import React, {useState} from "react";
import {FormControl, FormGroup, Form} from "react-bootstrap";
import BusyFakeButton from "../../../../component/busyFakeButton/BusyFakeButton";
// @ts-ignore
import GoogleLogin from "react-google-login";
import {ValidationUtils} from "../../../../util/ValidationUtils";
import {LoginRequest} from "../../../../model/Account";

export interface LoginSectionProps {
    login: (loginRequest: LoginRequest) => Promise<void>
}

export const LoginSection: React.FC<LoginSectionProps> = ({login}) => {
    const [emailAddress, setEmailAddress] = useState<string>("");
    const [password, setPassword] = useState<string>("");

    const [isLoggingIn, setInLoggingIn] = useState(false);

    const signUpHandler = async (func: () => Promise<void>) => {
        setInLoggingIn(true);
        await func().finally(() => setInLoggingIn(false));
    };

    const handleSignUpKeyPress = async (e: React.KeyboardEvent<HTMLElement>) => {
        if (e.key === 'Enter' && !e.shiftKey && isValid) {
            e.preventDefault();
            await handleNormalLogin();
        }
    };

    async function handleNormalLogin() {
        await signUpHandler(() => login({
            emailAddress,
            password,
            accountType: "NORMAL"
        }));
    }

    async function handleGoogleLogin(response: any) {
        await signUpHandler(() => login({
            emailAddress: response.profileObj.email,
            password: response.tokenId,
            accountType: "GOOGLE"
        }));
    }

    const isValid = ValidationUtils.combineValidationResults([
        ValidationUtils.validateLength(emailAddress, 1),
        ValidationUtils.validateLength(password, 1)
    ]);

    return (
        <Form noValidate>
            <FormGroup id="loginForm">
                <FormControl type="text"
                             id="loginEmail"
                             value={emailAddress}
                             onChange={(e: any) => setEmailAddress((e as any).target.value)}
                             placeholder="Enter email address"
                             onKeyPress={handleSignUpKeyPress}/>
                <FormControl type="password"
                             id="password"
                             value={password}
                             onChange={(e: any) => setPassword((e as any).target.value)}
                             placeholder="Enter password"
                             onKeyPress={handleSignUpKeyPress}/>

                <hr/>
                <BusyFakeButton className="button-center" isBusy={isLoggingIn}
                                size="sm"
                                onClick={handleNormalLogin}
                                disabled={!isValid}>
                    Log In
                </BusyFakeButton>
                <hr/>
                <GoogleLogin
                    clientId={process.env.REACT_APP_GOOGLE_APP_ID || ""}
                    buttonText="Login"
                    autoLoad={false}
                    onSuccess={(response: any) => handleGoogleLogin(response)}
                    onFailure={(response) => console.error(response)}
                    render={(renderProps: { onClick: () => void } | undefined) => (
                        <BusyFakeButton className="button-center button-social-network button-bottom"
                                        isBusy={isLoggingIn}
                                        size="sm"
                                        onClick={renderProps?.onClick}>
                            <span className="fab fa-google"/> Log In with Google
                        </BusyFakeButton>
                    )}
                />
            </FormGroup>
        </Form>
    )
};