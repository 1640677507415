import React, {ReactNode, useCallback, useContext, useState} from "react";
import {Button, Col, Container, Row} from "react-bootstrap";

import './AccountPage.css'
import {store} from "../../AppState";
import UserService from "../../service/UserService";
import {Features, Login} from "../../model/Account";
import LoadingComponent from "../../component/loadingComponent/LoadingComponent";
import {SignUpPanel} from "../signUpPanel/SignUpPanel";
import {useHistory} from "react-router-dom";
import {DispatcherCreator} from "../../state/dispatcherCreator";
import PixelTracker from "../../component/pixelTracker/PixelTracker";

const AccountPage: React.FC = () => {
    const {state} = useContext(store);
    const successfulLogin: Login | undefined = state.lastSuccessfulLogin;
    const features: Features | undefined = state.features;

    const history = useHistory();
    const goBack = () => {
        history.goBack();

    };
    const userFeatures = state.features?.features;
    if (!userFeatures) {
        return <div className="loading-token-overlay main-background">
            <LoadingComponent isBusy={true} size='4x'/>
        </div>
    }

    return (
        <div className="main-background-landing">
            <PixelTracker eventName="AccountViewPage"/>

            <div className="pricing-display-container">
                <Container>
                    <Row>
                        <Col sm={12} md={true} className="slate-background-transparent pricing-display">
                            <div className="price-holder">
                                <h3>Basic</h3>
                                <hr/>

                                <br/>
                                <h5>Free</h5>
                            </div>

                            <div className="billing-button-holder">
                                <OpenBillingButton successfulLogin={successfulLogin}
                                                   selected={features?.level === "BASIC"}
                                                   pricingPlan="One month basic subscription"
                                                   disabled={!successfulLogin?.paymentSignedUp}/>
                            </div>

                            <div>
                                <hr/>
                                <table>
                                    <tbody>
                                    <Feature><b>Unlimited</b> Tasks</Feature>
                                    <Feature><b>Unlimited</b> Goals</Feature>
                                    <Feature>Assign Tasks to Goals</Feature>
                                    <Feature>Up to <b>5</b> Boards</Feature>
                                    <Feature>Recurring Tasks</Feature>
                                    <Feature>Custom Workflow per Board</Feature>
                                    <Feature>Shared Boards (with up to 3 people)</Feature>
                                    <Feature>Upload Files up to <b>50MB</b> per board</Feature>
                                    <Feature>Total Files up to <b>5MB</b> per file</Feature>
                                    <NoFeature/>
                                    <NoFeature/>
                                    <NoFeature/>
                                    <NoFeature/>
                                    <NoFeature/>
                                    </tbody>
                                </table>
                            </div>
                        </Col>
                        <Col sm={12} md={true} className="slate-background-transparent pricing-display">
                            <div className="price-holder">
                                <h3>Premium</h3>
                                <hr/>

                                <h5>£1.25 per month</h5>
                                <h6>or</h6>
                                <h5>£12.00 per year</h5>
                            </div>

                            <div className="billing-button-holder">
                                <OpenBillingButton successfulLogin={successfulLogin}
                                                   selected={features?.level === "PREMIUM"}
                                                   pricingPlan="One month premium subscription"/>
                            </div>

                            <div>
                                <hr/>
                                <table>
                                    <tbody>
                                    <Feature><b>Unlimited</b> Tasks</Feature>
                                    <Feature><b>Unlimited</b> Goals</Feature>
                                    <Feature>Assign Tasks to Goals</Feature>
                                    <Feature><b>Unlimited</b> Boards</Feature>
                                    <Feature>Recurring Tasks</Feature>
                                    <Feature>Custom Workflow per Board</Feature>
                                    <Feature>Shared Boards (with up to 8 people)</Feature>
                                    <Feature>Upload Files up to <b>250MB</b> per board</Feature>
                                    <Feature>Total Files up to <b>25MB</b> per file</Feature>
                                    <Feature>Share Boards publicly to everyone</Feature>
                                    <Feature>Assignees for Tasks</Feature>
                                    <Feature>3 Automated Rules per Board</Feature>
                                    <NoFeature/>
                                    <NoFeature/>
                                    </tbody>
                                </table>
                            </div>
                        </Col>
                        <Col sm={12} md={true} className="slate-background-transparent pricing-display">
                            <div className="price-holder">
                                <h3>Workforce</h3>
                                <hr/>

                                <h5>£7.25 per month</h5>
                                <h6>or</h6>
                                <h5>£70.00 per year</h5>
                            </div>

                            <div className="billing-button-holder">
                                <OpenBillingButton successfulLogin={successfulLogin}
                                                   selected={features?.level === "WORKFORCE"}
                                                   pricingPlan="One month workforce subscription"/>
                            </div>

                            <div>
                                <hr/>
                                <table>
                                    <tbody>
                                    <Feature><b>Unlimited</b> Tasks</Feature>
                                    <Feature><b>Unlimited</b> Goals</Feature>
                                    <Feature>Assign Tasks to Goals</Feature>
                                    <Feature><b>Unlimited</b> Boards</Feature>
                                    <Feature>Recurring Tasks</Feature>
                                    <Feature>Custom Workflow per Board</Feature>
                                    <Feature>Shared Boards (with <b>Unlimited</b> people)</Feature>
                                    <Feature>Upload Files up to <b>1024MB</b> per board</Feature>
                                    <Feature>Total Files up to <b>50MB</b> per file</Feature>
                                    <Feature>Share Boards publicly to everyone</Feature>
                                    <Feature>Assignees for Tasks</Feature>
                                    <Feature><b>Unlimited</b> Automated Rules</Feature>
                                    <Feature>Create Teams</Feature>
                                    <Feature>Shared Boards with Teams</Feature>
                                    </tbody>
                                </table>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={12} md={true}/>
                        <Col sm={12} md={true} className="pricing-button-holder">
                            <Button onClick={goBack} variant="dark">
                                Back
                            </Button>
                        </Col>
                        <Col sm={12} md={true}/>
                    </Row>
                </Container>
            </div>
        </div>
    )
};

interface Created {
    successfulLogin: Login | undefined,
    selected: boolean,
    pricingPlan: string,
    disabled?: boolean
}

const OpenBillingButton: React.FC<Created> = ({successfulLogin, selected, pricingPlan, disabled = false}) => {
    const {dispatch} = useContext(store);
    const dispatcherCreator = useCallback(() => new DispatcherCreator(dispatch), [dispatch]);

    const [showSign, setShowSign] = useState(false);
    const [isBusy, setIsBusy] = useState(false);

    const onSignUp = (loginResponse: Login) => {
        dispatcherCreator().onSignUp(loginResponse);
        onBilling(loginResponse);
    };

    const onClose = () => {
        dispatcherCreator().closeSignInPanel();
        setShowSign(false);
    };

    const handleClickOpenBilling = async () => {
        if (!successfulLogin?.userEmail) {
            setShowSign(true);
            return;
        }
        onBilling(successfulLogin);
    };

    const onBilling = async (login: Login) => {
        if (!login?.userEmail) {
            return;
        }

        setIsBusy(true);
        const session = await new UserService().billingSession(login.userEmail, pricingPlan);
        dispatcherCreator().setPaymentSignedUp(session.paymentSignedUp);
        window.location.href = session.url;
    };

    let buttonText;

    if (selected) {
        buttonText = "Current Plan";
    } else if (!successfulLogin?.paymentSignedUp) {
        buttonText = "Sign Up for 30 day free trial"
    } else {
        buttonText = "Open Billing";
    }

    return (
        <>
            <SignUpPanel showSignUpPanel={showSign}
                         onSignUp={onSignUp}
                         onClose={onClose}
                         lastSuccessfulLogin={successfulLogin}/>

            <Button onClick={handleClickOpenBilling} variant={selected ? "light" : "dark"} disabled={disabled}>
                {buttonText} <LoadingComponent isBusy={isBusy}/>
            </Button>
        </>
    )
};

const NoFeature: React.FC = () => {
    return <Feature>-</Feature>
};

const Feature: React.FC<{ children: ReactNode }> = ({children}) => {
    return (
        <tr>
            <td>
                {children}
            </td>
        </tr>
    )
};

export default AccountPage;
