import React, {useCallback, useContext} from "react";
import UserService from "../../service/UserService";
import {store} from "../../AppState";
import {ChangePasswordPanel} from "./ChangePasswordPanel";
import {ChangePasswordRequest, Login} from "../../model/Account";
import {DispatcherCreator} from "../../state/dispatcherCreator";

const ChangePasswordPanelContainer: React.FC<{ closePanel: () => void }> = ({closePanel}) => {
    const {state, dispatch} = useContext(store);
    const dispatcherCreator = useCallback(() => new DispatcherCreator(dispatch), [dispatch]);

    const handleChangePassword = async (request: ChangePasswordRequest): Promise<{ loginFailureMessage: string | undefined }> => {
        const login: Login = await new UserService().changePassword(request);
        if (login.loginStatus === "SUCCESS") {
            dispatcherCreator().onSignUp(login);
            return Promise.resolve({loginFailureMessage: undefined})
        } else {
            return Promise.reject({loginFailureMessage: login.loginMessage})
        }
    };

    if (!state.lastSuccessfulLogin?.userEmail) {
        return null;
    }

    return (
        <ChangePasswordPanel emailAddress={state.lastSuccessfulLogin?.userEmail}
                             changePassword={handleChangePassword}
                             closePanel={closePanel}/>
    )
};

export default ChangePasswordPanelContainer;