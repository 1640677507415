import React from "react";

import automation from '../../../assets/img/v2-automation.gif'

export const V2: React.FC = () => {
    return <>
        <h5>Automation Rules</h5>
        <span className="new">NEW!</span> <span>Premium Feature</span>
        <div className="login-text-holder">
            <span>Automation rules allow you to set actions to occur when your tasks reaches certain states.</span>
        </div>
        <div className="images-holder">
            <img src={automation} alt="Automation Rules"/>
        </div>
    </>
};