import React, {useContext, useEffect} from 'react';
import title from '../../assets/img/logo_white.png'

import TasksBoardContainer from "./components/tasksboard/TasksBoardContainer";
import GoalsBoardContainer from "./components/goalsboard/GoalsBoardContainer";
import SignUpPanelContainer from "../signUpPanel/SignUpPanelContainer";

import './PlanPage.css'
import SideBarContainer from "./components/sidebar/SideBarContainer";
import UserPreferencePanelContainer from "../userPreferencesPanel/UserPreferencesPanelContainer";
import ForgotPasswordPanelContainer from "../forgotPasswordPanel/ForgotPasswordPanelContainer";
import {Board, PermissionLevel} from "../../model/Board";
import {store} from "../../AppState";
import FontAwesome from "react-fontawesome";
import UnsubscribePanelContainer from "../unsubcribePanel/UnsubscribePanelContainer";
import FeedbackPanelContainer from "../feedbackPanel/FeedbackPanelContainer";
import {FeedbackButtonContainer} from "./components/feedbackBar/FeedbackButtonContainer";
import AnalyticsService from "../../service/AnalyticsService";
import AutomationPanelContainer from "../automationPanel/AutomationPanel";
import TeamOrganisationPanelContainer from "../organisationPanel/TeamOrganisationPanel";
import LoginNoticePanel from "../loginNotices/LoginNoticesPanel";
import ErrorMessagePanelContainer from "../errorMessagePanel/ErrorMessagePanel";
import {Login, User} from "../../model/Account";
import {Organisation} from "../../model/Organisation";
import {Selector} from "../../state/selector";
import {PomodoroPanelContainer} from "./components/pomodoroPanel/PomodoroPanelContainer";
import PixelTracker from "../../component/pixelTracker/PixelTracker";

const PlanPage: React.FC = () => {
    const {state} = useContext(store);
    useEffect(() => {
        new AnalyticsService().logPageView("PLAN_PAGE_VIEW", state.lastSuccessfulLogin);
    }, [state.lastSuccessfulLogin]);

    return (
        <div className="main-background">
            <PixelTracker eventName="TaskPageView"/>
            <SignUpPanelContainer/>
            <LoginNoticePanel/>
            <ForgotPasswordPanelContainer/>
            <UserPreferencePanelContainer/>
            <UnsubscribePanelContainer/>
            <FeedbackPanelContainer/>
            <AutomationPanelContainer/>
            <TeamOrganisationPanelContainer/>
            <ErrorMessagePanelContainer/>

            <div className="plan-page">
                <SideBarContainer/>
                <div className="rest-of-page">
                    <TitleBar/>
                    <GoalsBoardContainer/>
                    <TasksBoardContainer/>
                </div>
            </div>
            <FeedbackButtonContainer/>
            <PomodoroPanelContainer/>
        </div>
    )
};

const TitleBar: React.FC = () => {
    return (
        <>
            <div className="title-bar">
                <span className="title-bar-logo">
                    <a href="/">
                        <img src={title} alt="horizon logo"/>
                    </a>
                </span>
            </div>
            <SelectedBoardTitle/>
        </>
    )
};

const SelectedBoardTitle: React.FC = () => {
    const {state} = useContext(store);
    const lastSuccessfulLogin: Login | undefined = state.lastSuccessfulLogin;
    const selectedBoard: Board | undefined = state.selectedBoard;
    const creator: User | undefined = state.users?.find(it => it.userId === selectedBoard?.creatorUserId && it.userId !== lastSuccessfulLogin?.userId);


    return (
        <div className="title-board-display">
            {!!selectedBoard && <>
                <FontAwesome name="square-full"
                             style={{color: selectedBoard.colour}}/> {selectedBoard.boardName}
                <br/>
                <span className="board-title-created-by">
                    <SharedWithDisplay/>
                    {(creator) && <>Created by: {creator?.displayName}</>}
                </span>
            </>}

        </div>
    )
};

const SharedWithDisplay: React.FC = () => {
    const {state} = useContext(store);
    const selectedBoard: Board | undefined = state.selectedBoard;
    const users: User[] = state.users || [];
    const organisations: Organisation[] | undefined = state.organisations;

    const sharedWithUsers: User[] = selectedBoard?.sharedWithUsers?.map(sharedWith =>
        users.find(it => it.userId === sharedWith))
        .filter(it => !!it)
        .map(it => it as User) || [];

    const sharedWithOrganisation: Organisation | undefined = organisations?.find(it => it.id === selectedBoard?.sharedWithTeam);

    const sharedWithDisplay = [
        ...(sharedWithUsers.map(it => it.displayName)),
        ...(!!sharedWithOrganisation ? [sharedWithOrganisation?.name] : [])
    ];

    const readPermissionAccess: PermissionLevel = new Selector(useContext).getReadPermissionAccess();

    let sharedWith;
    switch (readPermissionAccess) {
        case "SHARED_USERS":
            sharedWith =  !!sharedWithDisplay.length && <>{"Shared with: " + sharedWithDisplay.join(', ') + ""}<br/></>;
            break;
        case "EVERYONE":
            sharedWith = <>{"Shared with Everyone"}<br/></>;
            break;
        default:
            sharedWith = <></>
    }

    return <>{sharedWith}</>
};


export default PlanPage;