import React, {useState} from "react";
import {Button, FormControl, FormGroup, Modal} from "react-bootstrap";
// @ts-ignore
import StarRatings from 'react-star-ratings'
import BusyFakeButton from "../../component/busyFakeButton/BusyFakeButton";
import AnalyticsService from "../../service/AnalyticsService";
import {PostSurveyRequest} from "../../model/Analytics";

import './FeedbackPanel.css';

interface FeedbackPanelProps {
    showFeedbackPanel: boolean,
    setShowFeedbackPanel: (value: boolean) => void
    markFeedbackSubmitted: () => void
}

export const FeedbackPanel: React.FC<FeedbackPanelProps> = ({showFeedbackPanel, setShowFeedbackPanel, markFeedbackSubmitted}) => {
    const [isSaving, setIsSaving] = useState(false);
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const [rating, setRating] = useState(0);
    const [showExtras, setShowExtras] = useState(false);
    const [commentText, setCommentText] = useState("");

    const changeRating = (rating: number) => {
        setRating(rating);
        setShowExtras(true);
    };

    const onSubmit = async () => {
        setIsSaving(true);
        const request: PostSurveyRequest = {
            rating: rating,
            page: 'PLAN_PAGE',
            comment: commentText,
            additionalData: {}
        };
        await new AnalyticsService().postSurveyFeedback(request).finally(() => setIsSaving(false));
        markFeedbackSubmitted();
        setHasSubmitted(true);
    };

    const onClose = () => setShowFeedbackPanel(false);

    if (hasSubmitted) {
        return <Modal show={showFeedbackPanel} animation={false} onHide={onClose}>
            <Modal.Header>
                <Modal.Title>How would you rate Horizon?</Modal.Title>
                <Button variant="link" onClick={onClose}>X</Button>
            </Modal.Header>
            <Modal.Body>
                <div className="inline-survey">
                    Thanks for your feedback!
                </div>
            </Modal.Body>
        </Modal>
    }

    return <Modal show={showFeedbackPanel} animation={false} onHide={onClose}>
        <Modal.Header>
            <Modal.Title>How would you rate Horizon?</Modal.Title>
            <Button variant="link" onClick={onClose}>X</Button>
        </Modal.Header>
        <Modal.Body>
            <div className="inline-survey">
                <StarRatings
                    disabled={hasSubmitted}
                    isSelectable={true}
                    rating={rating}
                    changeRating={changeRating}
                    numberOfStars={5}
                    starRatedColor={"black"}
                    starHoverColor={"black"}
                    name='rating'
                    starDimension='30px'
                    starSpacing='7px'/>

                {showExtras && <>
                    <FormGroup>
                        <div className="fade survey-holder">
                            <FormControl as="textarea"
                                         placeholder="What would improve your experience?"
                                         rows={3}
                                         id="comment"
                                         value={commentText}
                                         onChange={(e) => setCommentText(e.target.value)}
                                         disabled={hasSubmitted}/>
                        </div>

                        <div className="fade survey-holder">
                            <BusyFakeButton onClick={() => onSubmit()}
                                            disabled={hasSubmitted}
                                            isBusy={isSaving}
                                            className="inline-survey-button">
                                {hasSubmitted ? 'Thanks for your feedback' : 'Submit'}
                            </BusyFakeButton>
                        </div>
                    </FormGroup>
                </>}
            </div>
        </Modal.Body>
    </Modal>
};