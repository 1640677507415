import {SharedStatus} from "./Task";

export interface UserBoards {
    userId: string;
    selectedBoardId: string;
    boards: Board[];
    sharedBoards: Board[];
    selectedBoard?: Board
}

export const toUserBoards = (json: any): UserBoards => {
    const {userId, selectedBoardId, selectedBoard, boards, sharedBoards} = json;
    return {
        userId,
        selectedBoardId,
        boards: boards.map((it: any) => toBoard(it)),
        sharedBoards: sharedBoards.map((it: any) => toBoard(it)),
        selectedBoard
    }
};


export interface Board {
    id?: string;
    boardName: string;
    colour: string;
    creatorUserId?: string;
    defaultBoard: boolean;
    sharedWithUsers?: string[];
    sharedWithTeam?: string;
    sharedStatus?: SharedStatus;
    columns?: string[];
    settings?: BoardSettings;
    fileUploadedBytes: number;
    boardByteLimit?: number;
}

export const DEFAULT_COLUMNS: string[] = ["TODO", "IN_PROGRESS", "DONE"];

export const DEFAULT_BOARD_SETTINGS: BoardSettings = {
    readPermissionAccess: "SHARED_USERS",
    writePermissionAccess: "SHARED_USERS"
};

export const toBoard = (json: any): Board => {
    const {id, boardName, colour, creatorUserId, sharedWithUsers, sharedWithTeam, sharedStatus, defaultBoard, columns, settings, fileUploadedBytes, boardByteLimit} = json;
    return {
        id,
        boardName,
        colour,
        creatorUserId,
        defaultBoard,
        sharedWithUsers,
        sharedWithTeam,
        sharedStatus,
        columns,
        settings,
        fileUploadedBytes,
        boardByteLimit
    }
};

export interface BoardSettings {
    readPermissionAccess: PermissionLevel,
    writePermissionAccess: PermissionLevel,
}

export type PermissionLevel = "ONLY_ME" | "SHARED_USERS" | "EVERYONE"