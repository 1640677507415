import HttpUtilService from "./HttpUtilService";
import {HttpUrls} from "../util/Properties";
import {FriendsWithPendingInvites} from "../model/Friend";
import {Notification} from "../model/Notification";
import {Organisation, toOrganisation} from "../model/Organisation";

export class SocialService {

    private readonly USER_ACCOUNT_SERVICE_URL: string;
    private readonly httpUtilService: HttpUtilService;

    constructor(USER_ACCOUNT_SERVICE_URL: string | undefined = process.env.REACT_APP_USER_ACCOUNT_BACK_END_API,
                httpUtilService: HttpUtilService = new HttpUtilService()) {

        this.USER_ACCOUNT_SERVICE_URL = USER_ACCOUNT_SERVICE_URL || "";
        this.httpUtilService = httpUtilService;
    }

    public getFriends(userId: string): Promise<FriendsWithPendingInvites> {
        return this.httpUtilService.get(HttpUrls.USER_FRIENDS, this.USER_ACCOUNT_SERVICE_URL, {userId: userId})
            .then(response => response.data);
    }

    public addInvites(userId: string, emailAddresses: string[]): Promise<FriendsWithPendingInvites> {
        const body = {emailAddresses: emailAddresses};
        return this.httpUtilService.post(HttpUrls.USER_FRIENDS, this.USER_ACCOUNT_SERVICE_URL, body, {userId: userId})
            .then(response => response.data);
    }

    public deleteFriend(id: string): Promise<FriendsWithPendingInvites> {
        const params = {id: id};
        return this.httpUtilService.delete(HttpUrls.USER_FRIENDS, this.USER_ACCOUNT_SERVICE_URL, undefined, params)
            .then(response => response.data);
    }

    public acceptFriendRequest(id: string): Promise<FriendsWithPendingInvites> {
        const params = {id: id};
        return this.httpUtilService.post(HttpUrls.USER_REQUEST, this.USER_ACCOUNT_SERVICE_URL, undefined, params)
            .then(response => response.data);
    }

    public deleteFriendRequest(id: string): Promise<FriendsWithPendingInvites> {
        const params = {id: id};
        return this.httpUtilService.delete(HttpUrls.USER_REQUEST, this.USER_ACCOUNT_SERVICE_URL, undefined, params)
            .then(response => response.data);
    }

    public getNotifications(forUserId: string): Promise<Notification[]> {
        return this.httpUtilService.get(HttpUrls.USER_NOTIFICATIONS(forUserId), this.USER_ACCOUNT_SERVICE_URL)
            .then(response => response.data);
    }

    public updateNotifications(updates: Partial<Notification>[]): Promise<Notification[]> {
        return this.httpUtilService.post(HttpUrls.USER_NOTIFICATION, this.USER_ACCOUNT_SERVICE_URL, updates)
            .then(response => response.data);
    }

    public updateNotificationSeen(notifications: Notification[]): Promise<Notification[]> {
        const updates = notifications.filter(it => !it.seen).map(it => ({id: it.id, seen: true}));
        return !!updates.length ? this.updateNotifications(updates) : Promise.resolve(notifications);
    }

    public deleteNotifications(deletes: Partial<Notification>[]): Promise<Notification[]> {
        return this.httpUtilService.delete(HttpUrls.USER_NOTIFICATION, this.USER_ACCOUNT_SERVICE_URL, deletes)
            .then(response => response.data);
    }

    public getOrganisations(userId: string): Promise<Organisation[]> {
        return this.httpUtilService.get(HttpUrls.USER_ORGANISATION, this.USER_ACCOUNT_SERVICE_URL, {userId: userId})
            .then(response => response.data.map((it: any, index: number) => toOrganisation(it, index)));
    }

    public saveOrganisation(userId: string, organisations: Organisation[]): Promise<Organisation[]> {
        return this.httpUtilService.post(HttpUrls.USER_ORGANISATION, this.USER_ACCOUNT_SERVICE_URL, {organisations: organisations}, {userId: userId})
            .then(response => response.data.map((it: any, index: number) => toOrganisation(it, index)));
    }

}