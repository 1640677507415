import React, {useCallback, useContext, useEffect} from "react";
import {store} from "../../AppState";
import PlanPage from "./PlanPage";
import {Notification as HorizonNotification} from "../../model/Notification";
import {NotificationService} from "../../service/NotificationService";
import {PlanPageSocketMessageHandler} from "./service/PlanPageSocketMessageHandler";
import * as H from "history";
import {useHistory, useLocation} from "react-router-dom";
import TaskService from "../../service/TaskService";
import {isNavigationsSupported} from "../../util/BrowserCompatibilityFunctions";
import {DispatcherCreator} from "../../state/dispatcherCreator";
import {Routes} from "../../util/Properties";

const requestNotification = () => {
    if (isNavigationsSupported()) {
        Notification?.requestPermission();
    }
};

const PlanPageContainer: React.FC = () => {
    const {state, dispatch} = useContext(store);
    const dispatcherCreator = useCallback(() => new DispatcherCreator(dispatch), [dispatch]);

    const successfulLogin = state?.lastSuccessfulLogin;
    const userId = successfulLogin?.userId;
    const boardId = state.selectedBoard?.id;

    const pushNewNotifications = useCallback(async (notifications: HorizonNotification[]) => {
        const newNotifications = notifications.filter(it => !it.seen);
        const createNotifications = newNotifications.map(it => new NotificationService().createNotification(it.notificationText, it.additionalData));
        await Promise.all(createNotifications);
    }, []);

    const dispatchNotifications = useCallback((notifications: HorizonNotification[]) => {
        pushNewNotifications(notifications);
        dispatcherCreator().setNotifications(notifications)
    }, [pushNewNotifications, dispatcherCreator]);

    const listenToWebSockUpdates = useCallback(async (userId: string | undefined, boardId: string) => {
        return new PlanPageSocketMessageHandler().handleFor(userId, boardId, {
            updateTasks: dispatcherCreator().setTasks,
            updateGoals: dispatcherCreator().setGoals,
            updateFriends: dispatcherCreator().setFriendsPendingInvites,
            updateNotification: dispatchNotifications,
            updateSharedBoards: dispatcherCreator().setSharedBoards
        });
    }, [dispatcherCreator, dispatchNotifications]);


    useEffect(() => {
        if (!!boardId) {
            listenToWebSockUpdates(userId, boardId);
            requestNotification();
        }

        return () => {
            if (!!boardId) {
                new PlanPageSocketMessageHandler().unsubscribe(boardId)
            }
        }
    }, [userId, boardId, listenToWebSockUpdates]);


    const location: H.Location = useLocation();
    const history = useHistory();
    const selectBoardParam: string | null = new URLSearchParams(location.search).get("selectBoard");

    useEffect(() => {
        const targetBoard = [...state.boards, ...state.sharedBoards].find(it => it.id === selectBoardParam);
        if (!!targetBoard && !!userId) {
            new TaskService().saveBoards(userId, state.boards, targetBoard).then(dispatcherCreator().setUserBoards);
            history.push(Routes.PLAN_PATH);
        }
    }, [userId, location, history, state.boards, state.sharedBoards, selectBoardParam, dispatcherCreator]);

    return (
        <PlanPage/>
    )
};

export default PlanPageContainer;