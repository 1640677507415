// store.js
import React, {createContext, ReactNode, useReducer} from 'react';
import {Task, TaskFilter} from "./model/Task";
import {Goal} from "./model/Goal";
import reducer, {initialState} from "./state/reducer";
import {Action} from "./state/actions";
import {FriendsWithPendingInvites} from "./model/Friend";
import {UserPreferences} from "./model/UserPreferences";
import {Notification} from "./model/Notification";
import {Board} from "./model/Board";
import {Features, Login, User} from "./model/Account";
import {Organisation} from "./model/Organisation";
import {CountdownState} from "./features/planPage/components/pomodoroPanel/PomodoroPanel";

const store = createContext({
    state: initialState,
    dispatch: (_: Action) => {}
});

export interface AppState {
    tasks: Task[],
    taskFilter: TaskFilter,
    goals: Goal[],
    boards: Board[],
    sharedBoards: Board[],
    showSignUp: boolean,
    showSideBar: boolean,
    showFeedbackPanel: boolean,
    showAutomationPanel: boolean,
    showOrganisationPanel: boolean,
    submittedFeedback: boolean,
    lastSuccessfulLogin?: Login,
    selectedBoard?: Board;
    friendsWithPendingInvites?: FriendsWithPendingInvites,
    userPreferences?: UserPreferences,
    notifications?: Notification[],
    features?: Features,
    selectedBoardOrganisation?: Organisation,
    organisations?: Organisation[],
    users?: User[],
    errorBoardAuth?: string,
    pomodoroState?: PomodoroState
}

export interface PomodoroState {
    onUnloadTime: Date,
    onUnloadPomodoroNextTimer?: Date,
    onUnloadState?: CountdownState,
    wasRunning: boolean
}

const StateProvider = ({children}: { children: ReactNode }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    return <store.Provider value={{state, dispatch}}>
        {children}
    </store.Provider>;
};

export {store, StateProvider}