import React, {EventHandler, ReactNode, SyntheticEvent, useEffect, useRef, useState} from "react";
import {Button, ButtonGroup, Modal} from "react-bootstrap";

import './ConfirmAction.css'
import {NOOP} from "../../util/Functions";

interface ConfirmActionProps {
    confirmButtonText: string,
    confirmMessage: ReactNode,
    onClick: EventHandler<any>,
    disabled?: boolean
}

const ConfirmAction: React.FC<ConfirmActionProps> = ({children, onClick, confirmButtonText, confirmMessage, disabled = false}) => {
    const [modalShow, setModalShow] = useState<boolean>(false);
    const buttonRef = useRef<any>();

    const handleOpenModal = () => {
        setModalShow(true);
    };

    const handleOnConfirm = (e: SyntheticEvent<any>, onClick: EventHandler<any>) => {
        setModalShow(false);
        onClick(e);
    };

    const handleClose = () => {
        setModalShow(false);
    };

    useEffect(() => {
        if (modalShow) {
            buttonRef.current.focus();
        }
    }, [modalShow]);

    return (
        <>
            <span onClick={disabled ? NOOP : handleOpenModal}>
                {children}
            </span>

            <Modal show={modalShow} onHide={handleClose} animation={false}>
                <Modal.Body className="confirm-panel">
                    <span>{confirmMessage}</span>
                    <div className="button-group" >
                        <ButtonGroup>
                            <Button variant="outline-dark" style={{width: '150px'}} onClick={(e: any) => handleOnConfirm(e, onClick)} as="button" ref={buttonRef}>
                                {confirmButtonText}
                            </Button>
                            <Button variant="dark" style={{width: '150px'}} onClick={handleClose}>
                                Close
                            </Button>
                        </ButtonGroup>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
};

export default ConfirmAction;