import PasswordValidator from "password-validator";

type ValidationResult = boolean;

export class ValidationUtils {

    static EMAIL_REG_EXP: RegExp = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

    static combineValidationResults(validationResults: ValidationResult[]) {
        return !validationResults.some(validationResult => !validationResult);
    }

    static validateLength(value: string, minimumLength: number, maximumLength: number = 99999999999): ValidationResult {
        if (!value || value.length === 0) {
            return false
        }

        return (value.length >= minimumLength && (value.length <= maximumLength));
    }


    static validateEmail(value: string | undefined): ValidationResult {
        return ValidationUtils.validateRegEx(value, ValidationUtils.EMAIL_REG_EXP);
    }

    static validateRegEx(value: string | undefined, regex: RegExp) {
        if (!value || value.length === 0) {
            return false
        }

        return regex.test(value);
    }

    static validatePassword(password: string) {
        return !!ValidationUtils.getPasswordValidator().validate(password)
    }

    static matchingPassword(password: string, confirmPassword: string) {
        return ValidationUtils.combineValidationResults([
            ValidationUtils.validateLength(password, 1),
            ValidationUtils.validateLength(confirmPassword, 1),
            password === confirmPassword,
            !!ValidationUtils.getPasswordValidator().validate(password)
        ]);
    }

    private static getPasswordValidator(): PasswordValidator {
        const passwordSchema: PasswordValidator = new PasswordValidator();
        passwordSchema
            .is().min(8)
            .is().max(100)
            .has().uppercase()
            .has().lowercase()
            .has().digits()
            .has().not().spaces();
        return passwordSchema;
    }

}