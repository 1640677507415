export interface Notification {
    id: string;
    notificationText: string;
    belongsToUserId: string;
    forUserId: string;
    type: NotificationType;
    additionalData?: {[key: string]: string};
    seen: boolean
}

export const toNotification = (json: any) => {
    const {id, notificationText, belongsToUserId, forUserId, type, additionalData, seen} = json;
    return {
        id,
        notificationText,
        belongsToUserId,
        forUserId,
        type,
        additionalData,
        seen
    }
};

export type NotificationType = "FRIEND_INVITE" | "TASK_INVITE" | "TASK_ACCEPT" | "GOAL_INVITE" | "BOARD_INVITE" | "TASK_CHANGE"