import React, {useEffect, useState} from "react";
import {Button, Modal} from "react-bootstrap";
import UserService from "../../service/UserService";
import {SignUpSection} from "./components/signupsection/SignUpSection";
import {LoginSection} from "./components/loginsection/LoginSection";
import {ResetPasswordSection} from "./components/resetPassword/ResetPasswordSection";

import './SignUpPanel.css'
import {Login, LoginRequest, SignUpRequest} from "../../model/Account";

type MODE = "SIGNUP" | "LOGIN" | "PASSWORD_RESET";

export interface SignUpPanelProps {
    showSignUpPanel: boolean;
    lastSuccessfulLogin: Login | undefined;
    onSignUp: (loginResponse: Login) => void;
    onClose: () => void
}

export const SignUpPanel: React.FC<SignUpPanelProps> = ({showSignUpPanel: initialShowSignUpPanel, onSignUp, onClose}) => {
    const userService = new UserService();

    const [mode, setMode] = useState<MODE>("LOGIN");
    const [showSignUpPanel, setShowSignUpPanel] = useState(false);

    const [loginFailureMessage, setLoginFailureMessage] = useState<string | undefined>(undefined);
    const [showResetPasswordMessage, setShowResetPasswordMessage] = useState<boolean>(false);

    useEffect(() => {
        setShowSignUpPanel(initialShowSignUpPanel)
    }, [initialShowSignUpPanel]);

    const switchMode = (mode: MODE) => {
        setMode(mode);
        setShowResetPasswordMessage(false);
    };

    const handleSignUp = async (signUpRequest: SignUpRequest) => {
        const response: Login = await userService.signUp(signUpRequest);
        if (response.loginStatus === "SUCCESS") {
            onSignUp(response);
            onClose();
            setLoginFailureMessage(undefined);
        } else {
            setLoginFailureMessage(response.loginMessage);
        }
    };

    const handleLogin = async (loginRequest: LoginRequest) => {
        const response: Login = await userService.login(loginRequest);
        if (response.loginStatus === "SUCCESS") {
            onSignUp(response);
            onClose();
            setLoginFailureMessage(undefined);
        } else {
            setLoginFailureMessage(response.loginMessage);
        }
    };

    const handlePasswordReset = async (request: { emailAddress: string }) => {
        await userService.forgotPassword(request);
        setShowResetPasswordMessage(true);
    };

    return (
        <Modal show={showSignUpPanel} animation={false} onHide={onClose}>
            <Modal.Header>
                <Modal.Title>
                    {mode === "SIGNUP" && "Sign Up"}
                    {mode === "LOGIN" && "Log In"}
                    {mode === "PASSWORD_RESET" && "Reset Your Password"}
                </Modal.Title>
                <Button variant="link" onClick={onClose}>X</Button>
            </Modal.Header>
            <Modal.Body className="sign-up-modal">
                {mode === "SIGNUP" && <SignUpSection signUp={handleSignUp}/>}
                {mode === "LOGIN" && <LoginSection login={handleLogin}/>}
                {mode === "PASSWORD_RESET" && <ResetPasswordSection resetPassword={handlePasswordReset}/>}
                <hr/>
                {!!loginFailureMessage && <div className="login-failure">{loginFailureMessage}</div>}
                {showResetPasswordMessage &&
                <div className="reset-password-message">Thanks! If you have an account registered with this email
                    address then you will receive further instructions</div>}

                <div className="bottom-sign-up-links">
                    <SwitchPanelLink mode={mode} setMode={switchMode}/>
                    <Button variant="link" onClick={onClose}>
                        Sign up later and go straight to Horizon!
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    )
};

const SwitchPanelLink: React.FC<{ mode: MODE, setMode: (mode: MODE) => void }> = ({mode, setMode}) => {
    return (
        <span style={{fontSize: '1.4rem'}}>
            {(mode === "SIGNUP" || mode === "PASSWORD_RESET") &&
            <Button variant="link" onClick={() => setMode("LOGIN")}>Existing user? Log in here.</Button>}

            {(mode === "LOGIN" || mode === "PASSWORD_RESET") &&
            <Button variant="link" onClick={() => setMode("SIGNUP")}>New user? Sign up here.</Button>}

            {mode !== "PASSWORD_RESET" &&
            <Button variant="link" onClick={() => setMode("PASSWORD_RESET")}>Forgot your password? Reset it
                here.</Button>}
        </span>
    )
};
