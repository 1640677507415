import React, {useCallback, useContext} from "react";
import {Modal} from "react-bootstrap";
import {store} from "../../AppState";
import FakeButton from "../../component/fakeButton/FakeButton";
import {DispatcherCreator} from "../../state/dispatcherCreator";
import {useHistory} from "react-router-dom";
import {Routes} from "../../util/Properties";
import {Board} from "../../model/Board";

const ErrorMessagePanel: React.FC = () => {
    const {state, dispatch} = useContext(store);
    const history = useHistory();
    const dispatcherCreator = useCallback(() => new DispatcherCreator(dispatch), [dispatch]);
    const boards = state.boards;

    const onHide = useCallback(() => {
        dispatcherCreator().setCloseErrorAuthMessage();
        const defaultBoard: Board | undefined = boards.find(it => it.defaultBoard);

        if (defaultBoard?.id && defaultBoard?.creatorUserId) {
            history.push(Routes.PLAN_PATH_FOR_PATH(defaultBoard.creatorUserId, defaultBoard.id));
        } else {
            history.push(Routes.PLAN_PATH);
        }
    }, [history, dispatcherCreator, boards]);

    const errorBoardAuth = state.errorBoardAuth;

    return (
        <Modal show={!!errorBoardAuth} animation={false} onHide={onHide}>
            <Modal.Header>
                <Modal.Title/>
            </Modal.Header>
            <Modal.Body className="time-zone-dialog">
                {state.errorBoardAuth}
                <FakeButton size="sm" onClick={onHide}>
                    Close
                </FakeButton>
            </Modal.Body>
        </Modal>
    )
};

export default ErrorMessagePanel;