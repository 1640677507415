import React, {ChangeEvent, useCallback, useContext, useEffect, useState} from "react";
import {store} from "../../AppState";
import {Button, ButtonGroup, Form, Modal} from "react-bootstrap";
import {Automation, AutomationAction} from "../../model/Automation";
import {Board} from "../../model/Board";
import LoadingComponent from "../../component/loadingComponent/LoadingComponent";
import TaskService from "../../service/TaskService";
import {TaskStatus} from "../../model/Task";

import './AutomationPanel.css'
import {isValidUserSelection, UserSelection} from "./options/UserSelection";
import {isValidSendEmailToAnyone, SendEmailToAnyoneOption} from "./options/SendEmailToAnyoneOption";
import {isValidSendEmailToUser, SendEmailToUserOption} from "./options/SendEmailToUserOption";
import FeatureNotAvailable from "../../component/featureNotAvailable/FeatureNotAvailable";
import {ValidationUtils} from "../../util/ValidationUtils";
import {DispatcherCreator} from "../../state/dispatcherCreator";
import {FeatureLevel} from "../../model/Account";
import automationImg from "../../assets/img/v2-automation.gif";
import {Selector} from "../../state/selector";

const AutomationPanel: React.FC = () => {
    const {state, dispatch} = useContext(store);
    const dispatcherCreator = useCallback(() => new DispatcherCreator(dispatch), [dispatch]);

    const selectedBoard: Board | undefined = state?.selectedBoard;
    const showAutomationPanel: boolean = state.showAutomationPanel;

    const [automation, setAutomation] = useState<Automation[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [isDirty, setIsDirty] = useState<boolean>(false);

    const boardCreatorUserId = state.selectedBoard?.creatorUserId;
    const columns = new Selector(useContext).getSelectedBoardColumns();

    const featureLevel: FeatureLevel | undefined = state.features?.level;
    const noOfAutomatedRules: number | undefined = state.features?.features?.noOfAutomatedRules;
    const exceedAutomatedRules = automation.length >= (noOfAutomatedRules || 0);

    const setAutomationForIndex = (index: number, automationToSet: Automation) => {
        const newAutomationList = [...automation];
        newAutomationList[index] = automationToSet;
        setAutomation(newAutomationList);
        setIsDirty(true);
    };

    const removeAutomation = (index: number) => {
        const newAutomationList = [...automation];
        newAutomationList.splice(index, 1);
        setAutomation(newAutomationList);
        setIsDirty(true);
    };

    const addAutomation = () => {
        const newAutomationList = automation.concat({
            action: {type: "NO_ACTION"},
            rule: {
                reachesStatus: columns[columns.length - 1]
            }
        });

        setAutomation(newAutomationList);
        setIsDirty(true);
    };


    const closePanel = () => dispatcherCreator().setAutomationPanelShown(false);

    const saveAutomation = () => {
        if (!selectedBoard?.id || !boardCreatorUserId) {
            return;
        }
        setIsSaving(true);
        new TaskService().saveAutomations(boardCreatorUserId, selectedBoard.id, automation).then(setAutomation)
            .finally(() => {
                setIsSaving(false);
                setIsDirty(false);
                closePanel();
            });

    };

    const cancel = () => {
        if (!selectedBoard?.id || !boardCreatorUserId) {
            return;
        }
        new TaskService().getAutomations(boardCreatorUserId, selectedBoard?.id).then(setAutomation).finally(closePanel)
    };

    const isValid = ValidationUtils.combineValidationResults(automation.map(it => {
        switch (it.action.type) {
            case "SEND_EMAIL_USER":
                return isValidSendEmailToUser(it.action);
            case "SEND_EMAIL":
                return isValidSendEmailToAnyone(it.action);
            case "ASSIGN_TO_USER":
            case "NOTIFICATION":
                return isValidUserSelection(it.action);
            case "NOTIFICATION_FOR_ALL":
                return true;
            default:
                return false;
        }
    }));

    useEffect(() => {
        if (selectedBoard?.id && showAutomationPanel && boardCreatorUserId) {
            setIsLoading(true);
            setIsDirty(false);
            new TaskService().getAutomations(boardCreatorUserId, selectedBoard?.id).then(setAutomation)
                .finally(() => setIsLoading(false));
        }
    }, [boardCreatorUserId, selectedBoard, showAutomationPanel]);

    return (
        <Modal className="automation-modal" show={showAutomationPanel} animation={false} onHide={closePanel}>
            <Modal.Header>
                <Modal.Title>Automation Rules</Modal.Title>
                <Button variant="link" onClick={closePanel}>X</Button>
            </Modal.Header>
            <Modal.Body className="automation-panel">
                {
                    (!selectedBoard || isLoading) &&
                    <div className="automation-is-loading">
                        <LoadingComponent isBusy={true} size={"2x"}/>
                    </div>
                }
                {
                    (selectedBoard && !isLoading && featureLevel === "BASIC") &&
                    <>
                        <FeatureNotAvailable supportedFeatureLevels={["PREMIUM", "WORKFORCE"]}
                                             displayText={<>
                                                 <span>Creating <b>'Automation Rules'</b> is a Premium feature.</span><br/>
                                                 <span>This allows you to set actions to occurs at specific points in your workflow.</span><br/>
                                                 <span>Click here to review all features and subscription settings.</span>
                                                 <br/>
                                                 <br/>
                                                 <div className="automation-images-holder" style={{textAlign: 'center'}}>
                                                     <img src={automationImg} alt="Automation"/>
                                                 </div>
                                             </>}/>
                    </>
                }
                {
                    (selectedBoard && !isLoading  && ["PREMIUM", "WORKFORCE"].includes(featureLevel || "")) &&
                    <>
                        <div className="automation-scroll-panel-holder">
                            <div className="automation-panel-holder">
                                {automation.map((it, index) =>
                                    <AutomationDisplay key={index}
                                                       index={index + 1}
                                                       automation={it}
                                                       setAutomation={(newAutomation) => setAutomationForIndex(index, newAutomation)}
                                                       removeAutomation={() => removeAutomation(index)}
                                                       columns={columns}
                                                       disabled={!noOfAutomatedRules}/>)
                                }
                            </div>
                        </div>

                        <div style={{textAlign: 'center'}}>
                            <Button variant="dark" onClick={addAutomation} disabled={exceedAutomatedRules}>
                                Add Automation
                            </Button>

                            <br/>
                            <br/>

                            <ButtonGroup>
                                <Button variant="dark" onClick={saveAutomation}
                                        className={isDirty ? "dirty-button" : ""}
                                        disabled={isSaving || !isValid || !noOfAutomatedRules || !isDirty}>
                                    Save <LoadingComponent isBusy={isSaving}/>
                                </Button>
                                <Button variant="secondary" onClick={cancel} disabled={isSaving}>
                                    Cancel
                                </Button>
                            </ButtonGroup>
                        </div>

                        {!!noOfAutomatedRules && exceedAutomatedRules &&
                        <FeatureNotAvailable supportedFeatureLevels={["WORKFORCE"]}
                                             displayText={<span>You've exceeded the max automated rules for this board.
                                                 <br/>You can upgrade this limit by upgrading your account.</span>}/>}
                    </>
                }
            </Modal.Body>
        </Modal>
    )
};

interface AutomationDisplayProps {
    automation: Automation,
    setAutomation: (automation: Automation) => void,
    removeAutomation: () => void,
    index: number,
    columns: string[],
    disabled: boolean
}

type ActionType = "NO_ACTION" | "NOTIFICATION" | "NOTIFICATION_FOR_ALL" | "ASSIGN_TO_USER" | "SEND_EMAIL";

const AutomationDisplay: React.FC<AutomationDisplayProps> = ({automation, setAutomation, removeAutomation, index, columns, disabled}) => {
    const selectAutomationStatusRule = (taskStatus: TaskStatus) => setAutomation({
        ...automation,
        rule: {
            ...automation.rule,
            reachesStatus: taskStatus
        }
    });

    const selectAutomationActionType = (type: ActionType) => setAutomation({
        ...automation,
        action: {
            ...automation.action,
            type: type
        }
    });

    const selectAutomationAction = (action: AutomationAction) => setAutomation({
        ...automation,
        action: action
    });

    const getColumnHeaderName = (name: TaskStatus) => {
        switch (name) {
            case "TODO":
                return "To Do";
            case "IN_PROGRESS":
                return "In Progress";
            case "DONE":
                return "Done";
            default:
                return name;
        }
    };

    const renderOption = (action: AutomationAction) => {
        switch (action.type) {
            case "ASSIGN_TO_USER":
            case "NOTIFICATION":
                return <UserSelection action={action} setAction={selectAutomationAction}/>;
            case "SEND_EMAIL":
                return <SendEmailToAnyoneOption action={action} setAction={selectAutomationAction}/>;
            case "SEND_EMAIL_USER":
                return <SendEmailToUserOption action={action} setAction={selectAutomationAction}/>;
            default:
                return <></>
        }
    };

    const selectType = (event: ChangeEvent<HTMLSelectElement>) => selectAutomationActionType(event.target.value as ActionType);
    const isValidActionType = automation.action.type !== "NO_ACTION";

    return (
        <div>
            <h6>Automation Rule #{index}</h6>
            <span>When Task enters the <b>status</b></span>
            <Form.Control as="select"
                          className="automation-selection"
                          disabled={disabled}
                          value={automation.rule.reachesStatus}
                          onChange={((event: ChangeEvent<HTMLSelectElement>) => selectAutomationStatusRule(event.target.value))}>
                {columns.map((it, index) => <option key={index} value={it}>{getColumnHeaderName(it)}</option>)}
            </Form.Control>
            <span>Then</span>
            <Form.Control as="select"
                          className="automation-selection"
                          disabled={disabled}
                          value={automation.action.type}
                          onChange={selectType}
                          isValid={isValidActionType}
                          isInvalid={!isValidActionType}>
                <option value="NO_ACTION">None</option>
                <option value="NOTIFICATION">Send notification</option>
                <option value="NOTIFICATION_FOR_ALL">Send notification to all users</option>
                <option value="ASSIGN_TO_USER">Assign task</option>
                <option value="SEND_EMAIL_USER">Send email to user</option>
                <option value="SEND_EMAIL">Send email to address</option>
            </Form.Control>

            {renderOption(automation.action)}

            <div className="automation-remove-button-holder">
                <Button variant="danger" size="sm" onClick={removeAutomation}>
                    Remove Rule
                </Button>
            </div>
            <hr className="automation-line-separator"/>
        </div>
    )
};

export default AutomationPanel;