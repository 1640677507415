import React from "react";
import FakeButton, {FakeButtonProps} from "../fakeButton/FakeButton";

import './BusyFakeButton.css'
import LoadingComponent from "../loadingComponent/LoadingComponent";

export interface BusyFakeButtonProps extends FakeButtonProps {
    isBusy: boolean
}

const BusyFakeButton: React.FC<BusyFakeButtonProps> = ({isBusy, children, disabled, ...rest}) => {
    return (
        <FakeButton {...rest} disabled={isBusy || disabled}>
            <LoadingComponent isBusy={isBusy}/> {children}
        </FakeButton>
    )
};
export default BusyFakeButton;