import {Login} from "../model/Account";
import {Board, PermissionLevel} from "../model/Board";

export class PermissionService {

    public checkBoardAccess(login: Login | undefined, board: Board | undefined, permissionAccess: PermissionLevel | undefined): boolean {
        if (!board) {
            return true;
        }

        if (!permissionAccess) {
            return false;
        }

        switch (permissionAccess) {
            case "EVERYONE":
                return true;
            case "SHARED_USERS":
                const userId = login?.userId || "";
                const sharedWithUsers = board.sharedWithUsers || [];
                return sharedWithUsers?.includes(userId) || board.creatorUserId === userId;
            case "ONLY_ME":
                return board.creatorUserId === login?.userId;
        }
    }

}