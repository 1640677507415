import React, {useContext, useState} from "react";
import {Button, ButtonGroup, Form, FormControl} from "react-bootstrap";
import {Task} from "../../../../../model/Task";
import {Friend, FriendsWithPendingInvites} from "../../../../../model/Friend";
import {Comment} from "../../../../../model/Comment";
import {Goal} from "../../../../../model/Goal";
import GoalDisplay from "../goalDisplay/GoalDisplay";
import Linkify from 'react-linkify';
import './TaskComments.css'
import {formatDateToDateTimeString} from "../../../../../util/DateHelpers";
import LoadingComponent from "../../../../../component/loadingComponent/LoadingComponent";
import ConfirmAction from "../../../../../component/confirmAction/ConfirmAction";
import FontAwesome from "react-fontawesome";
import {Login} from "../../../../../model/Account";
import {Selector} from "../../../../../state/selector";

interface TaskDetailPanelProps {
    task: Task;
    assignedGoal: Goal | undefined;
    successfulLogin: Login | undefined;
    friendsWithPendingInvites: FriendsWithPendingInvites | undefined;
    comments: Comment[] | undefined;
    addComment: (comment: Comment) => Promise<void>;
    deleteComment: (comment: Comment) => Promise<void>;
    onClose: () => void;
}

const TaskComments: React.FC<TaskDetailPanelProps> = ({task, assignedGoal, successfulLogin, friendsWithPendingInvites, comments, addComment, deleteComment, onClose}) => {
    const [commentToAdd, setCommentToAdd] = useState<string>("");
    const [isAdding, setIsAdding] = useState<boolean>(false);
    const canWrite = new Selector(useContext).checkBoardWriteAccess();

    const userId = successfulLogin?.userId;
    if (!successfulLogin || !friendsWithPendingInvites || !userId || !comments) {
        return <div className="slate-background-transparent form-holder task">
            <LoadingComponent isBusy={true}/>
        </div>

    }

    const handleAddComment = () => {
        const newComment: Comment = {
            commentText: commentToAdd,
            createdBy: userId
        };

        setIsAdding(true);
        addComment(newComment)
            .then(() => setCommentToAdd(""))
            .finally(() => setIsAdding(false));
    };

    const handleKeyPress = async (e: React.KeyboardEvent<HTMLElement>) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            await handleAddComment();
            e.preventDefault();
        }
    };


    return (
        <div className="slate-background-transparent form-holder task">
            <div>
                <span>{task.taskName}</span>
                {assignedGoal &&
                <>
                    <br/>
                    <span className="task-goal-display">
                        <GoalDisplay goal={assignedGoal}/>
                    </span>
                </>}
            </div>
            <CommentList comments={comments}
                         successfulLogin={successfulLogin}
                         friendsWithPendingInvites={friendsWithPendingInvites}
                         deleteComment={deleteComment}/>
            <Form className="form">
                <Form.Group>
                    <div className="comments-text-holder">
                        <FormControl as="textarea"
                                     id="comment"
                                     rows={3}
                                     value={commentToAdd}
                                     onChange={(e: any) => setCommentToAdd((e as any).target.value)}
                                     onKeyPress={handleKeyPress}
                                     placeholder="Add a comment"/>
                    </div>
                </Form.Group>
                <Form.Group>
                    <ButtonGroup>
                        <Button variant="light" style={{width: '150px'}} onClick={handleAddComment}
                                disabled={!commentToAdd.length || !canWrite}>
                            <LoadingComponent isBusy={isAdding}/> Add Comment
                        </Button>
                        <Button variant="dark" style={{width: '150px'}} onClick={onClose}>
                            Close
                        </Button>
                    </ButtonGroup>
                </Form.Group>
            </Form>
        </div>
    )
};

interface CommentListProps {
    comments: Comment[],
    successfulLogin: Login;
    friendsWithPendingInvites: FriendsWithPendingInvites;
    deleteComment: (comment: Comment) => Promise<void>;
}

const CommentList: React.FC<CommentListProps> = ({comments, successfulLogin, friendsWithPendingInvites, deleteComment}) => {

    const getDisplayName = (userId: string): string => {
        if (isMyComment(userId)) {
            return `${successfulLogin.firstName} ${successfulLogin.lastName}`
        }

        const friend: Friend | undefined = friendsWithPendingInvites.friends.find(it => it.friendUserId === userId);
        if (friend) {
            return friend.friendDisplayName;
        }

        return '';
    };

    const isMyComment = (userId: string) => {
        return successfulLogin.userId === userId;
    };

    return (
        <div>
            {
                comments.map((it, index) => {
                    const myComment = isMyComment(it.createdBy);
                    const displayName = getDisplayName(it.createdBy);

                    return (
                        <div key={index} className={`comment ${myComment ? 'my-comments' : 'other-comments'}`}>
                            <CommentDisplay comment={it} displayName={displayName} isMyComment={myComment}
                                            deleteComment={() => deleteComment(it)}/>
                        </div>
                    );
                })
            }
        </div>
    )
};

interface CommentDisplayProps {
    comment: Comment;
    displayName: string;
    isMyComment: boolean;
    deleteComment: (comment: Comment) => Promise<void>;
}

const CommentDisplay: React.FC<CommentDisplayProps> = ({comment, displayName, isMyComment, deleteComment}) => {
    const [isDeleting, setIsDeleting] = useState<boolean>(false);

    const handleDelete = async (comment: Comment) => {
        setIsDeleting(true);
        return deleteComment(comment);
    };

    if (isDeleting) {
        return <div style={{textAlign: 'center'}}><LoadingComponent isBusy={true}/></div>
    }

    return (
        <>
            <div>
                {`${displayName} (${formatDateToDateTimeString(comment.dateCreated, "D/MM/YY hh:mm A")})`}
                {isMyComment && <ConfirmAction confirmButtonText="Delete Comment"
                                               confirmMessage={
                                                   <span>Are you sure you want to delete this comment?</span>}
                                               onClick={() => handleDelete(comment)}>
                    <Button variant="link"><FontAwesome name="trash-alt"/></Button>
                </ConfirmAction>}
            </div>
            <div className="comment-text">
                <span>
                    <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
                        <a target="blank" href={decoratedHref} key={key}>
                            {decoratedText}
                        </a>
                    )}>{comment.commentText}</Linkify>
                </span>
            </div>
        </>
    )
};

export default TaskComments;