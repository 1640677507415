import React, {SyntheticEvent, useContext, useState} from "react";
import {Goal, GoalStatus} from "../../../../model/Goal";
import FontAwesome from "react-fontawesome";
import dayjs from "dayjs";
import {v4} from "uuid";
import {Button, Col, Row} from "react-bootstrap";
import FakeButton from "../../../../component/fakeButton/FakeButton";
import {formatDateToDateString} from "../../../../util/DateHelpers";
import {DragDropContainer, DropTarget} from "react-drag-drop-container";
import ColumnExpander from "../../../../component/columnExpander/ColumnExpander";
import {Friend, FriendsWithPendingInvites} from "../../../../model/Friend";
import ConfirmAction from "../../../../component/confirmAction/ConfirmAction";
import AddEditGoalForm from "./addEditGoalForm/AddEditGoalForm";
import GoalProgressBar from "./goalProgressBar/GoalProgressBar";
import {Task} from "../../../../model/Task";

import './GoalsBoard.css';
import {Board} from "../../../../model/Board";
import {GoalActions} from "./goalActions/GoalActions";
import {Selector} from "../../../../state/selector";

const SHOW_LIMIT_UNEXPECTED = 3;

type AddGoalHandler = (goalName: string, colour: string, dueDate?: dayjs.Dayjs) => void;
type EditGoalHandler = (goalId: string, goalName: string, colour: string, dueDate?: dayjs.Dayjs) => void;
type RemoveGoalHandler = (goalId: string) => void;
type SwapGoalHandler = (firstGoalId: string, secondGoalId: string) => void;
type MoveGoalHandler = (goals: Goal[], targetBoard: Board) => Promise<void>;
type ChangeGoalStatusHandler = (goalId: string, goalStatus: GoalStatus) => void;

interface DragEvent {
    dragData: {
        goalId: string
    }
}

interface GoalsBoardProps {
    tasks: Task[];
    goals: Goal[];
    setGoals: (goals: Goal[]) => void;
    friendResponse: FriendsWithPendingInvites | undefined;
    showTasksMatchingGoal: Goal | undefined;
    setShowTasksMatchingGoal: (goal: Goal | undefined) => void;
    boards: Board[],
    moveGoals: MoveGoalHandler
}

const GoalsBoard: React.FC<GoalsBoardProps> = ({
                                                   tasks, goals, setGoals, friendResponse,
                                                   showTasksMatchingGoal, setShowTasksMatchingGoal,
                                                   boards, moveGoals
                                               }) => {
    const [isExpanded, setExpanded] = useState(false);

    const addGoal: AddGoalHandler = (goalName, colour, dueDate) => {
        const newGoals: Goal[] = goals.concat({
            id: v4(),
            goalName: goalName,
            status: "IN_PROGRESS",
            dueDate: dueDate,
            colour: colour
        });
        setGoals(newGoals);
    };

    const editGoal: EditGoalHandler = ((goalId, goalName, colour, dueDate) => {
        const newGoals: Goal[] = goals.map((it: Goal) => {
            if (it.id === goalId) {
                return {
                    ...it,
                    goalName: goalName,
                    colour: colour,
                    dueDate: dueDate
                }
            }
            return it;
        });
        setGoals(newGoals);
    });

    const removeGoal: RemoveGoalHandler = (goalId) => {
        const newGoals: Goal[] = goals.filter((goal: Goal) => goal.id !== goalId);
        setGoals(newGoals);
    };

    const swapGoalOrders: SwapGoalHandler = (firstGoalId, secondGoalId) => {
        const targetGoalIndex = goals.findIndex(it => it.id === firstGoalId) || 0;

        const targetGoal = goals[targetGoalIndex];
        const draggingGoal = find(secondGoalId);

        if (!!draggingGoal) {
            const newTasks = [...goals.filter(it => it.id !== draggingGoal.id)];
            newTasks.splice(targetGoalIndex, 0, {...draggingGoal, status: targetGoal.status});
            setGoals(newTasks);
        }

        setExpanded(true);
    };

    const moveToTop = (goalId: string) => {
        const targetGoal = find(goalId);
        if (!!targetGoal) {
            const newGoals = [...goals.filter(it => it.id !== targetGoal.id)];
            newGoals.splice(0, 0, targetGoal);
            setGoals(newGoals);
        }
        setExpanded(true);
    };

    const moveToBottom = (goalId: string) => {
        const targetGoal = find(goalId);
        if (!!targetGoal) {
            const newGoals = [...goals.filter(it => it.id !== targetGoal.id)];
            newGoals.splice(newGoals.length, 0, targetGoal);
            setGoals(newGoals);
        }
        setExpanded(true);
    };

    const changeGoalStatus: ChangeGoalStatusHandler = (goalId, goalStatus) => {
        const targetGoal = find(goalId);
        if (!targetGoal || targetGoal.status === goalStatus) {
            return;
        }

        const newGoals: Goal[] = goals.map(it => {
            return it.id === goalId ? {...it, status: goalStatus} : it;
        });

        setGoals(newGoals);
        setExpanded(true);
    };

    const find = (goalId: string): Goal | undefined => {
        return goals.find(it => it.id === goalId);
    };

    const props = {
        editGoal,
        removeGoal,
        swapGoalOrders,
        changeGoalStatus,
        isExpanded,
        friendResponse,
        showTasksMatchingGoal,
        setShowTasksMatchingGoal,
        moveToTop,
        moveToBottom,
        boards,
        moveGoals
    };

    return (
        <div className="goals-container">
            <div className="goals-holder slate-background-transparent">
                <div>
                    <span className="panel-title"><FontAwesome name="bullseye"/> Goals</span>
                </div>
                <div>
                    <Row>
                        <Col sm={true}>
                            <GoalsList tasks={tasks}
                                       goals={goals.filter(it => it.status !== "DONE")}
                                       {...props}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={true}>
                            <GoalsList tasks={tasks}
                                       goals={goals.filter(it => it.status === "DONE")}
                                       {...props}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={true}>
                            <AddGoalSection addGoal={addGoal}
                                            goals={goals}
                                            isExpanded={isExpanded} setExpanded={setExpanded}
                                            friendResponse={friendResponse}/>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    )
};

interface GoalListProps {
    tasks: Task[];
    goals: Goal[];
    editGoal: EditGoalHandler;
    removeGoal: RemoveGoalHandler;
    swapGoalOrders: SwapGoalHandler;
    changeGoalStatus: ChangeGoalStatusHandler;
    isExpanded: boolean;
    friendResponse: FriendsWithPendingInvites | undefined;
    showTasksMatchingGoal: Goal | undefined;
    setShowTasksMatchingGoal: (goal: Goal | undefined) => void;
    moveToTop: (goalId: string) => void,
    moveToBottom: (goalId: string) => void,
    boards: Board[],
    moveGoals: MoveGoalHandler
}

const GoalsList: React.FC<GoalListProps> = ({
                                                tasks, goals, editGoal, removeGoal, swapGoalOrders, changeGoalStatus,
                                                isExpanded, friendResponse, showTasksMatchingGoal, setShowTasksMatchingGoal,
                                                moveToTop, moveToBottom, boards, moveGoals
                                            }) => {

    const displayGoals = !isExpanded ? goals.slice(0, SHOW_LIMIT_UNEXPECTED) : goals;

    return (
        <div className="goals-column">
            {displayGoals.map((goal, index) => {
                const tasksMatchingGoal = tasks.filter(it => it.goalId === goal.id);

                const isFilteredByGoal: boolean = showTasksMatchingGoal?.id === goal.id;
                const toggleFilterByGoal = () => {
                    if (isFilteredByGoal) {
                        setShowTasksMatchingGoal(undefined);
                    } else {
                        setShowTasksMatchingGoal(goal);
                    }
                };

                return <GoalDisplay key={index}
                                    tasks={tasksMatchingGoal}
                                    goal={goal}
                                    editGoal={editGoal}
                                    onRemove={() => removeGoal(goal.id)}
                                    swapGoalOrders={swapGoalOrders}
                                    changeGoalStatus={changeGoalStatus}
                                    friendResponse={friendResponse}
                                    isFilteredByGoal={isFilteredByGoal}
                                    toggleFilterByGoal={toggleFilterByGoal}
                                    moveToTop={index === 0 ? undefined : moveToTop}
                                    moveToBottom={index === displayGoals.length - 1 ? undefined : moveToBottom}
                                    boards={boards}
                                    moveGoals={moveGoals}/>;
            })}
        </div>
    )
};

interface GoalDisplayProps {
    tasks: Task[]
    goal: Goal;
    editGoal: EditGoalHandler;
    onRemove: () => void;
    swapGoalOrders: SwapGoalHandler;
    changeGoalStatus: ChangeGoalStatusHandler;
    friendResponse: FriendsWithPendingInvites | undefined;
    isFilteredByGoal: boolean;
    toggleFilterByGoal: () => void;
    moveToTop?: (goalId: string) => void,
    moveToBottom?: (goalId: string) => void,
    boards: Board[],
    moveGoals: MoveGoalHandler
}

const GoalDisplay: React.FC<GoalDisplayProps> = ({
                                                     tasks, goal, editGoal, onRemove, swapGoalOrders, changeGoalStatus,
                                                     friendResponse, isFilteredByGoal, toggleFilterByGoal,
                                                     moveToTop, moveToBottom, boards, moveGoals
                                                 }) => {

    const [isEditing, setEditing] = useState(false);

    const startEditing = (e: SyntheticEvent) => {
        setEditing(true);
        e.stopPropagation();
    };

    const handleEditTask = (goalName: string, colour: string, dueDate?: dayjs.Dayjs) =>
        editGoal(goal.id, goalName, colour, dueDate);

    const onDropAbove = (e: DragEvent) => {
        swapGoalOrders(goal.id, e.dragData.goalId);
    };

    const creator: Friend | undefined = friendResponse?.friends.find(it => it.friendUserId === goal.creatorUserId);
    const isDone = goal.status === "DONE";
    const canWrite = new Selector(useContext).checkBoardWriteAccess();

    const goalActions = <GoalActions goal={goal}
                                     boards={boards}
                                     moveGoals={moveGoals}
                                     moveToTop={moveToTop}
                                     moveToBottom={moveToBottom}
                                     changeGoalStatus={changeGoalStatus}
                                     canWrite={canWrite}/>;


    const confirmAction = <ConfirmAction confirmButtonText="Delete Goal"
                                         confirmMessage={
                                             <span>Are you sure you want to delete the goal <br/><b>"{goal.goalName}"?</b></span>}
                                         onClick={onRemove}
                                         disabled={!canWrite}>
        <Button variant="link" disabled={!canWrite}><FontAwesome name="trash-alt"/></Button>
    </ConfirmAction>;

    const isOverdue = () => !!goal.dueDate && !isDone && goal.dueDate.unix() < dayjs().unix();

    return (
        <>
            <div className="drop-target-holder">
                <DropTarget targetKey="goal" onHit={onDropAbove}/>
            </div>

            {
                !isEditing && <DragDropContainer targetKey="goal"
                                                 dragData={{goalId: goal.id}}
                                                 dragHandleClassName="drag"
                                                 zIndex={2}
                                                 noDragging={!canWrite}>
                    <DropTarget targetKey="goal" onHit={onDropAbove}>
                        <div className="full-width">
                            <div
                                className={`slate-background-transparent hover-support goal ${!!creator ? 'other-goal' : ''} ${isOverdue() ? 'overdue' : ''}`}>
                                <div className="goal-drag-handle drag">
                                    <span style={isDone ? {textDecoration: 'line-through'} : {}}><FontAwesome
                                        name="square-full" style={{color: goal.colour}}/> {goal.goalName}</span>
                                    {goal.dueDate && !isDone &&
                                    <>
                                        <br className="extra-small-screens-only"/>
                                        <span className={`due-date ${isOverdue() ? 'overdue' : ''}`}>
                                            {` Due: ${formatDateToDateString(goal.dueDate)}`}
                                        </span>
                                    </>}
                                    <br className="extra-small-screens-only"/>
                                    {isDone && goalActions}
                                    {isDone && confirmAction}
                                    {!!creator && !isDone &&
                                    <>
                                        <br className="extra-small-screens-only"/>
                                        <span
                                            className="shared-with-display show-more">{` Created by ${creator?.friendDisplayName} `}</span>
                                    </>
                                    }
                                </div>
                                {!!tasks.length && !isDone && <GoalProgressBar tasks={tasks}/>}

                                {!creator && !isDone &&
                                <Button variant="link" disabled={!canWrite} onClick={startEditing}><FontAwesome
                                    name="edit"/></Button>}
                                {!isDone && confirmAction}
                                {!isDone && <Button variant="link"
                                                    style={isFilteredByGoal ? {color: 'white'} : undefined}
                                                    onClick={toggleFilterByGoal}><FontAwesome name="eye"/></Button>}
                                {!isDone && goalActions}

                            </div>
                        </div>
                    </DropTarget>
                </DragDropContainer>
            }
            {
                isEditing &&
                <div className="full-width">
                    <div className="goal" style={{padding: '0'}}>
                        <AddEditGoalForm addGoal={handleEditTask}
                                         goal={goal}
                                         onClose={() => setEditing(false)}
                                         friendResponse={friendResponse}/>
                    </div>
                </div>
            }

            <div className="drop-target-holder">
                <DropTarget targetKey="goal" onHit={onDropAbove}/>
            </div>
        </>
    )
};

interface AddGoalSectionProps {
    addGoal: AddGoalHandler;
    goals: Goal[];
    isExpanded: boolean;
    setExpanded: (isExpanded: boolean) => void;
    friendResponse: FriendsWithPendingInvites | undefined;
}

const AddGoalSection: React.FC<AddGoalSectionProps> = ({addGoal, goals, isExpanded, setExpanded, friendResponse}) => {
    const [isAddingGoal, setIsAddingGoal] = useState(false);
    const showExpandButton = goals.length > SHOW_LIMIT_UNEXPECTED;
    const canWrite = new Selector(useContext).checkBoardWriteAccess();

    const handleAddGoal = () => {
        setIsAddingGoal(true);
        setExpanded(true);
    };

    return (
        <div className="goals-column">
            {
                !isAddingGoal && <div className="full-width">
                    {canWrite && <FakeButton size="sm" onClick={handleAddGoal}>
                        Add Goal
                    </FakeButton>}
                </div>
            }
            {
                isAddingGoal && <div className="full-width">
                    <AddEditGoalForm addGoal={addGoal}
                                     onClose={() => setIsAddingGoal(false)}
                                     friendResponse={friendResponse}/>
                </div>
            }
            {
                showExpandButton &&
                <div className="full-width">
                    <ColumnExpander isExpanded={isExpanded} setExpanded={setExpanded}
                                    showMoreMessage={`Show ${goals.length - SHOW_LIMIT_UNEXPECTED} more`}/>
                </div>
            }
        </div>
    )
};

export default GoalsBoard;