import React from "react";
import './FeedbackButton.css'
import {Button} from "react-bootstrap";
import FontAwesome from "react-fontawesome";

interface FeedbackBarProps {
    submittedFeedback: boolean;
    showFeedbackPanel: (value: boolean) => void;
}

export const FeedbackButton: React.FC<FeedbackBarProps> = ({submittedFeedback, showFeedbackPanel}) => {
    if (submittedFeedback) {
        return <></>
    }

    return <div className="feedback-bar">
        <div className="feedback-bar-link-holder">
            <Button variant="dark" onClick={() => showFeedbackPanel(true)}>
                <FontAwesome name="question-circle"/> How would rate Horizon?
            </Button>
        </div>
    </div>
};