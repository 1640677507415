import React, {useCallback, useContext} from "react";
import {FeedbackButton} from "./FeedbackButton";
import {store} from "../../../../AppState";
import {DispatcherCreator} from "../../../../state/dispatcherCreator";

export const FeedbackButtonContainer: React.FC = () => {
    const {state, dispatch} = useContext(store);
    const dispatcherCreator = useCallback(() => new DispatcherCreator(dispatch), [dispatch]);

    return <FeedbackButton submittedFeedback={state.submittedFeedback}
                           showFeedbackPanel={dispatcherCreator().setShowFeedbackPanel}/>
};