import React, {useCallback, useContext, useEffect, useState} from "react";
import {Alert, Button, ButtonGroup, Form, ProgressBar} from "react-bootstrap";
import FontAwesome from "react-fontawesome";

import {AppState, store} from "../../../../../AppState";
import {DispatcherCreator} from "../../../../../state/dispatcherCreator";
import {Task} from "../../../../../model/Task";
import LoadingComponent from "../../../../../component/loadingComponent/LoadingComponent";
import {FileUploadService} from "../../../../../service/FileUploadService";
import {FileUpload, FileUploadResponse} from "../../../../../model/FileUpload";
import './TaskUploadFile.css'
import {HttpUrls} from "../../../../../util/Properties";
import ConfirmAction from "../../../../../component/confirmAction/ConfirmAction";
import FeatureNotAvailable from "../../../../../component/featureNotAvailable/FeatureNotAvailable";
import {Selector} from "../../../../../state/selector";

interface HTMLInputEvent extends Event {
    target: HTMLInputElement & EventTarget;
}

interface TaskUploadFileContainerProps {
    task: Task
    onClose: () => void;
}

const TaskUploadFileContainer: React.FC<TaskUploadFileContainerProps> = ({task, onClose}) => {
    const {state, dispatch} = useContext(store);
    const [uploadPercent, setUploadPercent] = useState<number | undefined>(undefined);

    const selectedBoardId = state.selectedBoard?.id;
    const lastSuccessfulLogin = state.lastSuccessfulLogin;
    const features = state.features;

    const [fileUploads, setFileUploads] = useState<FileUpload[] | undefined>(undefined);
    const dispatcherCreator = useCallback(() => new DispatcherCreator(dispatch), [dispatch]);

    const dispatchFileUploadUpdates = (uploadResponse: FileUploadResponse) => {
        setFileUploads(uploadResponse.fileUploads);
        dispatcherCreator().updateSingleBoard({
            id: uploadResponse.boardByteTotals?.boardId,
            fileUploadedBytes: uploadResponse.boardByteTotals?.byteTotal
        });
        dispatcherCreator().updateSingleTask({
            id: task.id,
            noOfFileUploads: uploadResponse.fileUploads.length
        })
    };

    const updateUploadPercentage = (progressEvent: ProgressEvent,) => {
        const {loaded, total} = progressEvent;
        const percent = Math.floor((loaded * 100) / total);
        setUploadPercent(percent);
    };

    const uploadFiles = async (filesToUpload: File[]): Promise<void> => {
        if (lastSuccessfulLogin?.userId && !!filesToUpload.length && features) {
            const uploadResponse: FileUploadResponse = await new FileUploadService().prepareUploadFile("TASK", task.id, filesToUpload, features, selectedBoardId);
            await new FileUploadService().uploadFiles(filesToUpload, uploadResponse, updateUploadPercentage);
            dispatchFileUploadUpdates(uploadResponse);

        }
        setUploadPercent(undefined);
        return Promise.resolve();
    };

    const deleteFiles = async (filesToDelete: FileUpload[]): Promise<void> => {
        if (lastSuccessfulLogin?.userId && !!filesToDelete.length) {
            const uploadResponse = await new FileUploadService().deleteFiles(filesToDelete);
            dispatchFileUploadUpdates(uploadResponse);
        }
        return Promise.resolve();
    };

    useEffect(() => {
        if (lastSuccessfulLogin?.userId) {
            new FileUploadService().getUploadFiles("TASK", task.id).then(setFileUploads);
        }
    }, [task, lastSuccessfulLogin, setFileUploads]);


    if (!fileUploads) {
        return (
            <div className="slate-background-transparent form-holder">
                <Form className="form task-upload-loader">
                    <LoadingComponent isBusy={true}/>
                </Form>
            </div>
        )
    }

    return (
        <TaskUploadFile fileUploads={fileUploads}
                        uploadFiles={uploadFiles} deleteFiles={deleteFiles}
                        onClose={onClose}
                        uploadPercent={uploadPercent}/>
    )
};

interface TaskUploadFileProps {
    fileUploads: FileUpload[],
    uploadFiles: (files: File[]) => Promise<void>
    deleteFiles: (fileUploads: FileUpload[]) => Promise<void>
    onClose: () => void,
    uploadPercent: number | undefined;
}

const TaskUploadFile: React.FC<TaskUploadFileProps> = ({fileUploads, uploadFiles, deleteFiles, onClose, uploadPercent}) => {
    const [filesToUpload, setFilesToUpload] = useState<File[]>([]);
    const [filesToDelete, setFilesToDelete] = useState<FileUpload[]>([]);
    const [isBusy, setIsBusy] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

    const onSave = async () => {
        setIsBusy(true);
        await deleteFiles(filesToDelete)
            .then(() => {
                setFilesToDelete([]);
                return uploadFiles(filesToUpload);
            })
            .then((res) => {
                setFilesToUpload([]);
                onClose();
                return res;
            })
            .catch((err: Error) => {
                setErrorMessage(err.message);
            });
        setIsBusy(false);
    };

    const addFile = (e: HTMLInputEvent) => {
        const fileList: FileList | null = e.target.files;
        if (!fileList) {
            return;
        }

        const newFiles = [...filesToUpload];
        Array.from(fileList).forEach((file: File) => {
            newFiles.push(file);
        });
        setFilesToUpload(newFiles);
    };

    const removeFile = (index: number) => {
        const newFiles = [...filesToUpload];
        newFiles.splice(index, 1);
        setFilesToUpload(newFiles);
    };

    const addFileToDelete = (file: FileUpload) => {
        const newFilesToDelete = [...filesToDelete];
        newFilesToDelete.push(file);
        setFilesToDelete(newFilesToDelete);
    };

    const FILE_UPLOAD_SERVICE_URL: string | undefined = process.env.REACT_APP_USER_ACCOUNT_BACK_END_API;

    const canWrite = new Selector(useContext).checkBoardWriteAccess();

    const newFilesDisplay = filesToUpload.map((it, index) => {
        return <div key={index}>
                <span className="uploadFile">
                    <FontAwesome name="arrow-up" style={{marginRight: '5px', color: 'green'}}/>
                    <TruncateFileName fileName={it.name} maxCharacters={35}/>
                    <Button variant="link" onClick={() => removeFile(index)}>
                        <FontAwesome name="trash-alt"/>
                    </Button>
                </span>
        </div>
    });

    const existingFilesDisplay = fileUploads
        .filter(it => !filesToDelete.find(file => it.id === file.id))
        .map((it: FileUpload, index) => {
            return <div key={index}>
                <span className="uploadFile"><TruncateFileName fileName={it.fileName} maxCharacters={35}/>
                    <a className="btn btn-link"
                       href={`${FILE_UPLOAD_SERVICE_URL}${HttpUrls.FILE_DOWNLOAD_FOR_ID(it.id)}`}>
                        <FontAwesome name="download"/>
                    </a>
                    <ConfirmAction confirmButtonText="Delete File"
                                   confirmMessage={
                                       <span>Are you sure you want to remove <br/><b>"{it.fileName}"?</b></span>}
                                   onClick={() => addFileToDelete(it)}
                                   disabled={!canWrite}>
                        <Button variant="link" disabled={!canWrite}>
                            <FontAwesome name="trash-alt"/>
                        </Button>
                    </ConfirmAction>
                </span>
            </div>
        });

    return (
        <div className="slate-background-transparent form-holder">
            <Form className="form">
                <Form.Group className="form">
                    <Form.Label>Upload Files</Form.Label>
                </Form.Group>

                <Form.Group className="form">
                    <div className="file-upload">
                        <input multiple type="file" id="selectedFile"
                               disabled={!canWrite}
                               style={{display: 'none'}}
                               onChange={(e: any) => addFile(e)}/>
                        <Button variant="primary"
                                disabled={!canWrite}
                                onClick={() => document?.getElementById('selectedFile')?.click()}>
                            Upload
                        </Button>

                        <div className="file-holders">
                            {newFilesDisplay}
                            {existingFilesDisplay}
                        </div>
                        {errorMessage && <FileError errorMessage={errorMessage}/>}
                        {uploadPercent &&
                        <FileTransferProgressBar fileTransferPercent={uploadPercent} label={"Uploading Files"}/>}
                    </div>
                </Form.Group>

                <ButtonGroup style={{paddingTop: '0.5rem'}}>
                    <Button variant="light" onClick={onSave}>
                        <LoadingComponent isBusy={isBusy}/> OK
                    </Button>
                    <Button variant="dark"
                            onClick={onClose}>
                        Cancel
                    </Button>
                </ButtonGroup>
            </Form>
        </div>
    );
};

const FileTransferProgressBar: React.FC<{ fileTransferPercent: number, label: string }> = ({fileTransferPercent, label}) => {
    return (<div className="progress-bar-holder">
        <span className="progress-bar-label">{label}</span>
        <ProgressBar animated variant={"success"} now={fileTransferPercent}/>
    </div>);
};

const FileError: React.FC<{ errorMessage: string }> = ({errorMessage}) => {
    const {state}: { state: AppState } = useContext(store);
    const level: "BASIC" | "PREMIUM" | "WORKFORCE" | undefined = state.features?.level;

    const showFileUploadUpgrade = (level === "BASIC" || level === "PREMIUM")
        && (errorMessage.includes("Cannot upload files over") || errorMessage.includes("Exceeded total upload limit for this board"));

    return (
        <Alert variant="danger">
            <span className="file-error">
                {errorMessage}

                {showFileUploadUpgrade && <>
                    <br/>
                    <FeatureNotAvailable supportedFeatureLevels={["WORKFORCE"]}
                                         displayText={
                                             <span>You can upgrade this limit by upgrading your account.</span>}/>
                </>}
            </span>
        </Alert>
    )
};

const TruncateFileName: React.FC<{ fileName: string, maxCharacters: number }> = ({fileName, maxCharacters}) => {
    return (fileName || "").length > maxCharacters
        ? <>{fileName.substring(0, maxCharacters)}...</>
        : <>{fileName}</>
};


export default TaskUploadFileContainer;