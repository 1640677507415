import React, {useContext} from "react";
import {Dropdown} from "react-bootstrap";
import FontAwesome from "react-fontawesome";
import {store} from "../../../../AppState";
import {User} from "../../../../model/Account";

const findUser = (friendUserId: string | undefined, users: User[]): User | undefined => {
    return friendUserId ? users.find(it => it.userId === friendUserId) : undefined;
};

const getSelectedAssigneeName = (userId: string | undefined, users: User[]): string | undefined => {
    if (!userId) {
        return undefined;
    }

    const assigneeCreator = findUser(userId, users);
    if (assigneeCreator) {
        return assigneeCreator?.displayName
    }

    return ""
};

export const SelectedAssignee: React.FC<{ selectedAssignee: string | undefined }> = ({selectedAssignee}) => {
    const {state} = useContext(store);
    const users = state?.users || [];
    const selectedAssigneeName: string | undefined = getSelectedAssigneeName(selectedAssignee, users);

    return selectedAssigneeName?.length
        ? <><br/><span className="assignee">Assigned to {selectedAssigneeName}</span></>
        : <></>
};

interface AssigneeChooserProps {
    selectedAssignee: string | undefined,
    setSelectedAssignee: (userId: string | undefined) => void,
    allowOnlyBoardUsers?: boolean
    requireSelection?: boolean
}

export const AssigneeChooser: React.FC<AssigneeChooserProps> = ({
                                                                    selectedAssignee, setSelectedAssignee,
                                                                    allowOnlyBoardUsers = false,
                                                                    requireSelection = false
                                                                }) => {
    const {state} = useContext(store);
    const allUsers = [
        ...(state.selectedBoard?.sharedWithUsers || []),
        ...(state.selectedBoardOrganisation?.adminUserIds || []),
        ...(state.selectedBoardOrganisation?.userIds || []),
        ...(!!state.lastSuccessfulLogin?.userId ? [state.lastSuccessfulLogin.userId] : [])
    ];

    const users: User[] = state.users?.filter(it => !allowOnlyBoardUsers || allUsers.includes(it.userId)) || [];
    const successfulLogin = state.lastSuccessfulLogin;

    const selectedAssigneeName: string | undefined = getSelectedAssigneeName(selectedAssignee, users);
    const isSupported = ["PREMIUM", "WORKFORCE"].includes(state.features?.level as string);
    const isSelected = (userId: string | undefined): boolean => selectedAssignee === userId;
    const showRedBorder = requireSelection && !selectedAssignee;

    const selectAssignee = (userId: string) => {
        if (isSelected(userId)) {
            setSelectedAssignee(undefined);
        } else {
            setSelectedAssignee(userId);
        }
    };

    return <Dropdown>
        <Dropdown.Toggle className="form-control"
                         style={{
                             backgroundColor: 'white',
                             color: 'black',
                             border: showRedBorder ? '1px solid red' : ''
                         }}
                         id="dropdown-basic">
            {isSupported ? selectedAssigneeName : ""}
        </Dropdown.Toggle>
        {
            (!!successfulLogin) && <Dropdown.Menu>
                {
                    <Dropdown.Item disabled={!isSupported} onClick={() => setSelectedAssignee(undefined)}>
                        Clear
                    </Dropdown.Item>
                }
                {
                    users.map((it: User, index: number) =>
                        <Dropdown.Item disabled={!isSupported} onClick={() => selectAssignee(it.userId)}
                                       key={index}
                                       style={isSelected(it.userId) ? {backgroundColor: 'lightgray'} : {}}>
                            <FontAwesome name="square-full"/> {it.displayName}
                        </Dropdown.Item>)
                }
            </Dropdown.Menu>
        }
    </Dropdown>
};
