import React, {useState} from "react";
import {Form, FormControl, FormGroup} from "react-bootstrap";
// @ts-ignore
import GoogleLogin from "react-google-login";
import BusyFakeButton from "../../../../component/busyFakeButton/BusyFakeButton";
import {ValidationUtils} from "../../../../util/ValidationUtils";
import {SignUpRequest} from "../../../../model/Account";

interface SignUpSectionProps {
    signUp: (signUpRequest: SignUpRequest) => Promise<void>
}

export const SignUpSection: React.FC<SignUpSectionProps> = ({signUp}) => {
    const [emailAddress, setEmailAddress] = useState<string>("");
    const [firstName, setFirstName] = useState<string>("");
    const [lastName, setLastName] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [confirmPassword, setConfirmPassword] = useState<string>("");
    const [isSigningUp, setIsSigningUp] = useState(false);

    const signUpHandler = async (func: () => Promise<void>) => {
        setIsSigningUp(true);
        await func().finally(() => setIsSigningUp(false));
    };

    const handleSignUpKeyPress = async (e: React.KeyboardEvent<HTMLElement>) => {
        if (e.key === 'Enter' && !e.shiftKey && isSignUpFormValid) {
            e.preventDefault();
            await handleNormalSignUp();
        }
    };

    async function handleNormalSignUp() {
        await signUpHandler(() => signUp({
            emailAddress,
            firstName,
            lastName,
            password,
            accountType: "NORMAL"
        }));
    }

    const handleGoogleSignUp = async (response: any) => {
        await signUpHandler(() => signUp({
            emailAddress: response.profileObj.email,
            firstName: response.profileObj.givenName,
            lastName: response.profileObj.familyName,
            password: response.tokenId,
            accountType: "GOOGLE"
        }));
    };

    const isValidEmail = ValidationUtils.validateEmail(emailAddress);
    const validPassword = ValidationUtils.validatePassword(password);
    const isMatchingPassword = ValidationUtils.matchingPassword(password, confirmPassword);
    const isValidFirstName = ValidationUtils.validateLength(firstName, 1);
    const isValidLastName = ValidationUtils.validateLength(lastName, 1);

    const isSignUpFormValid = ValidationUtils.combineValidationResults([
        isValidEmail,
        validPassword,
        isMatchingPassword,
        isValidFirstName,
        isValidLastName
    ]);

    return (
        <Form noValidate>
            <FormGroup id="signUpForm">
                <FormControl type="text"
                             id="signUpEmail"
                             value={emailAddress}
                             onChange={(e: any) => setEmailAddress((e as any).target.value)}
                             placeholder="Enter email address"
                             onKeyPress={handleSignUpKeyPress}
                             isInvalid={!!emailAddress && !isValidEmail}
                             isValid={isValidEmail}/>
                <FormControl type="text"
                             id="firstName"
                             value={firstName}
                             onChange={(e: any) => setFirstName((e as any).target.value)}
                             placeholder="Enter first name"
                             onKeyPress={handleSignUpKeyPress}
                             isInvalid={!!firstName && !isValidFirstName}
                             isValid={isValidFirstName}/>
                <FormControl type="text"
                             id="lastName"
                             value={lastName}
                             onChange={(e: any) => setLastName((e as any).target.value)}
                             placeholder="Enter last name"
                             onKeyPress={handleSignUpKeyPress}
                             isInvalid={!!lastName && !isValidLastName}
                             isValid={isValidLastName}/>
            </FormGroup>

            <FormGroup id="signUpFormPassword">
                <FormControl type="password"
                             id="password"
                             value={password}
                             onChange={(e: any) => setPassword((e as any).target.value)}
                             placeholder="Enter password"
                             onKeyPress={handleSignUpKeyPress}
                             required
                             isInvalid={!!password && !validPassword}
                             isValid={validPassword}/>
                <FormControl type="password"
                             id="confirmPassword"
                             value={confirmPassword}
                             onChange={(e: any) => setConfirmPassword((e as any).target.value)}
                             placeholder="Confirm password"
                             onKeyPress={handleSignUpKeyPress}
                             isInvalid={!!confirmPassword && !isMatchingPassword}
                             isValid={isMatchingPassword}/>

                <div className="help-block">
                    <p>- Minimum 8 characters.</p>
                    <p>- At least one upper case letter.</p>
                    <p>- At least lower case letter.</p>
                    <p>- At least one number.</p>
                </div>

                <hr/>
                <BusyFakeButton className="button-center" isBusy={isSigningUp}
                                size="sm"
                                onClick={handleNormalSignUp}
                                disabled={!isSignUpFormValid}>
                    Sign Up
                </BusyFakeButton>
                <hr/>
                <GoogleLogin
                    clientId={process.env.REACT_APP_GOOGLE_APP_ID || ""}
                    buttonText="Login"
                    autoLoad={false}
                    onSuccess={(response: any) => handleGoogleSignUp(response)}
                    onFailure={(response) => console.error(response)}
                    render={(renderProps: { onClick: () => void } | undefined) => (
                        <BusyFakeButton className="button-center button-social-network button-bottom"
                                        isBusy={isSigningUp}
                                        size="sm"
                                        onClick={renderProps?.onClick}>
                            <span className="fab fa-google"/> Sign Up with Google
                        </BusyFakeButton>
                    )}
                />
            </FormGroup>
        </Form>
    )
};