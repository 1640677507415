import React, {useCallback, useContext} from "react";
import {Task} from "../../../../../model/Task";
import RecurringTaskForm from "./RecurringTaskForm";
import {store} from "../../../../../AppState";
import TaskService from "../../../../../service/TaskService";
import {DispatcherCreator} from "../../../../../state/dispatcherCreator";

interface RecurringTaskPanelContainerProps {
    task: Task,
    onClose: () => void;
}

const RecurringTaskFormContainer: React.FC<RecurringTaskPanelContainerProps> = ({task, onClose}) => {
    const {state, dispatch} = useContext(store);
    const dispatcherCreator = useCallback(() => new DispatcherCreator(dispatch), [dispatch]);

    const userId = state.lastSuccessfulLogin?.userId;
    const selectedBoardId = state.selectedBoard?.id;

    const updateTasks = async (updateTask: Task) => {
        const updateTasks = state.tasks.map(it => it.id === updateTask.id ? updateTask : it);
        if (userId && selectedBoardId) {
            await new TaskService().saveTasks(userId, updateTasks, selectedBoardId).then(dispatcherCreator().setTasks);
        }
    };

    return (
        <RecurringTaskForm task={task} updateTask={updateTasks} onClose={onClose}/>
    )
};

export default RecurringTaskFormContainer;