import React, {useCallback, useContext, useEffect, useState} from "react";
import {Button} from "react-bootstrap";
import FontAwesome from "react-fontawesome";
import './PomodoroPanel.css'
import {store} from "../../../../AppState";
import {DEFAULT_POMODORO, PomodoroSettings, UserPreferences} from "../../../../model/UserPreferences";
import {PomodoroPanel} from "./PomodoroPanel";
import {DispatcherCreator} from "../../../../state/dispatcherCreator";
import {Login} from "../../../../model/Account";
import {UserPreferencesService} from "../../../../service/UserPreferencesService";
import {PomodoroSettingsPanel} from "./PomodoroSettingsPanel";
import {PomodoroHelpPanel} from "./PomodoroHelpPanel";

export const PomodoroPanelContainer: React.FC = () => {
    const {state, dispatch} = useContext(store);
    const dispatcherCreator = useCallback(() => new DispatcherCreator(dispatch), [dispatch]);
    const successfulLogin: Login | undefined = state.lastSuccessfulLogin;
    const userId = successfulLogin?.userId;
    const userPreferences: UserPreferences | undefined = state.userPreferences;

    const pomodoroSettingsFromState = state.userPreferences?.pomodoroSettings;
    const pomodoroSettings = pomodoroSettingsFromState || DEFAULT_POMODORO;

    const [settings, setSettings] = useState(pomodoroSettings);
    const [isEditingSettings, setIsEditingSettings] = useState<boolean>(false);
    const [isShowingHelpMode, setIsShowingHelpMode] = useState<boolean>(false);

    useEffect(() => {
        if (pomodoroSettingsFromState) {
            setSettings(pomodoroSettingsFromState)
        }
    }, [pomodoroSettingsFromState, setSettings]);

    const setUserPreferences = async (userPreferences: UserPreferences) => {
        if (!!userId) {
            await new UserPreferencesService().saveUserPreferences(userId, userPreferences).then(dispatcherCreator().setUserPreferences);
        }
    };

    const toggleFocusMode = async () => {
        await setPomodoroSettings({
            ...settings,
            usePomodoroMode: !settings.usePomodoroMode
        })
    };

    const toggleEditingSettingsMode = () => {
        setIsEditingSettings(!isEditingSettings);
    };

    const toggleShowHelpMode = () => {
        setIsShowingHelpMode(!isShowingHelpMode);
    };

    const setPomodoroSettings = async (settings: PomodoroSettings) => {
        setSettings(settings);
        if (userPreferences) {
            await setUserPreferences({...userPreferences, pomodoroSettings: settings})
        }
    };

    if (!settings.usePomodoroMode) {
        return (
            <div className="pomodoro-bar medium-and-above-screens">
                <div className="focus-button-holder">
                    <Button variant="dark" onClick={toggleFocusMode}>
                        Use Pomodoro Focus Mode <FontAwesome name="brain"/>
                    </Button>
                </div>
            </div>
        )
    }

    if (isShowingHelpMode) {
        return <PomodoroHelpPanel toggleShowHelpMode={toggleShowHelpMode}/>
    }

    if (isEditingSettings) return (
        <PomodoroSettingsPanel pomodoroSettings={settings}
                               setPomodoroSettings={setPomodoroSettings}
                               toggleEditingSettings={toggleEditingSettingsMode}/>
    );

    return <PomodoroPanel pomodoroSettings={settings}
                          setPomodoroSettings={setPomodoroSettings}
                          toggleFocusMode={toggleFocusMode}
                          toggleEditingSettings={toggleEditingSettingsMode}
                          toggleShowHelpMode={toggleShowHelpMode}/>
};


