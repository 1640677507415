import React, {useState} from "react";
import {Form, FormControl, FormGroup} from "react-bootstrap";
import BusyFakeButton from "../../../../component/busyFakeButton/BusyFakeButton";
import {ValidationUtils} from "../../../../util/ValidationUtils";

interface ResetPasswordProps {
    resetPassword: (request: { emailAddress: string }) => Promise<void>
}

export const ResetPasswordSection: React.FC<ResetPasswordProps> = ({resetPassword}) => {

    const [emailAddress, setEmailAddress] = useState<string>("");
    const [isResettingPassword, setIsResettingPassword] = useState(false);

    const signUpHandler = async (func: () => Promise<void>) => {
        setIsResettingPassword(true);
        await func().finally(() => setIsResettingPassword(false));
    };

    const handleKeyPress = async (e: React.KeyboardEvent<HTMLElement>) => {
        if (e.key === 'Enter' && !e.shiftKey && isValid) {
            e.preventDefault();
            await passwordReset();
        }

    };

    async function passwordReset() {
        await signUpHandler(() => resetPassword({
            emailAddress
        }));
    }

    const isValid = ValidationUtils.validateLength(emailAddress, 1);

    return (
        <Form noValidate>
            <FormGroup id="loginForm">
                <FormControl type="text"
                             id="resetPasswordEmail"
                             value={emailAddress}
                             onChange={(e: any) => setEmailAddress((e as any).target.value)}
                             placeholder="Enter email address"
                             onKeyPress={handleKeyPress}/>

                <hr/>
                <BusyFakeButton className="button-center" isBusy={isResettingPassword}
                                size="sm"
                                onClick={passwordReset}
                                disabled={!isValid}>
                    Reset Password
                </BusyFakeButton>
            </FormGroup>
        </Form>
    )
};