import HttpUtilService from "./HttpUtilService";
import {PostSurveyRequest, UserEvent} from "../model/Analytics";
import {HttpUrls} from "../util/Properties";
import {Login} from "../model/Account";

class AnalyticsService {
    USER_ANALYTICS_FEEDBACK_URL: string;
    httpUtilService: HttpUtilService;

    constructor(USER_ANALYTICS_FEEDBACK_URL: string | undefined = process.env.REACT_APP_USER_ACCOUNT_BACK_END_API,
                httpUtilService: HttpUtilService = new HttpUtilService()) {

        this.USER_ANALYTICS_FEEDBACK_URL = USER_ANALYTICS_FEEDBACK_URL || "";
        this.httpUtilService = httpUtilService;
    }

    logPageView(eventType: string, login?: Login) {
        const request: UserEvent = {
            eventType: eventType,
            page: window.location.href,
            additionalData: {
                userAgent: navigator.userAgent,
                language: navigator.language,
                userEmail: login?.userEmail
            }
        };

        return this.httpUtilService.post(HttpUrls.USER_ANALYTICS_EVENT, this.USER_ANALYTICS_FEEDBACK_URL, request).then((response) => response.data);

    }

    postSurveyFeedback(request: PostSurveyRequest) {
        return this.httpUtilService.post(HttpUrls.USER_ANALYTICS_FEEDBACK, this.USER_ANALYTICS_FEEDBACK_URL, request).then((response) => response.data);
    }

}

export default AnalyticsService;