import React from "react";
import {Routes} from "../../../util/Properties";
import FontAwesome from "react-fontawesome";

export const V4: React.FC<{ handleClose: () => void }> = ({handleClose}) => {
    return <>
        <div className="login-text-holder">
            <a href={Routes.PRICING_PATH} onClick={handleClose} className="feature-text">
                <FontAwesome name="info-circle"/> <span style={{fontSize: '1.0rem'}}>View all the features here</span>
            </a>
        </div>
    </>
};