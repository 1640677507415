export default class SessionStore {

    private static defaultConverter = (json: any) => json;

    static setItem(key: string, data: any) {
        localStorage.setItem(key, JSON.stringify(data));
    }

    static getItem(key: string, converter: (json: any) => any = SessionStore.defaultConverter): any {
        const item = localStorage.getItem(key);
        return item !== null
            ? converter(JSON.parse(item))
            : null;
    }

    static getItemOrDefault(key: string, defaultValue: any, converter: (json: any) => any = SessionStore.defaultConverter) {
        const item = SessionStore.getItem(key, converter);
        return item !== null
            ? item
            : defaultValue;
    }

    static clearAll() {
        localStorage.clear();
    }

}