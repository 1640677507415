import React, {MouseEvent, MouseEventHandler, PropsWithChildren} from 'react';
import './FakeButton.css'
import {NOOP} from "../../util/Functions";

export interface FakeButtonProps extends React.HTMLAttributes<HTMLDivElement> {
    onClick?: MouseEventHandler<any>,
    href?: string,
    className?: string
    disabled?: boolean
    size?: "sm" | "md" | "lg",
}

const FakeButton: React.FC<FakeButtonProps> = ({onClick = NOOP, className, href, children, disabled, size}: PropsWithChildren<FakeButtonProps>) => {
    const handleOnClick = (e: MouseEvent<any>) => {
        if (disabled) {
            return;
        }

        if (href) {
            window.location.href = href;
        }
        onClick(e);
    };

    return (
        <div className={`fake-button ${className} ${size} ${disabled ? 'disabled' : ''}`} onClick={handleOnClick}>
            {children}
        </div>

    )
};

export default FakeButton