import {FriendsWithPendingInvites} from "../model/Friend";
import {Action} from "./actions";
import {Notification} from "../model/Notification";
import {Board, UserBoards} from "../model/Board";
import {Organisation} from "../model/Organisation";
import {Task} from "../model/Task";
import {Goal} from "../model/Goal";
import {Features, Login, User} from "../model/Account";
import {UserPreferences} from "../model/UserPreferences";
import {PomodoroState} from "../AppState";

type Dispatcher = (_: Action) => void;

export class DispatcherCreator {
    dispatch: Dispatcher;

    constructor(dispatcher: (_: Action) => void) {
        this.dispatch = dispatcher;
    }

    openSignIn = () => this.dispatch({type: "OPEN_SIGN_IN"});

    onSignUp = (loginResponse: Login) => this.dispatch({type: "LOGIN_SUCCESS", loginResponse: loginResponse});

    setPaymentSignedUp = (paymentSignedUp: boolean) => this.dispatch({
        type: "SET_PAYMENT_SIGNED_UP",
        value: paymentSignedUp
    });

    signOut = () => {
        this.dispatch({type: "SIGNOUT"});
        this.openSignIn();
    };

    closeSignInPanel = () => this.dispatch({type: "CLOSE_SIGN_IN"});

    setTasks = (tasks: Task[]) => this.dispatch({type: "SET_TASKS", tasks: tasks});

    updateSingleTask = (update: Partial<Task>) => this.dispatch({
        type: "UPDATE_SINGLE_TASK",
        update: update
    });

    setGoals = (goals: Goal[]) => this.dispatch({type: "SET_GOALS", goals: goals});

    setNotifications = (notifications: Notification[]) =>
        this.dispatch({type: "SET_NOTIFICATIONS", notifications: notifications});

    setFriendsPendingInvites = (invites: FriendsWithPendingInvites) =>
        this.dispatch({type: "SET_FRIENDS_PENDING_INVITES", friendsWithPendingInvites: invites});

    setUserBoards = (userBoards: UserBoards) =>
        this.dispatch({
            type: "SET_BOARDS",
            boards: userBoards.boards,
            sharedBoards: userBoards.sharedBoards,
            selectedBoardId: userBoards.selectedBoardId,
            selectedBoard: userBoards.selectedBoard
        });

    setSharedBoards = (sharedBoards: Board[]) => {
        this.dispatch({type: "SET_SHARED_BOARDS", sharedBoards: sharedBoards})
    };

    updateSingleBoard = (update: Partial<Board>) => this.dispatch({type: "UPDATE_SINGLE_BOARD", board: update});

    setOrganisations = (organisations: Organisation[] | undefined) =>
        this.dispatch({type: "SET_ORGANISATIONS", organisations: organisations});

    setUsers = (users: User[]) => {
        this.dispatch({type: "SET_USERS", users: users});
    };

    setSideBarShown = (value: boolean) => this.dispatch({type: "SET_SIDE_BAR_SHOWN", value: value});

    setAutomationPanelShown = (value: boolean) => this.dispatch({type: "SET_AUTOMATION_PANEL", value: value});

    setShowOrganisationPanelShown = (value: boolean) => this.dispatch({type: "SET_ORGANISATION_PANEL", value: value});

    setShowFeedbackPanel = (value: boolean) => this.dispatch({type: "SET_SHOW_FEEDBACK_PANEL", value: value});

    markFeedbackSubmitted = () => this.dispatch({type: "FINISHED_SUBMITTED_FEEDBACK"});

    setUserPreferences = (userPreferences: UserPreferences) => this.dispatch({
        type: "SET_USER_PREFERENCES",
        userPreferences: userPreferences
    });

    setFeatures = (features: Features) => this.dispatch({
        type: "SET_FEATURES",
        features: features
    });

    updateTaskFilterWithGoal = (goal: Goal | undefined) => this.dispatch({
        type: "UPDATE_TASK_FILTER",
        taskFilter: {showTaskMatchingGoal: goal}
    });

    setErrorBoardAuthMessage = (message: string) => this.dispatch({
        type: "SET_ERROR_BOARD_AUTH_MESSAGE",
        message: message
    });

    setCloseErrorAuthMessage = () => this.dispatch({
        type: "SET_ERROR_BOARD_AUTH_MESSAGE",
        message: undefined
    });

    setPomodoroState = (state: PomodoroState) => this.dispatch({
        type: "SET_POMODORO_STATE",
        state: state
    });

    resetPomodoroState = () => this.dispatch({
        type: "RESET_POMODORO_STATE"
    })

}