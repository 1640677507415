import React from "react";
import {Notification} from "../../../../../model/Notification";
import ConfirmAction from "../../../../../component/confirmAction/ConfirmAction";
import {Button} from "react-bootstrap";

interface NotificationPanelProps {
    notifications: Notification[];
    deleteNotifications: (notifications: Notification[]) => Promise<void>;
    updateNotificationsSeen: (notifications: Notification[]) => Promise<void>;
}

export const NotificationPanel: React.FC<NotificationPanelProps> = ({notifications, updateNotificationsSeen, deleteNotifications}) => {
    const handleNotificationClick = async (notification: Notification) => {
        await updateNotificationsSeen([notification]);
        switch (notification.type) {
            case "TASK_INVITE":
                window.location.href = `/plan?showTask=${notification.additionalData?.id}`;
                break;
            case "TASK_CHANGE":
                window.location.href = `/plan?showTask=${notification.additionalData?.taskId}`;
                break;
            case "BOARD_INVITE":
                window.location.href = `/plan?selectBoard=${notification.additionalData?.id}`;
                break;
        }
    };

    return (
        <div className="side-bar-notifications slate-background">
            <div className="side-bar-title">
                <b>Notifications</b>
            </div>

            <div className="notification-display">
                {
                    !notifications.length && <div className="notification">You have no notifications yet!</div>
                }
                {
                    notifications.map((it, index) =>
                        <div key={index} className={`notification ${it.seen ? 'seen' : ''}`}
                             onClick={() => handleNotificationClick(it)}>
                            {it.notificationText}
                        </div>)
                }
            </div>
            {
                !!notifications.length &&
                <ConfirmAction confirmMessage="Are you sure you want to clear all notifications?"
                               confirmButtonText="Clear"
                               onClick={() => deleteNotifications(notifications)}>
                    <Button variant="link">Clear all notifications</Button>
                </ConfirmAction>
            }
        </div>
    )
};