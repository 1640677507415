import TasksBoard from "./TasksBoard";
import React, {useCallback, useContext, useEffect} from "react";
import {store} from "../../../../AppState";
import TaskService from "../../../../service/TaskService";
import {Task} from "../../../../model/Task";
import {Board} from "../../../../model/Board";
import {DispatcherCreator} from "../../../../state/dispatcherCreator";


const TasksBoardContainer: React.FC = () => {
    const {state, dispatch} = useContext(store);
    const dispatcherCreator = useCallback(() => new DispatcherCreator(dispatch), [dispatch]);

    const boardCreatorUserId = state.selectedBoard?.creatorUserId;
    const selectedBoardId = state.selectedBoard?.id;
    const boards = state.boards;
    const successfulLogin = state?.lastSuccessfulLogin;

    const setBoardAuthError = useCallback((error: Error) => {
        if (error.message.includes('Invalid Access')) {
            dispatcherCreator().setErrorBoardAuthMessage(error.message);
        }
    }, [dispatcherCreator]);

    const setTasks = useCallback((tasks) => {
        dispatcherCreator().setTasks(tasks);
        if (boardCreatorUserId && !!selectedBoardId) {
            new TaskService().saveTasks(boardCreatorUserId, tasks, selectedBoardId)
                .then(dispatcherCreator().setTasks).catch(setBoardAuthError);
        }
    }, [setBoardAuthError, selectedBoardId, boardCreatorUserId, dispatcherCreator]);

    const moveTasks = useCallback((tasks: Task[], targetBoard: Board) => {
        dispatcherCreator().setTasks(tasks);
        if (boardCreatorUserId && selectedBoardId && targetBoard.id) {
            return new TaskService().moveTasks(boardCreatorUserId, tasks, selectedBoardId, targetBoard.id)
                .then(dispatcherCreator().setTasks).catch(setBoardAuthError);
        }
        return Promise.resolve();
    }, [setBoardAuthError, selectedBoardId, dispatcherCreator, boardCreatorUserId]);

    const setGoals = useCallback((goals) => {
        dispatcherCreator().setGoals(goals);
        if (boardCreatorUserId && !!selectedBoardId) {
            return new TaskService().saveGoals(boardCreatorUserId, goals, selectedBoardId)
                .then(dispatcherCreator().setGoals).catch(setBoardAuthError);
        }
        return Promise.resolve();
    }, [setBoardAuthError, selectedBoardId, boardCreatorUserId, dispatcherCreator]);

    const setBoards = useCallback(async (boardsToSave: Board[]) => {
        if (!!boardCreatorUserId) {
            await new TaskService().saveBoards(boardCreatorUserId, boardsToSave, state.selectedBoard)
                .then(dispatcherCreator().setUserBoards).catch(setBoardAuthError);
        }
    }, [setBoardAuthError, boardCreatorUserId, dispatcherCreator, state.selectedBoard]);

    useEffect(() => {
        if (boardCreatorUserId && !!selectedBoardId) {
            new TaskService().getTasks(boardCreatorUserId, selectedBoardId).then(dispatcherCreator().setTasks);
        }
    }, [dispatcherCreator, selectedBoardId, boardCreatorUserId]);

    return (
        <TasksBoard isSignedIn={!!successfulLogin}
                    successfulLogin={successfulLogin}
                    tasks={state.tasks}
                    goals={state.goals}
                    setTasks={setTasks}
                    setGoals={setGoals}
                    taskFilter={state.taskFilter}
                    selectedBoard={state.selectedBoard}
                    boards={boards}
                    setBoards={setBoards}
                    moveTasks={moveTasks}
                    setAutomationPanelShown={dispatcherCreator().setAutomationPanelShown}/>
    )
};

export default TasksBoardContainer