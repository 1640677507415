import React from 'react';
import {Button} from "react-bootstrap";
import FontAwesome from "react-fontawesome";

interface ColumnExpanderProps {
    isExpanded: boolean,
    setExpanded: (isExpanded: boolean) => void,
    showMoreMessage?: string
}

const ColumnExpander: React.FC<ColumnExpanderProps> = ({isExpanded, setExpanded, showMoreMessage}) => {
    return (
        <>
            {
                !isExpanded &&
                <Button variant="link" className="expand-button" onClick={() => setExpanded(true)}>
                    {!!showMoreMessage && <><span>{showMoreMessage}</span><br/></>}
                    <FontAwesome name="chevron-down"/>
                    <FontAwesome name="chevron-down"/>
                    <FontAwesome name="chevron-down"/>
                </Button>
            }
            {
                isExpanded &&
                <Button variant="link" className="expand-button" onClick={() => setExpanded(false)}>
                    <FontAwesome name="chevron-up"/>
                    <FontAwesome name="chevron-up"/>
                    <FontAwesome name="chevron-up"/>
                </Button>
            }
        </>
    )
};

export default ColumnExpander;