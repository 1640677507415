import {TaskStatus} from "./Task";

export interface Automation {
    id?: string;
    rule: TaskRule;
    action: AutomationAction;
}

export const toAutomation = (json: any): Automation => {
    const {id, rule, action} = json;
    return {
        id,
        rule,
        action,
    }
};

export interface TaskRule {
    reachesStatus: TaskStatus;
}

export type AutomationAction =
    NoAction
    | NotificationAction
    | NotificationForAllAction
    | AssignToUserAction
    | SendEmailAction
    | SendEmailToUserAction;

export interface NoAction {
    type: "NO_ACTION";
}

export interface NotificationAction {
    type: "NOTIFICATION"
    userId?: string;
}

export interface NotificationForAllAction {
    type: "NOTIFICATION_FOR_ALL"
}

export interface AssignToUserAction {
    type: "ASSIGN_TO_USER"
    userId?: string;
}

export interface SendEmailAction {
    type: "SEND_EMAIL",
    emailAddress?: string;
    subjectTitle?: string;
}

export interface SendEmailToUserAction {
    type: "SEND_EMAIL_USER",
    userId?: string;
    subjectTitle?: string;
}