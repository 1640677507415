import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {isNavigationsSupported} from "./util/BrowserCompatibilityFunctions";

ReactDOM.render(
    <React.StrictMode>
        <App/>
    </React.StrictMode>,
    document.getElementById('root')
);

if (isNavigationsSupported()) {
    navigator.serviceWorker?.register('sw.js');
}