import dayjs from "dayjs";

export type GoalStatus = "IN_PROGRESS" | "DONE";

export interface Goal {
    id: string,
    goalName: string,
    status: GoalStatus,
    colour: string,
    dueDate?: dayjs.Dayjs,
    creatorUserId?: string,
    shareAllTasks?: boolean
}

export const toGoal = (json: any): Goal => {
    const {id, goalName, status, colour, dueDate, creatorUserId, shareAllTasks} = json;
    return {
        id,
        goalName,
        status,
        colour,
        dueDate: !!dueDate ? dayjs(dueDate) : undefined,
        creatorUserId,
        shareAllTasks
    }
};
