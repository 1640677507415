import React, {PropsWithChildren, useContext} from "react";
import {store} from "../../AppState";
import {FeatureLevel} from "../../model/Account";

interface ShowFeatureProps {
    supportedFeatureLevels: FeatureLevel[];
}

const ShowFeature: React.FC<ShowFeatureProps> = ({supportedFeatureLevels, children}: PropsWithChildren<ShowFeatureProps>) => {
    const {state} = useContext(store);
    const featureLevel: FeatureLevel | undefined = state.features?.level;

    if (supportedFeatureLevels.find(it => it === featureLevel)) {
        return <>{children}</>;
    }

    return <></>;
};

export default ShowFeature;