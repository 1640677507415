import React, {useContext, useEffect, useState} from "react";
import ReactPixel from 'react-facebook-pixel';
import {store} from "../../AppState";

const PixelTracker: React.FC<{eventName: string}> = ({eventName}) => {
    const pixelId = "446765706486778";

    const {state} = useContext(store);
    const lastSuccessfulLogin = state.lastSuccessfulLogin;

    useEffect(() => {
        const usePixel: boolean = process.env.REACT_APP_USE_PIXEL === "true";

        const data = {
            loggedIn: !!lastSuccessfulLogin,
            emailAddress: lastSuccessfulLogin?.userEmail,
            firstName: lastSuccessfulLogin?.firstName,
            lastName: lastSuccessfulLogin?.lastName
        }

        if (usePixel) {
            ReactPixel.init(pixelId);
            ReactPixel.track(eventName, data);
        }
    }, [eventName]);

    return <></>
}

export default PixelTracker;
