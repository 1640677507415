import {SendEmailAction} from "../../../model/Automation";
import React from "react";
import {ValidationUtils} from "../../../util/ValidationUtils";
import {FormControl} from "react-bootstrap";

interface SendEmailToAnyoneOptionProps {
    action: SendEmailAction;
    setAction: (action: SendEmailAction) => void;
}

const validEmail = (action: SendEmailAction) => ValidationUtils.validateEmail(action.emailAddress);

const validEmailSubject = (action: SendEmailAction) =>
    ValidationUtils.validateLength(action.subjectTitle || "", 1);

export const isValidSendEmailToAnyone = (action: SendEmailAction) => {
    return ValidationUtils.combineValidationResults([
        validEmail(action),
        validEmailSubject(action)
    ]);
};

export const SendEmailToAnyoneOption: React.FC<SendEmailToAnyoneOptionProps> = ({action, setAction}) => {
    const setEmailAddress = (emailAddress: string) => setAction({...action, emailAddress: emailAddress});
    const setEmailSubject = (subjectTitle: string) => setAction({...action, subjectTitle: subjectTitle});
    const isValidEmail = validEmail(action);
    const isValidEmailSubject = validEmailSubject(action);

    return (
        <>
            <span>To Send to</span>
            <div className="automation-selection">
                <FormControl type="text"
                             id="sendEmail_emailAddress"
                             value={action.emailAddress}
                             onChange={(e: any) => setEmailAddress((e as any).target.value)}
                             placeholder="Enter email address"
                             isInvalid={!!action.emailAddress && !isValidEmail}
                             isValid={isValidEmail}/>
            </div>

            <span>With Email subject</span>
            <div className="automation-selection">
                <FormControl type="text"
                             id="sendEmail_subjectTitle"
                             value={action.subjectTitle}
                             onChange={(e: any) => setEmailSubject((e as any).target.value)}
                             placeholder="Enter email subject title"
                             isInvalid={!isValidEmailSubject}
                             isValid={isValidEmailSubject}/>
            </div>
        </>
    )
};