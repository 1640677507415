import React, {useContext, useEffect} from 'react';
import {Button, Col, Container, Row} from 'react-bootstrap';
import FontAwesome from "react-fontawesome";
import FakeButton from "../../component/fakeButton/FakeButton";

import title from '../../assets/img/logo_white.png'
import landingPageImg from '../../assets/img/landing-page-image.png'
import landingImg1 from '../../assets/img/landing-animation-1.gif'
import landingImg2 from '../../assets/img/landing-animation-2.gif'
import landingImg3 from '../../assets/img/landing-animation-3.gif'
import landingImg4 from '../../assets/img/landing-animation-4.gif'

import chromeWebIcon from '../../assets/img/chrome-web-store-icon.png'

import './LandingPage.css';
import {store} from "../../AppState";
import AnalyticsService from "../../service/AnalyticsService";
import {Routes} from "../../util/Properties";
import PixelTracker from "../../component/pixelTracker/PixelTracker";

const LandingPage: React.FC = () => {
    const {state} = useContext(store);
    useEffect(() => {
        new AnalyticsService().logPageView("PLAN_PAGE_VIEW", state.lastSuccessfulLogin);
    }, [state.lastSuccessfulLogin]);

    return (
        <div className="main-background-landing">
            <PixelTracker eventName="LandingPageView"/>
            <Container>
                <Row>
                    <span className="title">
                        <img src={title} alt="horizon logo"/>
                    </span>
                </Row>
                <Row>
                    <span className="show-case medium-and-above-screens">
                        <img src={landingPageImg} alt="horizon on laptop"/>
                    </span>
                </Row>
            </Container>

            <Container className="try-pricing-holder">
                <Row>
                    <Col><TryNow/></Col>
                    <Col><PricingButton/></Col>
                </Row>
            </Container>

            <WelcomeVideo/>
            <VideoTourButton/>
            <ExtensionButton/>
            <ProductHunt/>

            <KeepTrackHint/>
            <SharedBoardHint/>
            <CustomWorkFlowsHints/>
            <CommentsAndRecurringTaskHint/>

            <SecondTryNow/>
        </div>
    )
};

const WelcomeVideo: React.FC = () => {
    return (<div className="landing-page-holder no-bottom-padding">
        <Container>
            <Row>
                <Col sm={true} style={{textAlign: 'center'}}>
                    <div className="iframe-container">
                        <iframe title="horizon" src="https://player.vimeo.com/video/445192406?dnt=1" frameBorder="0"
                                allow="autoplay; fullscreen" allowFullScreen/>
                    </div>
                </Col>
            </Row>
        </Container>
    </div>)
};

const KeepTrackHint: React.FC = () => {
    return (
        <div className="landing-page-holder-alt">
            <Container>
                <Row>
                    <Col sm={true}>
                        <div className="landing-page-section-holder">
                            <FontAwesome size="3x" name="tasks"/><br/>
                            <div className="landing-page-section-title">
                                Plan your tasks and achieve your goals
                            </div>

                            <div className="landing-page-section-text">
                                <b>Horizon</b> is the perfect way to keep track of tasks need to be done.<br/>
                                You can use <b>Horizon</b> to associate your tasks with long and medium term goals.<br/>
                                Log in via your mobile phone, tablet or computer to keep in sync no matter where you are
                                working.
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col sm={true}>
                    <span className="show-case-landing">
                        <img src={landingImg1} alt="Plan goals and tasks"/>
                    </span>
                    </Col>
                </Row>
            </Container>
        </div>
    )
};

const SharedBoardHint: React.FC = () => {
    return (
        <div className="landing-page-holder">
            <Container>
                <Row>
                    <Col sm={true}>
                        <div className="landing-page-section-holder">
                            <FontAwesome size="3x" name="tasks"/><br/>
                            <div className="landing-page-section-title">
                                Share, collaborate and stay in sync
                            </div>

                            <div className="landing-page-section-text">
                                Use <b>Horizon</b> to share common tasks and goals with others, so everybody is aware
                                what needs to be done.<br/>
                                Having a common goal has never been easier.
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col sm={true}>
                    <span className="show-case-landing">
                        <img src={landingImg4} alt="Share and collaborate"/>
                    </span>
                    </Col>
                </Row>
            </Container>
        </div>
    )
};

const CustomWorkFlowsHints: React.FC = () => {
    return (
        <div className="landing-page-holder-alt">
            <Container>
                <Row>
                    <Col sm={true}>
                        <div className="landing-page-section-holder">
                            <FontAwesome size="3x" name="tasks"/><br/>
                            <div className="landing-page-section-title">
                                Alter your workflow to suit for any project
                            </div>

                            <div className="landing-page-section-text">
                                Independently manage your projects and tasks with different boards and customise your
                                work flows to suit each one.<br/>
                                There is no limit to the number of boards you can create.
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col sm={true}>
                        <span className="show-case-landing">
                            <img src={landingImg3} alt="Custom work flows"/>
                        </span>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

const CommentsAndRecurringTaskHint: React.FC = () => {
    return (
        <div className="landing-page-holder">
            <Container>
                <Row>
                    <Col sm={true}>
                        <div className="landing-page-section-holder">
                            <FontAwesome size="3x" name="tasks"/><br/>
                            <div className="landing-page-section-title">
                                Set up recurring tasks and add comments on tasks
                            </div>

                            <div className="landing-page-section-text">
                                Keep track of recurring tasks with <b>Horizon</b>. You can have tasks automatically
                                created every day, week, month or year<br/>
                                Add comments between you, your friends or your team to collaborate on any project.
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col sm={true}>
                        <span className="show-case-landing">
                            <img src={landingImg2} alt="Recurring tasks and comments"/>
                        </span>
                    </Col>
                </Row>
            </Container>
        </div>
    )
};

const TryNow: React.FC = () => {
    return (
        <span className="try-now">
            <FakeButton className="center-button" href={Routes.PLAN_PATH}>
                Try Now
            </FakeButton>
        </span>
    )
};


const VideoTourButton: React.FC = () => {
    return (
        <div className="landing-page-holder try-now">
            <Button className="center-button" variant="dark" target="_blank" style={{
                width: '280px',
                height: '60px',
                padding: '10px',
                paddingTop: '15px',
                borderRadius: '8px',
                fontSize: '1.2rem',
                border: '1px solid white'
            }}
                    href="https://www.youtube.com/watch?v=jGO_rJLKJbM&feature=youtu.be&ab_channel=Horizon">
                <FontAwesome name="video"/><span style={{color: 'white'}}> Horizon Video Tour</span>
            </Button>
        </div>
    )
};

const ExtensionButton: React.FC = () => {
    return (
        <div className="landing-page-holder try-now">
            <Button className="center-button" variant="light"  target="_blank" style={{
                width: '280px',
                height: '60px',
                padding: '10px',
                paddingTop: '15px',
                borderRadius: '8px',
                border: '2px solid #282e34'
            }}
                    href="https://chrome.google.com/webstore/detail/horizon/fgflbhelohpggpnpainfjjoiijdcipom?hl=en&authuser=1">
                <img src={chromeWebIcon} alt="Web Icon" width="30px"/> <span style={{color: '#282e34'}}>Add Horizon Chrome Extension</span>
            </Button>
        </div>
    )
};

const ProductHunt: React.FC = () => {
    return (
        <div className="landing-page-holder try-now">
            <a href="https://www.producthunt.com/posts/horizon-6?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-horizon-6"
               target="_blank" rel="noopener noreferrer" className="center-button">
                <img
                    src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=230520&theme=light"
                    alt="Horizon - A collaborative task and goal planner | Product Hunt"
                    style={{width: '280px', height: '60px'}}
                    width="280" height="60"/>
            </a>
        </div>
    )
};

const PricingButton: React.FC = () => {
    return (
        <div className="pricing-button">
            <FakeButton className="center-button" href={Routes.PRICING_PATH}>
                Features & Pricing
            </FakeButton>
        </div>
    )
};

const SecondTryNow: React.FC = () => {
    return (
        <div className="try-now second-try-now">
            <FakeButton className="center-button" href={Routes.PLAN_PATH}>
                What are you waiting for?
            </FakeButton>
        </div>
    )
};

export default LandingPage;