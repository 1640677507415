import React, {useState} from "react";
import {ValidationUtils} from "../../util/ValidationUtils";
import {Button, Form, FormControl, FormGroup, Modal} from "react-bootstrap";
import BusyFakeButton from "../../component/busyFakeButton/BusyFakeButton";
import {ChangePasswordRequest} from "../../model/Account";

interface ChangePasswordPanelProps {
    emailAddress: string;
    changePassword: (request: ChangePasswordRequest) => Promise<{ loginFailureMessage: string | undefined }>;
    closePanel: () => void;
}

export const ChangePasswordPanel: React.FC<ChangePasswordPanelProps> = ({emailAddress, changePassword, closePanel}) => {
    const [isSettingPassword, setIsSettingPassword] = useState<boolean>(false);
    const [loginFailureMessage, setLoginFailureMessage] = useState<boolean>(false);

    const [oldPassword, setOldPassword] = useState<string>("");
    const [newPassword, setNewPassword] = useState<string>("");
    const [newConfirmPassword, setConfirmNewPassword] = useState<string>("");

    const handleResetPassword = async () => {
        setIsSettingPassword(true);
        const request: ChangePasswordRequest = {
            emailAddress: emailAddress,
            oldPassword: oldPassword,
            newPassword: newPassword
        };
        changePassword(request).then(() => closePanel())
            .catch(({loginFailureMessage}) => setLoginFailureMessage(loginFailureMessage))
            .finally(() => setIsSettingPassword(false));
    };

    const handleSignUpKeyPress = async (e: React.KeyboardEvent<HTMLElement>) => {
        if (e.key === 'Enter' && !e.shiftKey && isValid) {
            e.preventDefault();
            await handleResetPassword();
        }
    };

    const validOldPassword = ValidationUtils.validateLength(oldPassword, 1);
    const validNewPassword = ValidationUtils.validatePassword(newPassword);
    const isMatchingNewPassword = ValidationUtils.matchingPassword(newPassword, newConfirmPassword);
    const isValid = ValidationUtils.combineValidationResults([
        validOldPassword,
        validNewPassword,
        isMatchingNewPassword
    ]);

    return (
        <Modal show={true} animation={false} onHide={closePanel}>
            <Modal.Header>
                <Modal.Title>
                    Set New Password
                </Modal.Title>
                <Button variant="link" onClick={closePanel}>X</Button>
            </Modal.Header>
            <Modal.Body className="sign-up-modal">
                <Form noValidate>
                    <FormGroup id="signUpFormPassword">
                        <FormControl type="password"
                                     id="oldPassword"
                                     value={oldPassword}
                                     onChange={(e: any) => setOldPassword((e as any).target.value)}
                                     placeholder="Enter old password"
                                     onKeyPress={handleSignUpKeyPress}/>
                        <hr/>
                        <FormControl type="password"
                                     id="password"
                                     value={newPassword}
                                     onChange={(e: any) => setNewPassword((e as any).target.value)}
                                     placeholder="Enter new password"
                                     onKeyPress={handleSignUpKeyPress}
                                     required
                                     isInvalid={!!newPassword && !validNewPassword}
                                     isValid={validNewPassword}/>
                        <FormControl type="password"
                                     id="confirmPassword"
                                     value={newConfirmPassword}
                                     onChange={(e: any) => setConfirmNewPassword((e as any).target.value)}
                                     placeholder="Confirm new password"
                                     onKeyPress={handleSignUpKeyPress}
                                     isInvalid={!!newConfirmPassword && !isMatchingNewPassword}
                                     isValid={isMatchingNewPassword}/>

                        <div className="help-block">
                            <p>- Minimum 8 characters.</p>
                            <p>- At least one upper case letter.</p>
                            <p>- At least lower case letter.</p>
                            <p>- At least one number.</p>
                        </div>

                        <hr/>
                        <BusyFakeButton className="button-center" isBusy={isSettingPassword}
                                        size="sm"
                                        onClick={handleResetPassword}
                                        disabled={!isValid}>
                            Change Password
                        </BusyFakeButton>
                    </FormGroup>
                </Form>
                <hr/>
                {loginFailureMessage && <div className="login-failure">{loginFailureMessage}</div>}
            </Modal.Body>
        </Modal>
    )
};