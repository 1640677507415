import jwt from 'jsonwebtoken'
import dayjs from "dayjs";

export const isTokenExpired = (accessToken: string | undefined): boolean => {
    if (!accessToken) {
        return false;
    }

    const parsedToken: any = jwt.decode(accessToken, {complete: true});
    const parsedTokenExpiryEpoch = parsedToken.payload.exp;
    const currentTimeEpoch = dayjs().unix();

    const isTokenEpochValid = dayjs.unix(parsedTokenExpiryEpoch).isValid();

    const isExpired = !isTokenEpochValid || currentTimeEpoch > parsedTokenExpiryEpoch;
    if (isExpired) {
        console.log('Token expired');
    }

    return isExpired;
};

export const getExpirationTime = (accessToken: string | undefined): dayjs.Dayjs | undefined => {
    if (!accessToken) {
        return undefined;
    }

    const parsedToken: any = jwt.decode(accessToken, {complete: true});
    const parsedTokenExpiryEpoch = parsedToken.payload.exp;
    return dayjs.unix(parsedTokenExpiryEpoch);
};