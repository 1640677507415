export interface FileUpload {
    id: string;
    fileName: string;
    createdBy: string,
    dateCreated: string
}

export const toFileUpload = (json: any): FileUpload => {
    const {id, fileName, createdBy, dateCreated} = json;
    return {
        id,
        fileName,
        createdBy,
        dateCreated
    }
};

export interface FileUploadResponse {
    fileUploads: FileUpload[],
    uploadLocations: string[],
    boardByteTotals?: {
        boardId: string,
        byteTotal: number
    }
}

export const toFileUploadResponse = (json: any): FileUploadResponse => {
    const {fileUploads, boardByteTotals, uploadLocations} = json;
    return {
        fileUploads,
        boardByteTotals,
        uploadLocations
    }
};