import React from "react";
import {AssigneeChooser} from "../../planPage/components/assigneeChooser/AssigneeChooser";
import {AssignToUserAction, NotificationAction} from "../../../model/Automation";
import {ValidationUtils} from "../../../util/ValidationUtils";

interface UserSelectionProps {
    action: NotificationAction | AssignToUserAction,
    setAction: (action: NotificationAction | AssignToUserAction) => void
}

export const isValidUserSelection = (action: NotificationAction | AssignToUserAction) =>
    ValidationUtils.validateLength(action.userId || "", 1);

export const UserSelection: React.FC<UserSelectionProps> = ({action, setAction}) => {
    const setAssignee = (userId: string | undefined) => {
        setAction({
            ...action,
            userId: userId
        })
    };

    return (
        <>
            <span>To User</span>
            <div className="automation-selection">
                <AssigneeChooser selectedAssignee={action.userId} setSelectedAssignee={setAssignee}
                                 requireSelection={true} allowOnlyBoardUsers={true}/>
            </div>
        </>
    )

};