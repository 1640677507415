import HttpUtilService from "./HttpUtilService";
import {HttpUrls} from "../util/Properties";
import {
    BillingSessionReturn,
    ChangePasswordRequest,
    Features,
    Login,
    LoginRequest,
    RefreshTokenRequest,
    ResetPasswordRequest,
    SignUpRequest, toUser, User
} from "../model/Account";

export default class UserService {
    private readonly USER_ACCOUNT_SERVICE_URL: string;
    private readonly httpUtilService: HttpUtilService;

    constructor(USER_ACCOUNT_SERVICE_URL: string | undefined = process.env.REACT_APP_USER_ACCOUNT_BACK_END_API,
                httpUtilService: HttpUtilService = new HttpUtilService()) {

        this.USER_ACCOUNT_SERVICE_URL = USER_ACCOUNT_SERVICE_URL || "";
        this.httpUtilService = httpUtilService;
    }

    public getUser(ids: string[]): Promise<User[]> {
        return this.httpUtilService.get(HttpUrls.USER, this.USER_ACCOUNT_SERVICE_URL, {ids: ids.join(',')})
            .then(response => response.data.map((it: any) => toUser(it)));
    }

    public signUp(signUpRequest: SignUpRequest): Promise<Login> {
        return this.httpUtilService.post(HttpUrls.USER_ACCOUNT_SIGN_UP, this.USER_ACCOUNT_SERVICE_URL, signUpRequest)
            .then(response => response.data);
    }

    public login(loginRequest: LoginRequest): Promise<Login> {
        return this.httpUtilService.post(HttpUrls.USER_ACCOUNT_LOGIN, this.USER_ACCOUNT_SERVICE_URL, loginRequest)
            .then(response => response.data);
    }

    public forgotPassword(passwordReset: { emailAddress: string }): Promise<void> {
        return this.httpUtilService.post(HttpUrls.USER_ACCOUNT_PASSWORD_RESET, this.USER_ACCOUNT_SERVICE_URL, passwordReset)
            .then(response => response.data);
    }

    public resetPassword(request: ResetPasswordRequest): Promise<Login> {
        return this.httpUtilService.post(HttpUrls.USER_RESET_NEW_PASSWORD, this.USER_ACCOUNT_SERVICE_URL, request)
            .then(response => response.data);
    }

    public changePassword(request: ChangePasswordRequest): Promise<Login> {
        return this.httpUtilService.post(HttpUrls.USER_CHANGE_PASSWORD, this.USER_ACCOUNT_SERVICE_URL, request)
            .then(response => response.data);
    }

    public refreshToken(refreshTokenRequest: RefreshTokenRequest): Promise<Login> {
        return this.httpUtilService.post(HttpUrls.USER_ACCOUNT_REFRESH_TOKEN, this.USER_ACCOUNT_SERVICE_URL, refreshTokenRequest)
            .then(response => response.data);
    }

    public billingSession(emailAddress: string, pricingPlan: string): Promise<BillingSessionReturn> {
        return this.httpUtilService.post(HttpUrls.PAYMENT_SESSION, this.USER_ACCOUNT_SERVICE_URL, {
            emailAddress: emailAddress,
            pricingPlan: pricingPlan
        }).then(response => response.data);
    }

    public getFeatures(userId?: string): Promise<Features> {
        return this.httpUtilService.get(HttpUrls.USER_FEATURES, this.USER_ACCOUNT_SERVICE_URL, {userId: userId})
            .then(response => response.data);
    }

}