import React, {useCallback, useContext, useEffect} from "react";
import GoalsBoard from "./GoalsBoard";
import {store} from "../../../../AppState";
import TaskService from "../../../../service/TaskService";
import {Goal} from "../../../../model/Goal";
import {Board} from "../../../../model/Board";
import {DispatcherCreator} from "../../../../state/dispatcherCreator";

const GoalsBoardContainer: React.FC = () => {
    const {state, dispatch} = useContext(store);
    const dispatcherCreator = useCallback(() => new DispatcherCreator(dispatch), [dispatch]);

    const friendsWithPendingInvites = state.friendsWithPendingInvites;
    const boardCreatorUserId = state.selectedBoard?.creatorUserId;
    const selectedBoardId = state.selectedBoard?.id;
    const showTasksMatchingGoal = state.taskFilter.showTaskMatchingGoal;
    const boards = state.boards.filter(it => it.id !== selectedBoardId);

    const setGoals = useCallback((goals) => {
        dispatcherCreator().setGoals(goals);
        if (boardCreatorUserId && !!selectedBoardId) {
            new TaskService().saveGoals(boardCreatorUserId, goals, selectedBoardId)
                .then(dispatcherCreator().setGoals)
        }
    }, [dispatcherCreator, boardCreatorUserId, selectedBoardId]);

    const moveGoals = useCallback((goals: Goal[], targetBoard: Board) => {
        dispatcherCreator().setGoals(goals);
        if (boardCreatorUserId && selectedBoardId && targetBoard.id) {
            return new TaskService().moveGoals(boardCreatorUserId, goals, selectedBoardId, targetBoard.id)
                .then(dispatcherCreator().setGoals)
        }
        return Promise.resolve();
    }, [selectedBoardId, dispatcherCreator, boardCreatorUserId]);

    useEffect(() => {
        if (boardCreatorUserId && !!selectedBoardId) {
            new TaskService().getGoals(boardCreatorUserId, selectedBoardId)
                .then(dispatcherCreator().setGoals);
        }
    }, [dispatcherCreator, boardCreatorUserId, selectedBoardId]);


    return (
        <GoalsBoard tasks={state.tasks}
                    goals={state.goals}
                    setGoals={setGoals}
                    friendResponse={friendsWithPendingInvites}
                    showTasksMatchingGoal={showTasksMatchingGoal}
                    setShowTasksMatchingGoal={dispatcherCreator().updateTaskFilterWithGoal}
                    boards={boards}
                    moveGoals={moveGoals}/>
    )
};

export default GoalsBoardContainer;