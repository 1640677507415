import HttpUtilService from "./HttpUtilService";
import {HttpUrls} from "../util/Properties";
import {Task, toTask} from "../model/Task";
import {Goal, toGoal} from "../model/Goal";
import {Board, toUserBoards, UserBoards} from "../model/Board";
import {Automation, toAutomation} from "../model/Automation";

export default class TaskService {
    private readonly USER_ACCOUNT_SERVICE_URL: string;
    private readonly httpUtilService: HttpUtilService;

    constructor(USER_ACCOUNT_SERVICE_URL: string | undefined = process.env.REACT_APP_USER_ACCOUNT_BACK_END_API,
                httpUtilService: HttpUtilService = new HttpUtilService()) {

        this.USER_ACCOUNT_SERVICE_URL = USER_ACCOUNT_SERVICE_URL || "";
        this.httpUtilService = httpUtilService;
    }

    public getTasks(userId: string, selectedBoardId: string): Promise<Task[]> {
        return this.httpUtilService.get(HttpUrls.USER_TASKS, this.USER_ACCOUNT_SERVICE_URL,
            {
                userId: userId,
                selectedBoardId: selectedBoardId
            })
            .then(response => response.data.map((it: any) => toTask(it)));
    }


    public saveTasks(userId: string, tasks: Task[], selectedBoardId: string): Promise<Task[]> {
        return this.httpUtilService.post(HttpUrls.USER_TASKS, this.USER_ACCOUNT_SERVICE_URL, {tasks: tasks},
            {
                userId: userId,
                selectedBoardId: selectedBoardId
            })
            .then(response => response.data.map((it: any) => toTask(it)));
    }

    public moveTasks(userId: string, tasks: Task[], sourceBoardId: string, targetBoardId: string): Promise<Task[]> {
        return this.httpUtilService.post(HttpUrls.MOVE_TASK, this.USER_ACCOUNT_SERVICE_URL, {tasks: tasks},
            {
                userId: userId,
                sourceBoardId: sourceBoardId,
                targetBoardId: targetBoardId
            })
            .then(response => response.data.map((it: any) => toTask(it)));
    }

    public getGoals(userId: string, selectedBoardId: string): Promise<Goal[]> {
        return this.httpUtilService.get(HttpUrls.USER_GOALS, this.USER_ACCOUNT_SERVICE_URL,
            {
                userId: userId,
                selectedBoardId: selectedBoardId
            })
            .then(response => response.data.map((it: any) => toGoal(it)));
    }

    public saveGoals(userId: string, goals: Goal[], selectedBoardId: string): Promise<Goal[]> {
        return this.httpUtilService.post(HttpUrls.USER_GOALS, this.USER_ACCOUNT_SERVICE_URL, {goals: goals},
            {
                userId: userId,
                selectedBoardId: selectedBoardId
            })
            .then(response => response.data.map((it: any) => toGoal(it)));
    }

    public moveGoals(userId: string, goals: Goal[], sourceBoardId: string, targetBoardId: string): Promise<Goal[]> {
        return this.httpUtilService.post(HttpUrls.MOVE_GOALS, this.USER_ACCOUNT_SERVICE_URL, {goals: goals},
            {
                userId: userId,
                sourceBoardId: sourceBoardId,
                targetBoardId: targetBoardId
            })
            .then(response => response.data.map((it: any) => toGoal(it)));
    }

    public getBoards(userId: string, boardId?: string): Promise<UserBoards> {
        return this.httpUtilService.get(HttpUrls.USER_BOARDS, this.USER_ACCOUNT_SERVICE_URL, {
            userId: userId,
            boardId: boardId
        })
            .then(response => toUserBoards(response.data));
    }

    public saveBoards(userId: string, boards: Board[], selectedBoard?: Board): Promise<UserBoards> {
        return this.httpUtilService.post(HttpUrls.USER_BOARDS, this.USER_ACCOUNT_SERVICE_URL, {
            boards: boards,
            selectedBoardId: selectedBoard?.id
        }, {userId: userId}).then(response => toUserBoards(response.data));
    }

    public getAutomations(userId: string, boardId: string): Promise<Automation[]> {
        return this.httpUtilService.get(HttpUrls.USER_AUTOMATION, this.USER_ACCOUNT_SERVICE_URL,
            {
                userId: userId,
                boardId: boardId
            }
        ).then(response => response.data.map((it: any) => toAutomation(it)));
    }

    public saveAutomations(userId: string, boardId: string, automation: Automation[]): Promise<Automation[]> {
        return this.httpUtilService.post(HttpUrls.USER_AUTOMATION, this.USER_ACCOUNT_SERVICE_URL, {automation: automation},
            {
                userId: userId,
                boardId: boardId
            })
            .then(response => response.data.map((it: any) => toAutomation(it)));
    }

}