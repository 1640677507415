import React, {useState} from "react";
import FontAwesome from "react-fontawesome";
import {FriendsWithPendingInvites} from "../../../../model/Friend";
import {Notification} from "../../../../model/Notification";

import './SideBar.css';
import {Board} from "../../../../model/Board";
import {Login} from "../../../../model/Account";
import {SideBarFriendsPanel} from "./panels/SideBarFriendsPanel";
import {BoardsPanel} from "./panels/BoardsPanel";
import {NotificationPanel} from "./panels/NotificationPanel";
import {SettingPanel} from "./panels/SettingPanel";
import {SideBarSelection} from "./sidebarSelection/SideBarSelection";

interface SideBarProps {
    isSignedIn: boolean;
    openSignIn: () => void;
    signOut: () => void;
    successfulLogin: Login | undefined;

    sideBarShown: boolean;
    setSideBarShown: (value: boolean) => void;

    setAutomationPanelShown: (value: boolean) => void;

    organisationPanelShown: boolean,
    setOrganisationPanelShown: (value: boolean) => void,

    friendResponse: FriendsWithPendingInvites | undefined;

    inviteFriend: (emailAddress: string) => Promise<void>;
    deleteFriend: (id: string) => Promise<void>;
    acceptFriendInvite: (id: string) => Promise<void>;
    deleteFriendInvite: (id: string) => Promise<void>;

    notifications: Notification[] | undefined;
    updateNotificationsSeen: (notifications: Notification[]) => Promise<void>;
    deleteNotifications: (notifications: Notification[]) => Promise<void>;

    boards: Board[];
    sharedBoards: Board[];
    selectedBoard?: Board;
    saveBoards: (boardsToSave: Board[], selectedBoard?: Board) => Promise<void>
}

type SelectedSideBar = "FRIENDS" | "NOTIFICATIONS" | "SETTINGS" | "BOARDS" | "ORGANISATIONS" | "NONE"

const SideBar: React.FC<SideBarProps> = ({
                                             isSignedIn, openSignIn, signOut,
                                             successfulLogin, friendResponse, inviteFriend, deleteFriend,
                                             acceptFriendInvite, deleteFriendInvite,
                                             notifications, updateNotificationsSeen, deleteNotifications,
                                             boards, sharedBoards, selectedBoard, saveBoards,
                                             organisationPanelShown, setOrganisationPanelShown,
                                             sideBarShown, setSideBarShown, setAutomationPanelShown
                                         }) => {

    const [selectedSideBar, setSelectedSideBar] = useState<SelectedSideBar>("NONE");
    const showSettingsTab = selectedSideBar === "SETTINGS";
    const showFriendTab = selectedSideBar === "FRIENDS";
    const showBoardsTab = selectedSideBar === "BOARDS";
    const showNotificationsTab = selectedSideBar === "NOTIFICATIONS";

    const showSideBar = () => setSideBarShown(true);

    const hideSideBar = () => {
        setSideBarShown(false);
        setSelectedSideBar("NONE");
    };

    const toggleShowFriendsTab = () => setSelectedSideBar(showFriendTab ? "NONE" : "FRIENDS");
    const toggleSettingTab = () => setSelectedSideBar(showSettingsTab ? "NONE" : "SETTINGS");
    const toggleBoardsTab = () => setSelectedSideBar(showBoardsTab ? "NONE" : "BOARDS");
    const toggleShowNotificationsTab = () => {
        setSelectedSideBar(showNotificationsTab ? "NONE" : "NOTIFICATIONS");
        if (!!notifications && !!showNotificationsTab) {
            updateNotificationsSeen(notifications);
        }
    };
    const showOrganisationPanel = () => {
        setSelectedSideBar("NONE");
        setOrganisationPanelShown(true);
    };

    const receivedFriendInvites = friendResponse?.receivedFriendInvites.length || 0;
    const noOfUnseenNotifications = notifications?.filter(it => !it.seen)?.length || 0;


    return (
        <>
            {!sideBarShown && <div className="side-bar-container-minimised">
                <div className="side-bar-minimised slate-background">
                    <SideBarSelection/>
                    <SideBarSelection/>
                    <SideBarSelection/>
                    <SideBarSelection/>
                    <SideBarSelection/>
                    <SideBarSelection/>
                    <SideBarSelection onClick={showSideBar}>
                        <FontAwesome size="2x" name="chevron-right"/>
                    </SideBarSelection>
                </div>
            </div>}
            {sideBarShown && <div className="side-bar-container">
                <div className="side-bar slate-background">
                    {
                        !isSignedIn && <SideBarSelection onClick={openSignIn}>
                            <FontAwesome size="2x" name="sign-in-alt"/><br/>Sign In
                        </SideBarSelection>
                    }
                    {
                        isSignedIn && <SideBarSelection onClick={signOut}>
                            <FontAwesome size="2x" name="sign-out-alt"/><br/>Sign Out
                        </SideBarSelection>
                    }

                    <SideBarSelection className={showSettingsTab ? 'selected' : ''}
                                      onClick={toggleSettingTab}>
                        <FontAwesome size="2x" name="users-cog"/><br/>User Settings
                    </SideBarSelection>

                    <SideBarSelection className={showNotificationsTab ? 'selected' : ''}
                                      onClick={toggleShowNotificationsTab}
                                      disabled={!isSignedIn}>
                        <FontAwesome size="2x" name="flag"/> <SideBarNumberDisplay
                        count={noOfUnseenNotifications}/><br/>Notifications
                    </SideBarSelection>

                    <SideBarSelection className={showFriendTab ? 'selected' : ''}
                                      onClick={toggleShowFriendsTab}
                                      disabled={!isSignedIn}>
                        <FontAwesome size="2x" name="user-friends"/> <SideBarNumberDisplay
                        count={receivedFriendInvites}/><br/>Friends
                    </SideBarSelection>

                    <SideBarSelection className={showBoardsTab ? 'selected' : ''}
                                      onClick={toggleBoardsTab}
                                      disabled={!isSignedIn}>
                        <FontAwesome size="2x" name="list-alt"/><br/>Boards
                    </SideBarSelection>

                    <SideBarSelection className={organisationPanelShown ? 'selected' : ''}
                                      onClick={showOrganisationPanel}
                                      disabled={!isSignedIn}>
                        <FontAwesome size="2x" name="sitemap"/><br/>Teams
                    </SideBarSelection>

                    <SideBarSelection onClick={hideSideBar}>
                        <FontAwesome size="2x" name="chevron-left"/>
                    </SideBarSelection>

                    {/*DISPLAY SIDE PANELS */}
                    {
                        (isSignedIn && showFriendTab) && <SideBarFriendsPanel successfulLogin={successfulLogin}
                                                                              friendResponse={friendResponse}
                                                                              inviteFriend={inviteFriend}
                                                                              deleteFriend={deleteFriend}
                                                                              acceptFriendInvite={acceptFriendInvite}
                                                                              deleteFriendInvite={deleteFriendInvite}/>
                    }

                    {
                        (isSignedIn && showNotificationsTab) && <NotificationPanel notifications={notifications || []}
                                                                                   deleteNotifications={deleteNotifications}
                                                                                   updateNotificationsSeen={updateNotificationsSeen}/>
                    }
                    {
                        (isSignedIn && showBoardsTab && !!friendResponse) && <BoardsPanel boards={boards}
                                                                                          sharedBoards={sharedBoards}
                                                                                          selectedBoard={selectedBoard}
                                                                                          saveBoard={saveBoards}
                                                                                          friendResponse={friendResponse}
                                                                                          setShowOrganisationPanel={setOrganisationPanelShown}
                                                                                          setAutomationPanelShown={setAutomationPanelShown}
                                                                                          onClose={() => setSelectedSideBar("NONE")}/>
                    }
                    {
                        (showSettingsTab) && <SettingPanel successfulLogin={successfulLogin}
                                                           onClose={() => setSelectedSideBar("NONE")}/>
                    }
                </div>
            </div>}
        </>
    )
};

const SideBarNumberDisplay: React.FC<{ count: number }> = ({count}) => {
    if (count < 1) {
        return <></>;
    }

    return (
        <span className="notification-display-no">{count}</span>
    )
};


export default SideBar;