import {Task, toTask} from "../../../model/Task";
import {Goal, toGoal} from "../../../model/Goal";
import {FriendsWithPendingInvites} from "../../../model/Friend";
import {Notification, toNotification} from "../../../model/Notification";
import {DefaultWebSocket} from "../../../service/WebSocketClientFactory";
import {WebSocketMessage} from "../../../model/WebSocketMessage";
import {NOOP} from "../../../util/Functions";
import {Board, toBoard} from "../../../model/Board";

type TaskHandler = (tasks: Task[]) => void;
type GoalHandler = (goals: Goal[]) => void;
type FriendResponseHandler = (friends: FriendsWithPendingInvites) => void;
type NotificationHandler = (notifications: Notification[]) => void;
type SharedBoardsHandler = (sharedBoards: Board[]) => void;

interface UpdateFunctions {
    updateTasks?: TaskHandler,
    updateGoals?: GoalHandler,
    updateFriends?: FriendResponseHandler,
    updateNotification?: NotificationHandler,
    updateSharedBoards?: SharedBoardsHandler
}

export class PlanPageSocketMessageHandler {

    public async handleFor(userId: string | undefined, boardId: string,
                           {
                               updateTasks,
                               updateGoals,
                               updateFriends,
                               updateNotification,
                               updateSharedBoards
                           }: UpdateFunctions = {}) {

        const updateTasksFunc = updateTasks || NOOP;
        const updateGoalsFunc = updateGoals || NOOP;
        const updateFriendsFunc = updateFriends || NOOP;
        const updateNotificationFunc = updateNotification || NOOP;
        const updateSharedBoardsFunc = updateSharedBoards || NOOP;

        const topics = userId ? [`USER_${userId}`, `BOARD_${boardId}`] : [`BOARD_${boardId}`];
        await DefaultWebSocket.connect(topics).then(() => DefaultWebSocket.onMessageReceived((topic: string, response: WebSocketMessage) => {
            switch (response.type) {
                case `TASKS_UPDATE_${boardId}`:
                    updateTasksFunc(response.message.map((it: any) => toTask(it)));
                    break;
                case `GOALS_UPDATE_${boardId}`:
                    updateGoalsFunc(response.message.map((it: any) => toGoal(it)));
                    break;
                case 'FRIENDS_UPDATE':
                    updateFriendsFunc(response.message);
                    break;
                case 'NOTIFICATIONS_UPDATE':
                    updateNotificationFunc(response.message.map((it: any) => toNotification(it)));
                    break;
                case 'SHARED_BOARDS_UPDATE':
                    updateSharedBoardsFunc(response.message.map((it: any) => toBoard(it)));
                    break;
            }
        }));
        return this;
    }

    public async unsubscribe(boardId: string) {
        await DefaultWebSocket.unSubscribe([`BOARD_${boardId}`]);
    }

}