import HttpUtilService from "./HttpUtilService";
import {HttpUrls} from "../util/Properties";

export class ResourceService {
    
    private readonly USER_RESOURCE_SERVICE_URL: string;
    private readonly httpUtilService: HttpUtilService;

    constructor(USER_RESOURCE_SERVICE_URL: string | undefined = process.env.REACT_APP_USER_ACCOUNT_BACK_END_API,
                httpUtilService: HttpUtilService = new HttpUtilService()) {

        this.USER_RESOURCE_SERVICE_URL = USER_RESOURCE_SERVICE_URL || "";
        this.httpUtilService = httpUtilService;
    }

    public getIotGatewayUrl(): Promise<{url: string}> {
        return this.httpUtilService.get(HttpUrls.RESOURCES_IOT_GATEWAY, this.USER_RESOURCE_SERVICE_URL)
            .then(response => response.data);
    }
    
}