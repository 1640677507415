import mqtt from 'mqtt';
import {IClientOptions, MqttClient} from "mqtt/types/lib/client";
import {ResourceService} from "./ResourceService";

const validateClientConnected = (client: MqttClient | undefined) => {
    if (!client) {
        throw new Error("Client is not connected yet. Call client.connect() first!");
    }
};

type Callback = (...args: any[]) => void;

export interface WebSocketClient {
    connect: (topics?: string[]) => Promise<WebSocketClient>,
    unSubscribe: (topics: string[]) => Promise<void>,
    onConnect: (callback: Callback) => WebSocketClient
    onDisconnect: (callback: Callback) => WebSocketClient,
    onMessageReceived: (callback: Callback) => WebSocketClient,
    disconnect: () => Promise<WebSocketClient>,
    refreshConnection: () => Promise<void>
}


const subscribedTopics: Set<string> = new Set<string>();
let url: string | undefined = undefined;

const WebSocket = (resourceService: ResourceService = new ResourceService()): WebSocketClient => {
    const options: IClientOptions = {
        will: {
            topic: "client-connection",
            payload: "{}",
            qos: 1,
            retain: false
        }
    };

    let client: MqttClient;
    const connect = async (topics: string [] = []) => {
        if (!url) {
            url = (await resourceService.getIotGatewayUrl()).url;
        }


        client = mqtt.connect(url, options);
        await topics.filter(topic => !subscribedTopics.has(topic))
            .map(topic => {
                return new Promise((resolve) => {
                    console.log('connecting ' + topic);
                    client?.subscribe(topic, () => {
                        subscribedTopics.add(topic);
                        resolve(undefined)
                    });
                });
            });

        return clientWrapper;
    };

    const unSubscribe = async (topics: string[]): Promise<void> => {
        const topicsToUnsubscribe = topics.filter(topic => subscribedTopics.has(topic));

        await Promise.all(topicsToUnsubscribe
            .map(topic => {
                return new Promise((resolve) => {
                    console.log('unsubscribing ' + topic);
                    client?.unsubscribe(topic, undefined, () => {
                        subscribedTopics.delete(topic);
                        resolve(undefined);
                    });
                })
            }));
    };

    const onConnect = (callback: Callback) => {
        validateClientConnected(client);
        client?.on('connect', callback);
        return clientWrapper;
    };
    const onDisconnect = (callback: Callback) => {
        console.log('disconnecting');
        validateClientConnected(client);
        client?.on('close', callback);
        return clientWrapper;
    };
    const onMessageReceived = (callback: Callback) => {
        validateClientConnected(client);
        client?.on('message', (topic: string, message: any) => {
            callback(topic, JSON.parse(message.toString()));
        });
        return clientWrapper;
    };

    const disconnect = (): Promise<WebSocketClient> => {
        return new Promise((resolve) => {
            client?.end(true, undefined, () => {
                resolve(clientWrapper);
            });
        });
    };

    const refreshConnection = async (): Promise<void> => {
        url = undefined;
        const topics: string[] = Array.from(subscribedTopics);
        await unSubscribe(topics);
        await connect(topics);
    };

    const clientWrapper = {
        connect: connect,
        unSubscribe: unSubscribe,
        onConnect: onConnect,
        onDisconnect: onDisconnect,
        onMessageReceived: onMessageReceived,
        disconnect: disconnect,
        refreshConnection: refreshConnection
    };
    return clientWrapper;
};

export const DefaultWebSocket = WebSocket();