import React, {useCallback, useContext, useEffect} from "react";
import {store} from "../../AppState";
import {UserPreferences} from "../../model/UserPreferences";
import {UserPreferencesService} from "../../service/UserPreferencesService";
import UserPreferencePanel from "./UserPreferencesPanel";
import {Login} from "../../model/Account";
import {DispatcherCreator} from "../../state/dispatcherCreator";

const UserPreferencePanelContainer: React.FC = () => {
    const {state, dispatch} = useContext(store);
    const dispatcherCreator = useCallback(() => new DispatcherCreator(dispatch), [dispatch]);

    const userPreferences: UserPreferences | undefined = state.userPreferences;
    const successfulLogin: Login | undefined = state.lastSuccessfulLogin;
    const userId = successfulLogin?.userId;

    const setUserPreferences = (userPreferences: UserPreferences) => {
        if (!!userId) {
            return new UserPreferencesService().saveUserPreferences(userId, userPreferences).then(dispatcherCreator().setUserPreferences);
        }
        return Promise.resolve();
    };

    useEffect(() => {
        if (!!userId) {
            new UserPreferencesService().getUserPreferences(userId).then(dispatcherCreator().setUserPreferences);
        }
    }, [userId, dispatcherCreator]);


    if (!userPreferences) {
        return <></>
    }

    return (
        <UserPreferencePanel userPreferences={userPreferences}
                             setUserPreferences={setUserPreferences}/>
    )
};

export default UserPreferencePanelContainer;