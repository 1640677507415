import React, {useCallback, useContext} from "react";
import {store} from "../../AppState";
import {ForgotPasswordPanel} from "./ForgotPasswordPanel";
import UserService from "../../service/UserService";
import {Login, ResetPasswordRequest} from "../../model/Account";
import * as H from "history";
import {useLocation} from "react-router-dom";
import {DispatcherCreator} from "../../state/dispatcherCreator";

const ForgotPasswordPanelContainer: React.FC = () => {
    const {dispatch} = useContext(store);
    const dispatcherCreator = useCallback(() => new DispatcherCreator(dispatch), [dispatch]);

    const handleResetPassword = async (request: ResetPasswordRequest): Promise<{ loginFailureMessage: string | undefined }> => {
        const login: Login = await new UserService().resetPassword(request);
        if (login.loginStatus === "SUCCESS") {
            dispatcherCreator().onSignUp(login);
            return Promise.resolve({loginFailureMessage: undefined})
        } else {
            return Promise.reject({loginFailureMessage: login.loginMessage})
        }
    };

    const location: H.Location = useLocation();
    const resetPasswordToken: string | null = new URLSearchParams(location.search).get("resetPassword");
    const userId: string | null = new URLSearchParams(location.search).get("userId");

    if (!resetPasswordToken || !userId) {
        return null;
    }

    return (<ForgotPasswordPanel userId={userId}
                                 resetPasswordToken={resetPasswordToken}
                                 signOut={dispatcherCreator().signOut}
                                 closeSignInPanel={dispatcherCreator().closeSignInPanel}
                                 resetPassword={handleResetPassword}/>)
};

export default ForgotPasswordPanelContainer