export interface PomodoroSettings {
    workTime: number,
    breakTime: number,
    longerBreakTime: number,
    longerBreakAfterIterations: number,
    completedPomodoros: number,
    playSound: boolean,
    autoStart: boolean,
    usePomodoroMode: boolean
}

export interface UserPreferences {
    userId: string;
    userTimeZoneOffset?: number;
    sendWeeklySummary?: boolean;
    lastViewedMessage?: number;
    pomodoroSettings?: PomodoroSettings
}

export const DEFAULT_POMODORO: PomodoroSettings = {
    workTime: 25,
    breakTime: 5,
    longerBreakTime: 15,
    longerBreakAfterIterations: 4,
    playSound: true,
    completedPomodoros: 0,
    autoStart: true,
    usePomodoroMode: false
};

export const toUserPreferences = (json: any): UserPreferences => {
    const {userId, userTimeZoneOffset, sendWeeklySummary, lastViewedMessage, pomodoroSettings} = json;
    return {
        userId,
        userTimeZoneOffset,
        sendWeeklySummary,
        lastViewedMessage,
        pomodoroSettings
    }
};