import React, {useCallback, useContext} from "react";
import {FeedbackPanel} from "./FeedbackPanel";
import {store} from "../../AppState";
import {DispatcherCreator} from "../../state/dispatcherCreator";

const FeedbackPanelContainer: React.FC = () => {
    const {state, dispatch} = useContext(store);
    const dispatcherCreator = useCallback(() => new DispatcherCreator(dispatch), [dispatch]);

    return <FeedbackPanel showFeedbackPanel={state.showFeedbackPanel}
                          setShowFeedbackPanel={dispatcherCreator().setShowFeedbackPanel}
                          markFeedbackSubmitted={dispatcherCreator().markFeedbackSubmitted}/>
};

export default FeedbackPanelContainer;