import React, {useCallback, useContext, useEffect, useState} from "react";
import {Button, Modal} from "react-bootstrap";
import {store} from "../../AppState";
import {V1} from "./messages/V1";
import {V2} from "./messages/V2";
import {V3} from "./messages/V3";
import {V4} from "./messages/V4";
import {V5} from "./messages/v5";
import './LoginNoticesPanel.css'
import {DispatcherCreator} from "../../state/dispatcherCreator";
import {UserPreferencesService} from "../../service/UserPreferencesService";
import {UserPreferences} from "../../model/UserPreferences";

const LAST_MESSAGE: number = 5;

const LoginNoticePanel: React.FC = () => {
    const {state, dispatch} = useContext(store);
    const dispatcherCreator = useCallback(() => new DispatcherCreator(dispatch), [dispatch]);

    const userPreferences: UserPreferences | undefined = state.userPreferences;
    const userTimeZoneOffset = userPreferences?.userTimeZoneOffset;

    const initialLastViewMessage: number = userPreferences?.lastViewedMessage || 1;

    const [lastViewedMessage, setLastViewMessage] = useState<number>(initialLastViewMessage);
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const isLastMessage = lastViewedMessage >= LAST_MESSAGE;

    const handleNext = () => {
        setLastViewMessage(lastViewedMessage + 1);
    };

    const handleClose = () => {
        setIsOpen(false);
        if (state.lastSuccessfulLogin?.userId && userPreferences) {
            const newUserPreferences = {...userPreferences, lastViewedMessage: lastViewedMessage + 1};
            new UserPreferencesService().saveUserPreferences(state.lastSuccessfulLogin?.userId, newUserPreferences).then(dispatcherCreator().setUserPreferences)
        }
    };

    const onClose = () => setIsOpen(false);

    useEffect(() => {
        setLastViewMessage(initialLastViewMessage);
        if (userTimeZoneOffset !== undefined) {
            setIsOpen(initialLastViewMessage <= LAST_MESSAGE);
        }
    }, [userTimeZoneOffset, initialLastViewMessage]);

    return (
        <Modal className="login-notices" show={isOpen} animation={false} onHide={onClose}>
            <Modal.Header/>
            <Modal.Body className="login-notices-panel">
                <Message viewedMessage={lastViewedMessage} handleClose={handleClose}/>
                <br/>
                {
                    !isLastMessage && <Button variant="secondary" onClick={handleNext}>Next</Button>
                }
                {
                    isLastMessage && <Button variant="dark" onClick={handleClose}>Close</Button>
                }
            </Modal.Body>
        </Modal>
    )
};

const Message: React.FC<{ viewedMessage: number, handleClose: () => void }> = ({viewedMessage, handleClose}) => {
    switch (viewedMessage) {
        case 1:
            return <V1/>;
        case 2:
            return <V2/>;
        case 3:
            return <V3/>;
        case 4:
            return <V4 handleClose={handleClose}/>;
        case 5:
            return <V5/>;
        default:
            return <></>;
    }
};


export default LoginNoticePanel;
