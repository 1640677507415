import React from "react";
import FontAwesome from "react-fontawesome";

export type Size = 'lg' | '2x' | '3x' | '4x' | '5x';

const LoadingComponent: React.FC<{ isBusy: boolean, size?: Size }> = ({isBusy, size}) => {
    return (
        <>{isBusy && <FontAwesome name="sync" size={size} className="loading-component-button"/>}</>
    )
};
export default LoadingComponent;