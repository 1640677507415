import React, {useCallback, useContext} from "react";
import {store} from "../../AppState";
import {SignUpPanel} from "./SignUpPanel";
import {DispatcherCreator} from "../../state/dispatcherCreator";

const SignUpPanelContainer: React.FC = () => {
    const {state, dispatch} = useContext(store);
    const dispatcherCreator = useCallback(() => new DispatcherCreator(dispatch), [dispatch]);

    return (
        <SignUpPanel showSignUpPanel={state.showSignUp}
                     onSignUp={dispatcherCreator().onSignUp}
                     onClose={dispatcherCreator().closeSignInPanel}
                     lastSuccessfulLogin={state.lastSuccessfulLogin}/>
    )
};

export default SignUpPanelContainer;