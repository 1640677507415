import {AppState} from "../AppState";
import {Action} from "./actions";
import {toTask, toTaskFilter} from "../model/Task";
import {toGoal} from "../model/Goal";
import SessionStore from "../util/SessionStore";
import {Board, toBoard} from "../model/Board";
import {toLogin} from "../model/Account";
import HttpUtilService from "../service/HttpUtilService";
import {enableES5} from 'immer';

enableES5();//before any logic

const SESSION_STORE_KEY = "horizon_session_store";

const tasks = SessionStore.getItemOrDefault(SESSION_STORE_KEY, [],
    (sessionState: AppState) => sessionState.tasks.map(it => toTask(it)));

const taskFilterConverter = (sessionStorage: AppState) => sessionStorage.taskFilter ? toTaskFilter(sessionStorage.taskFilter) : undefined;
const taskFilter = SessionStore.getItemOrDefault(SESSION_STORE_KEY, {}, taskFilterConverter);

const goals = SessionStore.getItemOrDefault(SESSION_STORE_KEY, [],
    (sessionState: AppState) => sessionState.goals.map(it => toGoal(it)));
const boards = SessionStore.getItemOrDefault(SESSION_STORE_KEY, [],
    (sessionState: AppState) => sessionState.boards?.map(it => toBoard(it))) || [];
const sharedBoards = SessionStore.getItemOrDefault(SESSION_STORE_KEY, [],
    (sessionState: AppState) => sessionState.sharedBoards?.map(it => toBoard(it))) || [];

const lastSuccessfulLoginConverter = (sessionState: AppState) => sessionState.lastSuccessfulLogin ? toLogin(sessionState.lastSuccessfulLogin) : undefined;
const lastSuccessfulLogin = SessionStore.getItemOrDefault(SESSION_STORE_KEY, undefined, lastSuccessfulLoginConverter);

const showSideBarConverter = (sessionStorage: AppState) => sessionStorage.showSideBar;
const showSideBar = SessionStore.getItemOrDefault(SESSION_STORE_KEY, true, showSideBarConverter);

const showAutomationPanelConverter = (sessionStorage: AppState) => sessionStorage.showAutomationPanel;
const showAutomationPanel = SessionStore.getItemOrDefault(SESSION_STORE_KEY, false, showAutomationPanelConverter);

const showOrganisationPanelConverter = (sessionStorage: AppState) => sessionStorage.showOrganisationPanel;
const showOrganisationPanel = SessionStore.getItemOrDefault(SESSION_STORE_KEY, false, showOrganisationPanelConverter);

const submittedFeedbackConverter = (sessionStorage: AppState) => sessionStorage.submittedFeedback;
const submittedFeedback = SessionStore.getItemOrDefault(SESSION_STORE_KEY, false, submittedFeedbackConverter);

const pomodoroStateConverter = (sessionStorage: AppState) => sessionStorage.pomodoroState;
const pomodoroState = SessionStore.getItemOrDefault(SESSION_STORE_KEY, undefined, pomodoroStateConverter);

export const initialState: AppState = {
    tasks,
    taskFilter,
    goals,
    boards,
    sharedBoards,
    lastSuccessfulLogin,
    showSignUp: !lastSuccessfulLogin,
    showSideBar,
    showFeedbackPanel: false,
    showAutomationPanel,
    showOrganisationPanel,
    submittedFeedback,
    pomodoroState
};

const reducer = (state: AppState, action: Action): AppState => {
    const composeNewState = () => {
        switch (action.type) {
            case 'SET_TASKS':
                return {...state, tasks: action.tasks};
            case "UPDATE_SINGLE_TASK":
                const updatedTasks = state.tasks.map(it => action.update.id === it.id ? ({...it, ...action.update}) : it);
                return {...state, tasks: updatedTasks};
            case 'SET_GOALS':
                return {...state, goals: action.goals};
            case 'SET_BOARDS':
                return {
                    ...state,
                    boards: action.boards,
                    sharedBoards: action.sharedBoards,
                    selectedBoard: action.selectedBoard,
                    selectedBoardOrganisation: state.organisations?.find(it => it.id === action.selectedBoard?.sharedWithTeam)
                };
            case 'SET_SHARED_BOARDS':
                const selectedBoardUpdate = [...state.boards, ...action.sharedBoards].find(it => it.id === state.selectedBoard?.id);
                return {
                    ...state,
                    sharedBoards: action.sharedBoards,
                    selectedBoard: selectedBoardUpdate,
                    selectedBoardOrganisation: state.organisations?.find(it => it.id === selectedBoardUpdate?.sharedWithTeam)
                };
            case "UPDATE_SINGLE_BOARD":
                const newBoards: Board[] = state.boards.map(it => it.id === action.board.id ? ({...it, ...action.board}) : it);
                const newSharedBoards: Board[] = state.sharedBoards.map(it => it.id === action.board.id ? ({...it, ...action.board}) : it);
                const newSelectedBoard: Board | undefined = state.selectedBoard && state.selectedBoard?.id === action.board.id
                    ? ({...state.selectedBoard, ...action.board}) : state.selectedBoard;
                return {
                    ...state,
                    boards: newBoards,
                    sharedBoards: newSharedBoards,
                    selectedBoard: newSelectedBoard
                };
            case 'SELECT_BOARD':
                const selectedBoardUpdates = state.boards.find(it => it.id === action.selectedBoardId);
                return {
                    ...state,
                    selectedBoard: selectedBoardUpdates,
                    selectedBoardOrganisation: state.organisations?.find(it => it.id === selectedBoardUpdates?.sharedWithTeam)
                };
            case "SET_ERROR_BOARD_AUTH_MESSAGE":
                return {
                    ...state,
                    showSignUp: false,
                    selectedBoard: undefined,
                    errorBoardAuth: action.message,
                    tasks: [],
                    goals: []
                };
            case 'LOGIN_SUCCESS':
                HttpUtilService.setTokens(action.loginResponse.accessToken, action.loginResponse.refreshToken);
                return {...state, lastSuccessfulLogin: action.loginResponse};
            case "SET_PAYMENT_SIGNED_UP":
                const login = !!state.lastSuccessfulLogin
                    ? {...state.lastSuccessfulLogin, paymentSignedUp: action.value} : state.lastSuccessfulLogin;
                return {
                    ...state,
                    lastSuccessfulLogin: login
                };
            case 'OPEN_SIGN_IN':
                return {...state, showSignUp: true};
            case 'CLOSE_SIGN_IN':
                return {...state, showSignUp: false};
            case 'SIGNOUT':
                HttpUtilService.setTokens(undefined, undefined);
                return {
                    ...state,
                    tasks: [],
                    goals: [],
                    boards: [],
                    sharedBoards: [],
                    users: undefined,
                    selectedBoard: undefined,
                    lastSuccessfulLogin: undefined,
                    friendsWithPendingInvites: undefined
                };
            case "SET_SIDE_BAR_SHOWN":
                return {...state, showSideBar: action.value};
            case "SET_AUTOMATION_PANEL":
                return {...state, showAutomationPanel: action.value};
            case 'SET_FRIENDS_PENDING_INVITES':
                return {...state, friendsWithPendingInvites: action.friendsWithPendingInvites};
            case 'SET_NOTIFICATIONS':
                return {...state, notifications: action.notifications};
            case "SET_USER_PREFERENCES":
                return {...state, userPreferences: action.userPreferences};
            case 'UPDATE_TASK_FILTER':
                return {...state, taskFilter: {...state.taskFilter, ...action.taskFilter}};
            case 'RESET_TASK_FILTER':
                return {...state, taskFilter: {}};
            case "SET_SHOW_FEEDBACK_PANEL":
                return {...state, showFeedbackPanel: action.value};
            case "SET_ORGANISATION_PANEL":
                return {...state, showOrganisationPanel: action.value};
            case "FINISHED_SUBMITTED_FEEDBACK":
                return {...state, submittedFeedback: true};
            case "SET_FEATURES":
                return {...state, features: action.features};
            case "SET_ORGANISATIONS":
                const selectedOrganisationUpdate = action.organisations?.find(it => it.id === state.selectedBoard?.sharedWithTeam);
                return {
                    ...state,
                    organisations: action.organisations,
                    selectedBoardOrganisation: selectedOrganisationUpdate
                };
            case "SET_USERS":
                return {...state, users: action.users};
            case "SET_POMODORO_STATE":
                return {...state, pomodoroState: action.state};
            case "RESET_POMODORO_STATE":
                return {...state, pomodoroState: undefined};
            default:
                return state;
        }
    };

    const newState = composeNewState();
    SessionStore.setItem(SESSION_STORE_KEY, newState);
    return newState;
};
export default reducer;