import React from "react";

import teams from '../../../assets/img/v3-teams.gif'

export const V3: React.FC = () => {
    return <>
        <h5>Teams</h5>
        <span className="new">NEW!</span> <span>Workforce Feature</span>
        <div className="login-text-holder">
            <span>Organise your friends or fellow members into Teams.</span>
        </div>
        <div className="images-holder">
            <img src={teams} alt="Teams"/>
        </div>
    </>
};