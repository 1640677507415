import dayjs from "dayjs";

export interface Comment {
    id?: string;
    commentText: string;
    createdBy: string;
    dateCreated?: dayjs.Dayjs,
}


export const toComment = (json: any): Comment => {
    const {id, commentText, createdBy, dateCreated} = json;
    return {
        id,
        commentText,
        createdBy,
        dateCreated: !!dateCreated ? dayjs(dateCreated) : undefined,
    }
};

export type ItemType = "TASK" |"GOAL"