import HttpUtilService, {ProgressCallback} from "./HttpUtilService";
import {HttpUrls} from "../util/Properties";
import {ItemType} from "../model/Comment";
import {FileUpload, FileUploadResponse, toFileUpload, toFileUploadResponse} from "../model/FileUpload";
import {Features} from "../model/Account";

export class FileUploadService {

    private readonly FILE_UPLOAD_SERVICE_URL: string;
    private readonly httpUtilService: HttpUtilService;

    constructor(FILE_UPLOAD_SERVICE_URL: string | undefined = process.env.REACT_APP_USER_ACCOUNT_BACK_END_API,
                httpUtilService: HttpUtilService = new HttpUtilService()) {

        this.FILE_UPLOAD_SERVICE_URL = FILE_UPLOAD_SERVICE_URL || "";
        this.httpUtilService = httpUtilService;
    }

    public async prepareUploadFile(itemType: ItemType, itemId: string, files: File[],
                                   features: Features, selectedBoardId?: string): Promise<FileUploadResponse> {

        FileUploadService.checkForErrors(files, features);
        const params = {selectedBoardId: selectedBoardId};

        const requestBody = files.map(it => ({
            fileName: it.name,
            bytesLength: it.size,
            contentType: it.type
        }));

        return this.httpUtilService.post(HttpUrls.FILE_UPLOAD_FOR_ITEM(itemType, itemId), this.FILE_UPLOAD_SERVICE_URL, requestBody, params)
            .then(response => toFileUploadResponse(response.data));
    }

    public async uploadFiles(files: File[], uploadResponse: FileUploadResponse, uploadProgressCallback?: ProgressCallback): Promise<void> {
        let updateCompletedBytesSoFar: number = 0;
        const totalFileBytes = files.reduce((number, it) => number + it.size, 0);

        for (let i = 0; i < files.length; i++) {
            const key = uploadResponse.fileUploads[i].id;
            const file = files[i];
            const uploadLocation = uploadResponse.uploadLocations[i];

            const totalUploadProgress: ProgressCallback | undefined = this.totalUploadProgress(updateCompletedBytesSoFar, totalFileBytes, uploadProgressCallback);

            await this.httpUtilService.postS3(uploadLocation, key, file, totalUploadProgress);
            updateCompletedBytesSoFar += file.size;
        }
    }

    public async getUploadFiles(itemType: ItemType, itemId: string): Promise<FileUpload[]> {
        return this.httpUtilService.get(HttpUrls.FILE_UPLOAD_FOR_ITEM(itemType, itemId), this.FILE_UPLOAD_SERVICE_URL)
            .then(response => response.data.map((it: any) => toFileUpload(it)));
    }

    public async deleteFiles(fileUploads: FileUpload[]): Promise<FileUploadResponse> {
        return this.httpUtilService.delete(HttpUrls.FILE_UPLOAD, this.FILE_UPLOAD_SERVICE_URL, fileUploads)
            .then(response => toFileUploadResponse(response.data));
    }

    private static checkForErrors(files: File[], features: Features) {
        const applicationFiles = files.filter(it => it.type === "application/x-msdownload");
        if (applicationFiles.length > 0) {
            throw Error(`This file type is not supported \n ${applicationFiles.map(it => `${it.name}`).join(', ')}`);
        }

        const fileUploadLimit = features.features.fileUploadLimit;
        const filesOverLimit = files.filter(it => it.size > features.features.fileUploadLimit);
        if (filesOverLimit.length > 0) {
            throw Error(`Cannot upload files over ${fileUploadLimit / 1024 / 1024}MB \n ${filesOverLimit.map(it => `${it.name}`).join(', ')}`);
        }
    }

    private totalUploadProgress(updateCompletedBytesSoFar: number, totalFileBytes: number, uploadProgressCallback?: ProgressCallback) {
        return (progressEvent: ProgressEvent) => {
            uploadProgressCallback && uploadProgressCallback({
                ...progressEvent,
                loaded: updateCompletedBytesSoFar + progressEvent.loaded,
                total: totalFileBytes
            });
        }
    }

}