import React, {useEffect, useState} from "react";
import {Button, Form, FormControl, FormGroup, Modal} from "react-bootstrap";
import * as H from "history";
import {useHistory} from "react-router-dom";
import {Routes} from "../../util/Properties";
import BusyFakeButton from "../../component/busyFakeButton/BusyFakeButton";
import {ValidationUtils} from "../../util/ValidationUtils";
import {ResetPasswordRequest} from "../../model/Account";

interface PanelProps {
    signOut: () => void;
    closeSignInPanel: () => void;
    userId: string;
    resetPasswordToken: string;
    resetPassword: (request: ResetPasswordRequest) => Promise<{ loginFailureMessage: string | undefined }>;
}

export const ForgotPasswordPanel: React.FC<PanelProps> = ({signOut, closeSignInPanel, userId, resetPasswordToken, resetPassword}) => {
    const history: H.History = useHistory();

    const [showPanel, setShowPanel] = useState<boolean>(true);
    const [isSettingPassword, setIsSettingPassword] = useState<boolean>(false);
    const [password, setPassword] = useState<string>("");
    const [confirmPassword, setConfirmPassword] = useState<string>("");

    const [loginFailureMessage, setLoginFailureMessage] = useState<string | undefined>(undefined);

    useEffect(() => {
        signOut();
        closeSignInPanel();
        history.push(Routes.PLAN_PATH);
    }, [history, userId, closeSignInPanel, resetPasswordToken, signOut]);

    const closePanel = () => {
        history.push(Routes.PLAN_PATH);
        setShowPanel(false);
    };

    const handleResetPassword = async () => {
        setIsSettingPassword(true);
        const request: ResetPasswordRequest = {
            userId: userId,
            resetPasswordToken: resetPasswordToken,
            newPassword: password
        };
        resetPassword(request).then(() => closePanel())
            .catch(({loginFailureMessage}) => setLoginFailureMessage(loginFailureMessage))
            .finally(() => setIsSettingPassword(false));
    };

    const handleSignUpKeyPress = async (e: React.KeyboardEvent<HTMLElement>) => {
        if (e.key === 'Enter' && !e.shiftKey && validPassword && isMatchingPassword) {
            e.preventDefault();
            await handleResetPassword();
        }
    };

    const validPassword = ValidationUtils.validatePassword(password);
    const isMatchingPassword = ValidationUtils.matchingPassword(password, confirmPassword);

    return (
        <Modal show={showPanel} animation={false} onHide={closePanel}>
            <Modal.Header>
                <Modal.Title>
                    Set New Password
                </Modal.Title>
                <Button variant="link" onClick={closePanel}>X</Button>
            </Modal.Header>
            <Modal.Body className="sign-up-modal">
                <Form noValidate>
                    <FormGroup id="signUpFormPassword">
                        <FormControl type="password"
                                     id="password"
                                     value={password}
                                     onChange={(e: any) => setPassword((e as any).target.value)}
                                     placeholder="Enter password"
                                     onKeyPress={handleSignUpKeyPress}
                                     required
                                     isInvalid={!!password && !validPassword}
                                     isValid={validPassword}/>
                        <FormControl type="password"
                                     id="confirmPassword"
                                     value={confirmPassword}
                                     onChange={(e: any) => setConfirmPassword((e as any).target.value)}
                                     placeholder="Confirm password"
                                     onKeyPress={handleSignUpKeyPress}
                                     isInvalid={!!confirmPassword && !isMatchingPassword}
                                     isValid={isMatchingPassword}/>

                        <div className="help-block">
                            <p>- Minimum 8 characters.</p>
                            <p>- At least one upper case letter.</p>
                            <p>- At least lower case letter.</p>
                            <p>- At least one number.</p>
                        </div>

                        <hr/>
                        <BusyFakeButton className="button-center" isBusy={isSettingPassword}
                                        size="sm"
                                        onClick={handleResetPassword}
                                        disabled={!validPassword || !isMatchingPassword}>
                            Reset Password
                        </BusyFakeButton>
                    </FormGroup>
                </Form>
                <hr/>
                {!!loginFailureMessage && <div className="login-failure">{loginFailureMessage}</div>}
            </Modal.Body>
        </Modal>
    )
};