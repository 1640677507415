export interface Organisation {
    id?: string;
    name?: string;
    index: number;
    userIds: string[];
    adminUserIds: string[];
    canEdit: boolean;
}

export const toOrganisation = (json: any, indexNo?: number): Organisation => {
    const {id, name, index, userIds, adminUserIds, canEdit} = json;
    return {
        id,
        name,
        index: indexNo ? indexNo : index,
        userIds,
        adminUserIds,
        canEdit
    }
};
