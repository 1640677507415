import React, {useCallback, useContext, useEffect, useState} from "react";
import TaskComments from "./TaskComments";
import {Task} from "../../../../../model/Task";
import {store} from "../../../../../AppState";
import {FriendsWithPendingInvites} from "../../../../../model/Friend";
import {Comment, toComment} from "../../../../../model/Comment";
import {Goal} from "../../../../../model/Goal";
import {CommentService} from "../../../../../service/CommentService";
import {DefaultWebSocket} from "../../../../../service/WebSocketClientFactory";
import {WebSocketMessage} from "../../../../../model/WebSocketMessage";
import {Login} from "../../../../../model/Account";
import {DispatcherCreator} from "../../../../../state/dispatcherCreator";

interface TaskCommentsContainerProps {
    task: Task;
    assignedGoal: Goal | undefined;
    onClose: () => void;
}

const TaskCommentContainer: React.FC<TaskCommentsContainerProps> = ({task, assignedGoal, onClose}) => {
    const {state, dispatch} = useContext(store);
    const dispatcherCreator = useCallback(() => new DispatcherCreator(dispatch), [dispatch]);

    const successfulLogin: Login | undefined = state?.lastSuccessfulLogin;
    const friendsWithPendingInvites: FriendsWithPendingInvites | undefined = state?.friendsWithPendingInvites;

    const [comments, setComments] = useState<Comment[] | undefined>(undefined);
    const [taskId] = useState<string>(task.id);

    const updateComments = useCallback((comments: Comment[]) => {
        setComments(comments);
        dispatcherCreator().updateSingleTask({id: taskId, noOfComments: comments.length});
    }, [taskId, setComments, dispatcherCreator]);

    const addComment = (comment: Comment) => {
        if (successfulLogin?.userId) {
            return new CommentService().addComment("TASK", task.id, comment).then(updateComments);
        }
        return Promise.resolve();
    };

    const deleteComment = (comment: Comment) => {
        if (successfulLogin?.userId && !!comment.id) {
            return new CommentService().deleteComment(comment.id).then(updateComments);
        }
        return Promise.resolve();
    };

    const listenToWebSockUpdates = useCallback((taskId: string) => {
        DefaultWebSocket.connect([`TASK_${taskId}`])
            .then(() => DefaultWebSocket.onMessageReceived((topic: string, response: WebSocketMessage) => {
                if (response.type === 'COMMENTS') {
                    updateComments(response.message.map((it: any) => toComment(it)));
                }
            }));
    }, [updateComments]);

    useEffect(() => {
        if (successfulLogin?.userId) {
            new CommentService().getComments("TASK", taskId).then(updateComments);
            listenToWebSockUpdates(taskId);
        }
    }, [taskId, successfulLogin, listenToWebSockUpdates, updateComments]);

    return (
        <TaskComments task={task}
                      assignedGoal={assignedGoal}
                      successfulLogin={successfulLogin}
                      friendsWithPendingInvites={friendsWithPendingInvites}
                      comments={comments}
                      addComment={addComment}
                      deleteComment={deleteComment}
                      onClose={onClose}/>
    )
};

export default TaskCommentContainer;