import React, {useContext} from "react";
import {Task} from "../../../../../model/Task";
import {ProgressBar} from "react-bootstrap";

import dayjs from "dayjs";
import duration from 'dayjs/plugin/duration'
import relativeTime from 'dayjs/plugin/relativeTime'
import {store} from "../../../../../AppState";
import {DEFAULT_COLUMNS} from "../../../../../model/Board";

dayjs.extend(duration);
dayjs.extend(relativeTime);

const GoalProgressBar: React.FC<{ tasks: Task[] }> = ({tasks}) => {
    const {state} = useContext(store);
    const columns = state.selectedBoard?.columns || DEFAULT_COLUMNS;
    const lastState = columns[columns.length - 1];

    const noOfDoneTasks = tasks.filter(it => it.status === lastState).length;

    const now = (noOfDoneTasks / tasks.length) * 100;
    const progressBarLabel = <>{noOfDoneTasks}/{tasks.length} Tasks completed<TimeSpentOfGoal tasks={tasks} lastState={lastState}/></>;

    return (
        <div className="progress-bar-holder drag">
            <span className="progress-bar-label">{progressBarLabel}</span>
            <ProgressBar now={now}/>
        </div>
    )
};

const TimeSpentOfGoal: React.FC<{ tasks: Task[], lastState: string }> = ({tasks, lastState}) => {
    const totalTimeSpentMillis: number = tasks
        .filter(it => it.status === lastState)
        .map(it => it.timeSpent)
        .reduce((a: number, b: number | undefined) => {
            if (!!b) {
                return a + b;
            }
            return a;
        }, 0);

    const years: number = dayjs.duration(totalTimeSpentMillis).years();
    const months: number = dayjs.duration(totalTimeSpentMillis).months();
    const weeks: number = dayjs.duration(totalTimeSpentMillis).weeks();
    const days: number = dayjs.duration(totalTimeSpentMillis).days();
    const hours: number = dayjs.duration(totalTimeSpentMillis).hours();
    const minutes: number = dayjs.duration(totalTimeSpentMillis).minutes();

    const yearsDisplay = years > 0 ? `${years} years` : undefined;
    const monthsDisplay = months > 0 ? `${months} months` : undefined;
    const weeksDisplay = weeks > 0 ? `${weeks} weeks` : undefined;
    const daysDisplay = days > 0 ? `${days} days` : undefined;
    const hoursDisplay = hours > 0 ? `${hours} hours` : undefined;
    const minutesDisplay = minutes > 0 ? `${minutes} minutes` : undefined;

    const display = [yearsDisplay, monthsDisplay, weeksDisplay, daysDisplay, hoursDisplay, minutesDisplay].filter(it => !!it).join(', ');
    if (display.length === 0) {
        return <></>
    }

    return (
        <span className="medium-and-above-screens"> ({`${display}`})</span>
    )
};

export default GoalProgressBar;