import {Button, OverlayTrigger, Popover} from "react-bootstrap";
import LoadingComponent from "../../../../../component/loadingComponent/LoadingComponent";
import FontAwesome from "react-fontawesome";
import React, {useState} from "react";
import {Goal, GoalStatus} from "../../../../../model/Goal";
import {Board} from "../../../../../model/Board";
import {getColumnHeaderName} from "../../tasksboard/TasksBoard";

type MoveGoalHandler = (goals: Goal[], targetBoard: Board) => Promise<void>;
type ChangeGoalStatusHandler = (goalId: string, goalStatus: GoalStatus) => void;

interface GoalActionsProps {
    goal: Goal,
    moveToTop?: (goalId: string) => void,
    moveToBottom?: (goalId: string) => void,
    boards: Board[],
    moveGoals: MoveGoalHandler;
    changeGoalStatus: ChangeGoalStatusHandler;
    canWrite: boolean
}

export const GoalActions: React.FC<GoalActionsProps> = ({goal, moveToTop, moveToBottom, boards, moveGoals, changeGoalStatus, canWrite}) => {
    const [show, setShow] = useState<boolean>(true);
    const [isMovingTask, setIsMovingTask] = useState<boolean>(false);

    const handleMoveToTop = () => {
        setShow(false);
        !!moveToTop && moveToTop(goal.id);
    };

    const handleMoveToBottom = () => {
        setShow(false);
        !!moveToBottom && moveToBottom(goal.id);
    };

    const handleChangeStatus = (status: GoalStatus) => {
        setShow(false);
        changeGoalStatus(goal.id, status);
    };

    const handleMoveTask = (goals: Goal[], board: Board) => {
        setIsMovingTask(true);
        moveGoals(goals, board).finally(() => {
            setIsMovingTask(false);
            setShow(false);
        });
    };

    const popover = <Popover id="popover-basic">
        <Popover.Content>
            <Button variant="link" onClick={handleMoveToTop} disabled={!moveToTop}>
                Move to Top
            </Button>
            <Button variant="link" onClick={handleMoveToBottom} disabled={!moveToBottom}>
                Move to Bottom
            </Button>
            {boards.length && <hr/>}
            {boards.map((it, index) => <Button key={index} variant="link" onClick={() => handleMoveTask([goal], it)}>Move
                to <b>{it.boardName}</b> <LoadingComponent isBusy={isMovingTask}/></Button>)}
            <hr/>
            {
                ["IN_PROGRESS", "DONE"].map((column, index) => <Button variant="link"
                                                                       key={index}
                                                                       onClick={() => handleChangeStatus(column as GoalStatus)}
                                                                       disabled={goal.status === column}>
                    Move to <b>{getColumnHeaderName(column)}</b>
                </Button>)
            }
        </Popover.Content>
    </Popover>;

    return (
        <OverlayTrigger trigger="click" placement="bottom-start" rootClose={true} overlay={show ? popover : <div/>}>
            <Button variant="link" disabled={!canWrite} onClick={() => setShow(true)}><FontAwesome name="caret-square-down"/></Button>
        </OverlayTrigger>
    )
};