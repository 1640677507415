import React from "react";
import {FormControl} from "react-bootstrap";
import {SendEmailToUserAction} from "../../../model/Automation";
import {AssigneeChooser} from "../../planPage/components/assigneeChooser/AssigneeChooser";
import {ValidationUtils} from "../../../util/ValidationUtils";

interface SendEmailToUserOptionProps {
    action: SendEmailToUserAction;
    setAction: (action: SendEmailToUserAction) => void;
}

const validUserId = (action: SendEmailToUserAction) =>
    ValidationUtils.validateLength(action.userId || "", 1);

const validEmailSubject = (action: SendEmailToUserAction) =>
    ValidationUtils.validateLength(action.subjectTitle || "", 1);


export const isValidSendEmailToUser = (action: SendEmailToUserAction) => {
    return ValidationUtils.combineValidationResults([
        validUserId(action),
        validEmailSubject(action)
    ]);
};

export const SendEmailToUserOption: React.FC<SendEmailToUserOptionProps> = ({action, setAction}) => {
    const setUserId = (userId: string | undefined) => setAction({...action, userId: userId});
    const setEmailSubject = (subjectTitle: string) => setAction({...action, subjectTitle: subjectTitle});
    const isValidEmailSubject = validEmailSubject(action);

    return (
        <>
            <span>To Send to</span>
            <div className="automation-selection">
                <AssigneeChooser selectedAssignee={action.userId} setSelectedAssignee={setUserId} allowOnlyBoardUsers={true}/>
            </div>

            <span>With Email subject</span>
            <div className="automation-selection">
                <FormControl type="text"
                             id="sendEmail_subjectTitle"
                             value={action.subjectTitle}
                             onChange={(e: any) => setEmailSubject((e as any).target.value)}
                             placeholder="Enter email subject title"
                             isInvalid={!isValidEmailSubject}
                             isValid={isValidEmailSubject}/>
            </div>
        </>
    )
};