import React, {useCallback, useContext, useEffect} from "react";
import SideBar from "./SideBar";
import {store} from "../../../../AppState";
import {SocialService} from "../../../../service/SocialService";
import {Notification} from "../../../../model/Notification";
import TaskService from "../../../../service/TaskService";
import {Board, UserBoards} from "../../../../model/Board";
import {DispatcherCreator} from "../../../../state/dispatcherCreator";
import {Organisation} from "../../../../model/Organisation";
import {UserCacheService} from "../../../../service/cache/UserCacheService";
import {useHistory, useLocation} from "react-router-dom";
import {Routes} from "../../../../util/Properties";
import * as H from "history";

const SideBarContainer: React.FC = () => {
    const {state, dispatch} = useContext(store);
    const history = useHistory();
    const dispatcherCreator = useCallback(() => new DispatcherCreator(dispatch), [dispatch]);

    const successfulLogin = state.lastSuccessfulLogin;
    const friendsWithPendingInvites = state.friendsWithPendingInvites;
    const notifications = state.notifications;
    const selectedBoard = state.selectedBoard;
    const organisations: Organisation[] | undefined = state.organisations;

    const setUserBoards = useCallback((resp: UserBoards) => {
        dispatcherCreator().setUserBoards(resp);
        const newSelectedBoard = resp.selectedBoard;
        if (newSelectedBoard?.creatorUserId && newSelectedBoard.id) {
            history.push(Routes.PLAN_PATH_FOR_PATH(newSelectedBoard?.creatorUserId, newSelectedBoard?.id));
        }
    }, [dispatcherCreator, history]);

    const onSignOff = useCallback(() => {
        dispatcherCreator().signOut();
        history.push(Routes.PLAN_PATH);
    }, [dispatcherCreator, history]);

    const setBoardAuthError = useCallback((error: Error) => {
        if (error.message.includes('Invalid Access')) {
            dispatcherCreator().setErrorBoardAuthMessage(error.message);
        }
    }, [dispatcherCreator]);

    const saveBoards = useCallback(async (boardsToSave: Board[], selectedBoard?: Board) => {
        if (!!successfulLogin?.userId) {
            await new TaskService().saveBoards(successfulLogin.userId, boardsToSave, selectedBoard).then(setUserBoards);
        }
    }, [setUserBoards, successfulLogin]);

    const inviteFriend = (emailAddress: string) => !!successfulLogin?.userId
        ? new SocialService().addInvites(successfulLogin.userId, [emailAddress]).then(dispatcherCreator().setFriendsPendingInvites)
        : Promise.resolve();
    const deleteFriend = (id: string) => new SocialService().deleteFriend(id).then(dispatcherCreator().setFriendsPendingInvites);
    const acceptFriendInvite = (id: string) => new SocialService().acceptFriendRequest(id).then(dispatcherCreator().setFriendsPendingInvites);
    const deleteFriendInvite = (id: string) => new SocialService().deleteFriendRequest(id).then(dispatcherCreator().setFriendsPendingInvites);
    const updateNotificationSeen = (notifications: Notification[]) =>
        new SocialService().updateNotificationSeen(notifications).then(dispatcherCreator().setNotifications);
    const deleteNotifications = (notifications: Notification[]) =>
        new SocialService().deleteNotifications(notifications).then(dispatcherCreator().setNotifications);

    const location: H.Location = useLocation();

    useEffect(() => {
        const boardKey: string | null = new URLSearchParams(location.search).get("k");
        const boardId: string | null = new URLSearchParams(location.search).get("b");

        if (!!successfulLogin?.userId) {
            new SocialService().getFriends(successfulLogin.userId).then(dispatcherCreator().setFriendsPendingInvites);
            new SocialService().getNotifications(successfulLogin.userId).then(dispatcherCreator().setNotifications);
        }

        if (boardKey && boardId) {
            new TaskService().getBoards(boardKey, boardId).then(setUserBoards).catch(setBoardAuthError);
        } else if (!!successfulLogin?.userId) {
            new TaskService().getBoards(successfulLogin.userId, boardId || undefined).then(setUserBoards).catch(setBoardAuthError);
        }
    }, [setUserBoards, setBoardAuthError, successfulLogin, dispatcherCreator]);

    useEffect(() => {
        if (!!successfulLogin?.userId) {
            dispatcherCreator().setOrganisations(undefined);
            new SocialService().getOrganisations(successfulLogin.userId).then(dispatcherCreator().setOrganisations);
        }
    }, [dispatcherCreator, successfulLogin]);

    const organisation: Organisation | undefined = organisations?.find(it => it.id === selectedBoard?.sharedWithTeam);
    useEffect(() => {
        if (!selectedBoard) {
            return;
        }

        const allUsers = [
            ...(!!selectedBoard?.creatorUserId ? [selectedBoard?.creatorUserId] : []),
            ...(selectedBoard?.sharedWithUsers || []),
            ...(organisation?.adminUserIds || []),
            ...(organisation?.userIds || []),
            ...(friendsWithPendingInvites?.friends.map(it => it.friendUserId) || []),
            ...(!!successfulLogin?.userId ? [successfulLogin.userId] : [])
        ];
        new UserCacheService().getUsers(allUsers).then(dispatcherCreator().setUsers)
    }, [successfulLogin, friendsWithPendingInvites, dispatcherCreator, organisation, selectedBoard]);

    return (
        <SideBar isSignedIn={!!successfulLogin}
                 successfulLogin={successfulLogin}
                 openSignIn={dispatcherCreator().openSignIn}
                 signOut={onSignOff}

                 sideBarShown={state.showSideBar}
                 setSideBarShown={dispatcherCreator().setSideBarShown}
                 setAutomationPanelShown={dispatcherCreator().setAutomationPanelShown}
                 organisationPanelShown={state.showOrganisationPanel}
                 setOrganisationPanelShown={dispatcherCreator().setShowOrganisationPanelShown}

                 friendResponse={friendsWithPendingInvites}
                 inviteFriend={inviteFriend}
                 deleteFriend={deleteFriend}
                 acceptFriendInvite={acceptFriendInvite}
                 deleteFriendInvite={deleteFriendInvite}
                 notifications={notifications}
                 updateNotificationsSeen={updateNotificationSeen}
                 deleteNotifications={deleteNotifications}
                 boards={state.boards}
                 sharedBoards={state.sharedBoards}
                 selectedBoard={selectedBoard}
                 saveBoards={saveBoards}/>
    )
};

export default SideBarContainer;