import React, {useEffect, useState} from "react";
import {UserPreferences} from "../../model/UserPreferences";
import {Modal} from "react-bootstrap";

import './UserPreferencesPanel.css'
import BusyFakeButton from "../../component/busyFakeButton/BusyFakeButton";

interface UserPreferencePanelProps {
    userPreferences: UserPreferences;
    setUserPreferences: (userPreferences: UserPreferences) => Promise<void>;
}

interface TimeZone {
    display: string;
    value: number;
}

const UserPreferencePanel: React.FC<UserPreferencePanelProps> = ({userPreferences, setUserPreferences}) => {
    const [preferences, setPreferences] = useState<UserPreferences>(userPreferences);
    const [showModal, setShowModal] = useState<boolean>(userPreferences.userTimeZoneOffset === undefined);
    const [isSaving, setIsSaving] = useState<boolean>(false);

    const setTimeZone = (timezone: number) => {
        setPreferences({
            ...preferences,
            userTimeZoneOffset: timezone
        })
    };

    const onOk = async () => {
        setIsSaving(true);
        await setUserPreferences(preferences);
        setIsSaving(false);
        setShowModal(false);
    };

    return (
        <Modal show={showModal} animation={false} onHide={() => setShowModal(false)}>
            <Modal.Header>
                <Modal.Title>Set Time Zone</Modal.Title>
            </Modal.Header>
            <Modal.Body className="time-zone-dialog">
                <TimeZoneSelector selectedTimeZone={preferences.userTimeZoneOffset} setTimeZone={setTimeZone}/>
                <BusyFakeButton isBusy={isSaving} size="sm" onClick={onOk}>
                    OK
                </BusyFakeButton>
            </Modal.Body>
        </Modal>
    )
};

const TimeZoneSelector: React.FC<{ selectedTimeZone: number | undefined, setTimeZone: (timeZone: number) => void }> = ({selectedTimeZone, setTimeZone}) => {
    const defaultTimeZone: TimeZone = {
        display: "(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London",
        value: 0
    };

    const availableTimeZones: TimeZone[] = [
        {display: "(GMT-12:00) International Date Line West", value: -12},
        {display: "(GMT-11:00) Midway Island, Samoa", value: -11},
        {display: "(GMT-10:00) Hawaii", value: -10},
        {display: "(GMT-09:00) Alaska", value: -9},
        {display: "(GMT-08:00) Pacific Time (US & Canada)", value: -8},
        {display: "(GMT-07:00) Mountain Time (US & Canada)", value: -7},
        {display: "(GMT-06:00) Central Time (US & Canada)", value: -6},
        {display: "(GMT-05:00) Eastern Time (US & Canada)", value: -5},
        {display: "(GMT-04:00) Atlantic Time (Canada)", value: -4},
        {display: "(GMT-03:00) Greenland", value: -3},
        {display: "(GMT-02:00) Mid-Atlantic", value: -2},
        {display: "(GMT-01:00) Azores", value: -1},
        defaultTimeZone,
        {display: "(GMT+01:00) Brussels, Copenhagen, Madrid, Paris", value: 1},
        {display: "(GMT+02:00) Cairo", value: 2},
        {display: "(GMT+02:00) Harare, Pretoria", value: 2},
        {display: "(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius", value: 2},
        {display: "(GMT+02:00) Jerusalem", value: 2},
        {display: "(GMT+02:00) Minsk", value: 2},
        {display: "(GMT+02:00) Windhoek", value: 2},
        {display: "(GMT+03:00) Moscow, St. Petersburg, Volgograd", value: 3},
        {display: "(GMT+04:00) Abu Dhabi, Muscat", value: 4},
        {display: "(GMT+05:00) Yekaterinburg", value: 5},
        {display: "(GMT+05:00) Islamabad, Karachi, Tashkent", value: 5},
        {display: "(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi", value: 5.5},
        {display: "(GMT+06:00) Almaty, Novosibirsk", value: 6},
        {display: "(GMT+06:00) Astana, Dhaka", value: 6},
        {display: "(GMT+06:30) Yangon (Rangoon)", value: 6.5},
        {display: "(GMT+07:00) Bangkok, Hanoi, Jakarta", value: 7},
        {display: "(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi", value: 8},
        {display: "(GMT+09:00) Seoul", value: 9},
        {display: "(GMT+10:00) Canberra, Melbourne, Sydney", value: 10},
        {display: "(GMT+11:00) Magadan, Solomon Is., New Caledonia", value: 11},
        {display: "(GMT+12:00) Auckland, Wellington", value: 12},
        {display: "(GMT+13:00) Nuku'alofa", value: 13},
    ];

    const timeZone: TimeZone | undefined = availableTimeZones.find(it => it.value === selectedTimeZone);

    useEffect(() => {
        if (!timeZone) {
            setTimeZone(defaultTimeZone.value);
        }
    }, [timeZone, defaultTimeZone, setTimeZone]);

    return (
        <select id="time-zone" className="form-control" value={timeZone?.value || defaultTimeZone.value}
                onChange={((event: React.ChangeEvent<HTMLSelectElement>) => setTimeZone(parseInt(event.target.value)))}>
            {
                availableTimeZones.map((it: any, index: number) =>
                    <option key={index} value={it.value}>
                        {it.display}
                    </option>)
            }
        </select>
    )
};

export default UserPreferencePanel;