import React, {useContext} from "react";
import {Dropdown} from "react-bootstrap";
import FontAwesome from "react-fontawesome";
import {store} from "../../../../AppState";
import {Login, User} from "../../../../model/Account";

import './MultipleShareWithFriendChooser.css'

interface MultipleShareWithFriendChooserProps {
    selectedUsers: string[],
    setSelectedUsers: (friend: string[]) => void,
    allowOnlyBoardUsers?: boolean
    disabled?: boolean,
    allowSelectingMyself?: boolean
}

const findUser = (friendUserId: string | undefined, users: User[]): User | undefined => {
    return friendUserId ? users.find(it => it.userId === friendUserId) : undefined;
};

const getSelectedAssigneeName = (userId: string, users: User[]): string => {
    const assigneeCreator = findUser(userId, users);
    if (assigneeCreator) {
        return assigneeCreator?.displayName
    }

    return ""
};


const MultipleShareWithFriendChooser: React.FC<MultipleShareWithFriendChooserProps> = ({
                                                                                           selectedUsers,
                                                                                           setSelectedUsers,
                                                                                           allowOnlyBoardUsers = false,
                                                                                           disabled = false,
                                                                                           allowSelectingMyself = false
                                                                                       }) => {
    const {state} = useContext(store);
    const login: Login | undefined = state.lastSuccessfulLogin;
    const allUsers = [
        ...(state.selectedBoard?.sharedWithUsers || []),
        ...(state.selectedBoardOrganisation?.adminUserIds || []),
        ...(state.selectedBoardOrganisation?.userIds || []),
    ];

    const users: User[] = state.users?.filter(it => !allowOnlyBoardUsers || allUsers.includes(it.userId)) || [];

    const isSelected = (userId: string | undefined): boolean => !!selectedUsers.find(it => it === userId);
    const select = (userId: string) => {
        const newFriends = (isSelected(userId))
            ? selectedUsers.filter(it => it !== userId)
            : selectedUsers.concat([userId]);
        setSelectedUsers(newFriends);
    };

    const clear = () => setSelectedUsers([]);

    const assignees: string[] = selectedUsers.map(it => getSelectedAssigneeName(it, users)).filter(it => !!it?.length);
    const selectedAssignees: string = assignees.join(', ');

    return <Dropdown>
        <Dropdown.Toggle className="form-control friends-chooser"
                         style={{backgroundColor: 'white', color: 'black'}}
                         id="dropdown-basic"
                         disabled={disabled}>
            {selectedAssignees}
        </Dropdown.Toggle>
        <Dropdown.Menu>
            {
                <Dropdown.Item disabled={disabled} onClick={() => clear()}>
                    Clear All
                </Dropdown.Item>
            }
            {
                users
                    .filter(it => allowSelectingMyself || it.userId !== login?.userId)
                    .map((it: User, index: number) =>
                    <Dropdown.Item onClick={() => select(it.userId)} key={index}
                                   disabled={disabled}
                                   style={isSelected(it.userId) ? {backgroundColor: 'lightgray'} : {}}>
                        <FontAwesome name="square-full"/> {it.displayName}
                    </Dropdown.Item>)
            }
        </Dropdown.Menu>
    </Dropdown>
};

export default MultipleShareWithFriendChooser;