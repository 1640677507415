import Axios, {AxiosInstance, AxiosRequestConfig} from 'axios'

export interface TokenStore {
    accessToken: string,
    refreshToken: string
}

export type ProgressCallback = (progressEvent: ProgressEvent) => void

export default class HttpUtilService {
    private readonly axiosClient: AxiosInstance;
    private static tokenStore: TokenStore;

    constructor() {
        const config: AxiosRequestConfig = {
            validateStatus: () => true
        };
        this.axiosClient = Axios.create(config);
        this.axiosClient.interceptors.response.use((response) => {
            if (response.status >= 200 && response.status < 400) {
                return Promise.resolve(response);
            }

            return Promise.reject(new Error(response.data.error));
        })
    }

    public static setTokens(accessToken?: string, refreshToken?: string) {
        if (accessToken && refreshToken) {
            HttpUtilService.tokenStore = {accessToken, refreshToken};
        }
    }

    private static getHeaders(contentType = 'application/json') {
        const accessToken = HttpUtilService.tokenStore?.accessToken;
        if (!!accessToken) {
            return {
                'Content-Type': contentType,
                'Accept': contentType,
                'Authorization': accessToken
            }
        } else {
            return {
                'Accept': contentType,
                'Content-Type': contentType
            }
        }
    }

    get(url: string, baseURL: string, params?: any) {
        return this.axiosClient.request({
            method: 'get',
            url: url,
            baseURL: baseURL,
            params: params,
            headers: HttpUtilService.getHeaders()
        });
    }

    post(url: string, baseURL: string, body?: any, params?: any) {
        return this.axiosClient.request({
            method: 'post',
            url: url,
            baseURL: baseURL,
            data: body,
            params: params,
            headers: HttpUtilService.getHeaders()
        });
    }

    postS3(url: string, key: string, file: File, uploadProgressCallback?: ProgressCallback) {
        const formData = new FormData();
        formData.append('key', key);
        formData.append("Content-Type", file.type);
        formData.append('acl', "public-read");
        formData.append('file', file);

        return this.axiosClient.post(url, formData, {
            onUploadProgress: uploadProgressCallback
        });
    }

    delete(url: string, baseURL: string, body?: any, params?: any) {
        return this.axiosClient.request({
            method: 'delete',
            url: url,
            baseURL: baseURL,
            data: body,
            params: params,
            headers: HttpUtilService.getHeaders()
        });
    }
}