import {Goal} from "../../../../../model/Goal";
import {FriendsWithPendingInvites} from "../../../../../model/Friend";
import React, {useState} from "react";
import {dateToDayJs, dayJsToDate} from "../../../../../util/DateHelpers";
import {Button, ButtonGroup, Form} from "react-bootstrap";
import DatePicker from "react-datepicker";
import dayjs from "dayjs";
import {ColourChooser} from "../../../../../component/colourChooser/ColourChooser";

type AddGoalHandler = (goalName: string, colour: string, dueDate?: dayjs.Dayjs) => void;

interface AddEditGoalFormProps {
    addGoal: AddGoalHandler;
    onClose: () => void;
    goal?: Goal;
    friendResponse: FriendsWithPendingInvites | undefined;
}

const AddEditGoalForm: React.FC<AddEditGoalFormProps> = ({addGoal, onClose, goal}) => {
    const [goalName, setGoalName] = useState<string>(goal?.goalName || '');
    const [colour, setColour] = useState<string | undefined>(goal?.colour);
    const [dueDate, setDueDate] = useState<Date | null>(dayJsToDate(goal?.dueDate) || null);

    const isValid = () => {
        return !!goalName.length && !!colour;
    };

    const handleOnKeyPress = (event: React.KeyboardEvent<HTMLElement>) => {
        if (event.key === 'Enter') {
            handleSubmit();
        }
    };

    const handleSubmit = () => {
        addGoal(goalName, colour || '#00000', dateToDayJs(dueDate));
        onClose();
    };


    return (
        <div className="slate-background-transparent form-holder">
            <Form className="form">
                <Form.Group className="form">
                    <Form.Label>Goal Name</Form.Label>
                    <Form.Control type="text"
                                  value={goalName}
                                  onKeyPress={handleOnKeyPress}
                                  onChange={(e: any) => setGoalName((e as any).target.value)}>
                    </Form.Control>
                </Form.Group>
                <Form.Group className="form">
                    <Form.Label>Due Date (Optional)</Form.Label>
                    <DatePicker selected={dueDate}
                                wrapperClassName={"form-control"}
                                onChange={setDueDate}
                                dateFormat={'d MMM yyyy'}
                                withPortal
                                customInput={<Form.Control type="text"/>}/>
                </Form.Group>
                <Form.Group className="form">
                    <Form.Label>Label</Form.Label>
                    <ColourChooser selectedColour={colour} setColour={(it) => setColour(it)}/>
                </Form.Group>
                <ButtonGroup>
                    <Button disabled={!isValid()}
                            onClick={handleSubmit}>
                        OK
                    </Button>
                    <Button onClick={onClose}>
                        Cancel
                    </Button>
                </ButtonGroup>
            </Form>
        </div>
    )
};

export default AddEditGoalForm;