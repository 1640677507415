import React from "react";
import chromeExtension from "../../../assets/img/v5-chrome-extensions.gif";

export const V5: React.FC = () => {
    return (
        <>
            <h5>New Chrome Extension</h5>
            <span className="new">NEW!</span>
            <div className="login-text-holder">
                <span>Use the chrome extension to enable you to add tasks even when you are not actively on the Horizon website</span><br/>
                <span>Install the extension from the Chrome web store
                    <a href="https://chrome.google.com/webstore/detail/horizon/fgflbhelohpggpnpainfjjoiijdcipom?hl=en&authuser=1"> here.</a>
                </span>
            </div>
            <div className="images-holder">
                <img width="410px" src={chromeExtension} alt="Chrome extension"/>
            </div>
        </>
    )
};