import {Login} from "../../../../../model/Account";
import {Friend, FriendsWithPendingInvites, PendingInvite} from "../../../../../model/Friend";
import React, {useState} from "react";
import {ValidationUtils} from "../../../../../util/ValidationUtils";
import {Button, FormControl} from "react-bootstrap";
import BusyFakeButton from "../../../../../component/busyFakeButton/BusyFakeButton";
import FontAwesome from "react-fontawesome";
import LoadingComponent from "../../../../../component/loadingComponent/LoadingComponent";

interface SideBarFriendsPanelProps {
    successfulLogin: Login | undefined,
    friendResponse: FriendsWithPendingInvites | undefined,
    inviteFriend: (emailAddress: string) => Promise<void>,
    deleteFriend: (id: string) => Promise<void>,
    acceptFriendInvite: (id: string) => Promise<void>,
    deleteFriendInvite: (id: string) => Promise<void>
}

export const SideBarFriendsPanel: React.FC<SideBarFriendsPanelProps> = ({successfulLogin, friendResponse, inviteFriend, deleteFriend, acceptFriendInvite, deleteFriendInvite}) => {
    const [emailAddress, setEmailAddress] = useState<string>('');
    const [isBusy, setIsBusy] = useState<boolean>(false);

    const friends: Friend[] | undefined = friendResponse?.friends;
    const receiveFriendInvites: PendingInvite[] | undefined = friendResponse?.receivedFriendInvites;
    const sentFriendInvites: PendingInvite[] | undefined = friendResponse?.sentFriendInvites;

    const handleOnKeyPress = (event: React.KeyboardEvent<HTMLElement>) => {
        if (event.key === 'Enter' && isValidEmail(emailAddress)) {
            handleInviteFriend();
        }
    };

    const handleInviteFriend = () => {
        return busyHandler(async () => {
            await inviteFriend(emailAddress);
            setEmailAddress('');
        }, isBusy, setIsBusy);
    };

    const isValidEmail = (emailAddress: string): boolean => {
        return ValidationUtils.validateEmail(emailAddress)
            && emailAddress !== successfulLogin?.userEmail
            && !(sentFriendInvites || []).map(it => it.inviteeEmailAddress).includes(emailAddress)
    };

    return (
        <div className="side-bar-friends slate-background">
            {!!friends?.length && <b>Friends</b>}
            {
                friends?.map((it, index) => <FriendDisplay friend={it} deleteFriend={deleteFriend} key={index}/>)
            }
            <hr/>
            {!!receiveFriendInvites?.length && <b>Friend Requests</b>}
            {
                receiveFriendInvites?.map((it, index) => <ReceivedFriendRequest pendingInvite={it}
                                                                                key={index}
                                                                                acceptFriendInvite={acceptFriendInvite}
                                                                                deleteFriendInvite={deleteFriendInvite}/>)
            }
            {!!sentFriendInvites?.length && <b>Sent Requests</b>}
            {
                sentFriendInvites?.map((it, index) => <SentFriendRequest pendingInvite={it}
                                                                         key={index}
                                                                         deleteFriendInvite={deleteFriendInvite}/>)
            }
            {(!!receiveFriendInvites?.length || !!sentFriendInvites?.length) && <hr/>}
            <FormControl type="text"
                         size="sm"
                         placeholder="Please enter your friend's email address"
                         value={emailAddress}
                         onChange={(e: any) => setEmailAddress((e as any).target.value)}
                         onKeyPress={handleOnKeyPress}/>
            <BusyFakeButton size="sm" className="invite-friends-button" onClick={handleInviteFriend}
                            disabled={!isValidEmail(emailAddress)}
                            isBusy={isBusy}>
                Invite Friend
            </BusyFakeButton>
        </div>
    )
};


interface FriendProps {
    friend: Friend,
    deleteFriend: (id: string) => Promise<void>,
}

const FriendDisplay: React.FC<FriendProps> = ({friend, deleteFriend}) => {
    const [isBusy, setIsBusy] = useState<boolean>(false);
    const handleDeleteFriend = () => busyHandler(() => deleteFriend(friend.id), isBusy, setIsBusy);

    return (
        <div className="friend-request">
            <FontAwesome name="user-friends"/>{`${friend.friendDisplayName}`}
            <Button variant="link" onClick={handleDeleteFriend}><FontAwesome name="user-times"/></Button>
            <LoadingComponent isBusy={isBusy}/>
        </div>
    );
};

interface ReceivedFriendRequestProps {
    pendingInvite: PendingInvite,
    acceptFriendInvite: (id: string) => Promise<void>,
    deleteFriendInvite: (id: string) => Promise<void>
}

const ReceivedFriendRequest: React.FC<ReceivedFriendRequestProps> = ({pendingInvite, acceptFriendInvite, deleteFriendInvite}) => {
    const [isBusy, setIsBusy] = useState<boolean>(false);
    const handleAcceptFriendInvite = () => busyHandler(() => acceptFriendInvite(pendingInvite.id), isBusy, setIsBusy);
    const handleDeleteFriendInvite = () => busyHandler(() => deleteFriendInvite(pendingInvite.id), isBusy, setIsBusy);

    return (
        <div className="friend-request">
            <FontAwesome
                name="user-friends"/>{`${pendingInvite.inviteeEmailAddress} (${pendingInvite.inviteeDisplayName})`}
            <Button variant="link" onClick={handleAcceptFriendInvite}><FontAwesome name="user-check"/></Button>
            <Button variant="link" onClick={handleDeleteFriendInvite}><FontAwesome name="user-times"/></Button>
            <LoadingComponent isBusy={isBusy}/>
        </div>
    )
};

interface SentFriendRequestProps {
    pendingInvite: PendingInvite,
    deleteFriendInvite: (id: string) => Promise<void>
}

const SentFriendRequest: React.FC<SentFriendRequestProps> = ({pendingInvite, deleteFriendInvite}) => {
    const [isBusy, setIsBusy] = useState<boolean>(false);
    const handleDeleteFriendInvite = () => busyHandler(() => deleteFriendInvite(pendingInvite.id), isBusy, setIsBusy);

    return (
        <div className="friend-request">
            <FontAwesome name="mail-bulk"/>{`${pendingInvite.inviteeEmailAddress}`}
            <Button variant="link" onClick={handleDeleteFriendInvite}><FontAwesome name="user-times"/></Button>
            <LoadingComponent isBusy={isBusy}/>
        </div>
    )
};


const busyHandler = async (func: () => Promise<void>, isBusy: boolean, setIsBusy: (bool: boolean) => void) => {
    if (isBusy) {
        return;
    }
    setIsBusy(true);
    await func().finally(() => setIsBusy(false));
};