import React, {ReactNode, useContext} from "react";
import {FeatureLevel} from "../../model/Account";
import {store} from "../../AppState";
import {Routes} from "../../util/Properties";

import './FeatureNotAvailable.css'
import FontAwesome from "react-fontawesome";

interface FeatureNotAvailableProps {
    supportedFeatureLevels: FeatureLevel[],
    displayText: ReactNode;
}

const FeatureNotAvailable: React.FC<FeatureNotAvailableProps> = ({supportedFeatureLevels, displayText}) => {
    const {state} = useContext(store);
    const featureLevel: FeatureLevel | undefined = state.features?.level;

    if (supportedFeatureLevels.find(it => it === featureLevel)) {
        return <></>
    }

    return (
        <div>
            <a href={Routes.PRICING_PATH} className="feature-text">
                <FontAwesome name="info-circle"/> {displayText}
            </a>
        </div>
    )
};

export default FeatureNotAvailable;