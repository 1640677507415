import React, {useEffect} from "react";
import {Dropdown} from "react-bootstrap";

export const ColourChooser: React.FC<{ selectedColour: string | undefined, setColour: (colour: string) => void }> = ({selectedColour, setColour}) => {
    const availableColours: string[] = ['#ff2600', '#ff8400', '#ffdd00', '#00ff04', '#00ffe5', '#007bff', '#0011ff', '#5500ff', '#aa00ff', '#ff00ea'];

    useEffect(() => {
        if (!selectedColour) {
            const randomColour = availableColours[Math.floor(Math.random() * availableColours.length)];
            setColour(randomColour);
        }
    }, [availableColours, selectedColour, setColour]);

    return (
        <Dropdown>
            <Dropdown.Toggle className="form-control"
                             style={{backgroundColor: selectedColour, borderColor: selectedColour}}
                             id="dropdown-basic">
                <span style={{backgroundColor: selectedColour}} className="label"/>
            </Dropdown.Toggle>
            <Dropdown.Menu>
                {
                    availableColours.map(
                        (it, index) => <Dropdown.Item key={index} onClick={() => setColour(it)}>
                            <div style={{backgroundColor: it}} className="label"/>
                        </Dropdown.Item>)
                }
            </Dropdown.Menu>
        </Dropdown>
    )
};