import React, {useState} from "react";
import {Button, Form, Modal} from "react-bootstrap";
import {Routes} from "../../util/Properties";
import * as H from "history";
import {useHistory} from "react-router-dom";
import {UserPreferencesService} from "../../service/UserPreferencesService";
import BusyFakeButton from "../../component/busyFakeButton/BusyFakeButton";
import {UserPreferences} from "../../model/UserPreferences";

interface UnsubscribePanelProps {
    userId: string,
    userPreferences: UserPreferences,
    updateUserPreference: (userPreferences: UserPreferences) => void
}

const UnsubscribePanel: React.FC<UnsubscribePanelProps> = ({userId, userPreferences, updateUserPreference}) => {
    const history: H.History = useHistory();
    const [showPanel, setShowPanel] = useState<boolean>(true);
    const [isBusy, setIsBusy] = useState<boolean>(false);
    const [sendWeeklySummary, setSendWeeklySummary] = useState<boolean>(userPreferences.sendWeeklySummary || false);

    const closePanel = () => {
        history.push(Routes.PLAN_PATH);
        setShowPanel(false);
    };

    const handleUnsubToEmails = () => {
        setIsBusy(true);
        new UserPreferencesService().saveUserPreferences(userId, {
            ...userPreferences,
            userId: userId,
            sendWeeklySummary: sendWeeklySummary
        }).then((userPreferences) => {
            updateUserPreference(userPreferences);
            closePanel();
        }).finally(() => setIsBusy(false));
    };

    return (
        <Modal show={showPanel} animation={false} onHide={closePanel}>
            <Modal.Header>
                <Modal.Title>
                    Email Settings
                </Modal.Title>
                <Button variant="link" onClick={closePanel}>X</Button>
            </Modal.Header>
            <Modal.Body className="sign-up-modal">
                <Form.Check type="checkbox"
                            label="Subscribe to Weekly Summary emails"
                            checked={sendWeeklySummary}
                            onChange={(e: any) => setSendWeeklySummary(e.target.checked)}/>
                <br/>
                <BusyFakeButton className="button-center"
                                isBusy={isBusy}
                                size="sm"
                                onClick={handleUnsubToEmails}>
                    Save
                </BusyFakeButton>
            </Modal.Body>
        </Modal>
    )
};

export default UnsubscribePanel;