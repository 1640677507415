import HttpUtilService from "./HttpUtilService";
import {HttpUrls} from "../util/Properties";
import {Comment, ItemType, toComment} from "../model/Comment";

export class CommentService {

    private readonly USER_ACCOUNT_SERVICE_URL: string;
    private readonly httpUtilService: HttpUtilService;

    constructor(USER_ACCOUNT_SERVICE_URL: string | undefined = process.env.REACT_APP_USER_ACCOUNT_BACK_END_API,
                httpUtilService: HttpUtilService = new HttpUtilService()) {

        this.USER_ACCOUNT_SERVICE_URL = USER_ACCOUNT_SERVICE_URL || "";
        this.httpUtilService = httpUtilService;
    }


    public getComments(itemType: ItemType, itemId: string): Promise<Comment[]> {
        return this.httpUtilService.get(HttpUrls.USER_COMMENTS(itemType, itemId), this.USER_ACCOUNT_SERVICE_URL)
            .then(response => response.data.map((it: any) => toComment(it)));
    }

    public addComment(itemType: ItemType, itemId: string, comment: Comment): Promise<Comment[]> {
        return this.httpUtilService.post(HttpUrls.USER_COMMENTS(itemType, itemId), this.USER_ACCOUNT_SERVICE_URL, comment)
            .then(response => response.data.map((it: any) => toComment(it)));
    }

    public deleteComment(commentId: string): Promise<Comment[]> {
        return this.httpUtilService.delete(HttpUrls.USER_COMMENT(commentId), this.USER_ACCOUNT_SERVICE_URL)
            .then(response => response.data.map((it: any) => toComment(it)));
    }

}