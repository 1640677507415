import UserService from "../UserService";
// @ts-ignore
import {User} from "../../model/Account";

const userCache = new Map<string, User>();

export class UserCacheService {

    private readonly userService: UserService;

    constructor(userService = new UserService()) {
        this.userService = userService;
    }

    public async getUsers(ids: string[]): Promise<User[]> {
        const uniqueIds = Array.from(new Set<string>(ids));
        const idsNotInCache: string[] = uniqueIds.filter(id => !userCache.get(id));

        if (idsNotInCache.length) {
            const users: User[] = await this.userService.getUser(idsNotInCache);
            users.forEach(user => userCache.set(user.userId, user));
        }

        return uniqueIds
            .map(id => userCache.get(id))
            .filter(it => !!it)
            .map(it => it as User);
    }

}