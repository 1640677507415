export class NotificationService {

    public async createNotification(notificationText: string, additionalData: any = undefined): Promise<void> {
        return navigator.serviceWorker.ready.then(function (registration) {
            return registration.showNotification('Horizon', {
                body: notificationText,
                icon: '/logo192.png',
                vibrate: [200, 100, 200],
                data: additionalData
            });
        }).catch(() => {
            console.error('Notification unhandled');
        });
    }

}