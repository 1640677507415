import React from "react";
import {PermissionService} from "../service/PermissionService";
import {store} from "../AppState";
import memoize from "fast-memoize";
import {Board, BoardSettings, DEFAULT_COLUMNS, PermissionLevel} from "../model/Board";

type useContextFactory = <T>(context: React.Context<T>) => T;

export class Selector {
    useContext: useContextFactory;

    public constructor(useContext: useContextFactory) {
        this.useContext = useContext;
    }

    public getSelectedBoardColumns: () => string[] = () => {
        const {state} = this.useContext(store);
        const getColumns = (selectedBoard: Board) => selectedBoard?.columns || DEFAULT_COLUMNS;
        return useMemoizedFunction(getColumns)(state.selectedBoard);
    };

    public getReadPermissionAccess: () => PermissionLevel = () => {
        const {state} = this.useContext(store);
        const getReadBoardSettings = (boardSettings: BoardSettings | undefined) => boardSettings?.readPermissionAccess;
        return useMemoizedFunction(getReadBoardSettings)(state.selectedBoard?.settings);
    };

    public checkBoardWriteAccess: () => boolean = () => {
        const {state} = this.useContext(store);
        return useMemoizedFunction(new PermissionService().checkBoardAccess)(
            state.lastSuccessfulLogin,
            state.selectedBoard,
            state.selectedBoard?.settings?.writePermissionAccess);
    };

}

type Func = (...args: any[]) => any;
const memoizedFunctions = new Map<string, Func>();

const useMemoizedFunction = (func: Func): Func => {
    if (!memoizedFunctions.has(func.name)) {
        memoizedFunctions.set(func.name, memoize(func));
    }
    return memoizedFunctions.get(func.name) as Func;
};
