import React, {ChangeEvent, useCallback, useContext, useEffect, useState} from "react";
import {Button, ButtonGroup, Col, Form, Modal, Row} from "react-bootstrap";
import {DurationInMillis, Task, TaskFilter, TaskStatus} from "../../../../model/Task";
import {DragDropContainer, DropTarget} from 'react-drag-drop-container';
import dayjs from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat'
import FakeButton from "../../../../component/fakeButton/FakeButton";
import {v4} from 'uuid'
import "react-datepicker/dist/react-datepicker.css";
import FontAwesome from "react-fontawesome";
import {formatDateToDateTimeString} from "../../../../util/DateHelpers";
import {Goal} from "../../../../model/Goal";
import ColumnExpander from "../../../../component/columnExpander/ColumnExpander";
import ConfirmAction from "../../../../component/confirmAction/ConfirmAction";
import TaskComment from "./taskComments/TaskCommentsContainer";
import GoalDisplay from "./goalDisplay/GoalDisplay";
import AddEditTaskForm, {AddingTask} from "./addEditTaskForm/AddEditTaskForm";
import {Board, DEFAULT_COLUMNS} from "../../../../model/Board";
import {Routes} from "../../../../util/Properties";
import {useHistory, useLocation} from "react-router-dom";
import * as H from "history";

import './TasksBoard.css'
import {TaskAction} from "./taskActions/TaskActions";
import RecurringTaskFormContainer from "./recurringTaskForm/RecurringTaskFormContainer";
import {Login} from "../../../../model/Account";
import {SelectedAssignee} from "../assigneeChooser/AssigneeChooser";
import ShowFeature from "../../../../component/showFeature/ShowFeature";
import {Base64} from "js-base64";
import TaskUploadFileContainer from "./taskUploadFile/TaskUploadFile";
import {Selector} from "../../../../state/selector";
import LoadingComponent from "../../../../component/loadingComponent/LoadingComponent";

dayjs.extend(customParseFormat);

const SHOW_LIMIT_UNEXPECTED = 3;

type AddTaskHandler = (taskName: string, dueDate?: dayjs.Dayjs, goalId?: string, assignedUserId?: string, link?: string) => void;
type EditTaskHandler = (taskId: string, taskName: string, dueDate?: dayjs.Dayjs, goalId?: string, assignedUserId?: string, link?: string) => void;
type RemoveTaskHandler = (taskId: string) => void;
type SwapTaskOrderHandler = (taskId: string, taskId2: string) => void;
type ChangeTaskStatusHandler = (taskId: string, taskStatus: TaskStatus) => void;
type SetTimeSpendHandler = (taskId: string, timeSpent: DurationInMillis | undefined) => void;
type MoveTaskHandler = (tasks: Task[], targetBoard: Board) => Promise<void>;
type SwapColumnHandler = (column1: string, column2: string) => Promise<void>;

interface TaskDragEvent {
    dragData: {
        taskId: string,
    }
}

interface ColumDragEvent {
    dragData: {
        taskStatus: string
    }
}

const findTask = (taskId: string | undefined, tasks: Task[]): Task | undefined => {
    return tasks.find(it => it.id === taskId);
};

const findGoal = (goalId: string | undefined, goals: Goal[]): Goal | undefined => {
    return goalId ? goals.find(it => it.id === goalId) : undefined;
};

interface TasksBoardProps {
    isSignedIn: boolean;
    successfulLogin: Login | undefined;
    tasks: Task[],
    setTasks: (tasks: Task[]) => void,
    goals: Goal[],
    setGoals: (goals: Goal[]) => Promise<void>,
    taskFilter: TaskFilter,
    selectedBoard: Board | undefined
    boards: Board[],
    setBoards: (boards: Board[]) => Promise<void>,
    moveTasks: MoveTaskHandler,
    setAutomationPanelShown: (value: boolean) => void
}

const TasksBoard: React.FC<TasksBoardProps> = ({
                                                   isSignedIn, successfulLogin,
                                                   tasks, setTasks, goals,
                                                   taskFilter,
                                                   selectedBoard,
                                                   boards, setBoards,
                                                   moveTasks,
                                                   setAutomationPanelShown
                                               }) => {


    const [isDragging, setDragging] = useState(false);
    const [taskForTimeTrackingId, setTaskForTimeTrackingId] = useState<string | undefined>();
    const canWrite = new Selector(useContext).checkBoardWriteAccess();

    const columns = selectedBoard?.columns ? selectedBoard.columns : DEFAULT_COLUMNS;
    const lastState = columns[columns.length - 1];

    const updateBoards = async (newColumns: string[]) => {
        if (!selectedBoard) {
            return;
        }

        const newSelectedBoard: Board = {
            ...selectedBoard,
            columns: newColumns
        };
        const newBoards: Board[] = boards.map(it => {
            return it.id === newSelectedBoard.id ? newSelectedBoard : it;
        });

        await setBoards(newBoards);
    };

    const computeNewColumnName = (newColumnName: string, oldColumnName?: string) => {
        const length = columns
            .filter(it => it !== oldColumnName)
            .filter(it => it.match(`^(${newColumnName})( \\([0-9]+\\))*$`)).length;

        return length >= 1 ? `${newColumnName} (${length + 1})` : newColumnName;
    };

    const addNewColumn = async (index: number, columnName: string) => {
        const newColumnName = computeNewColumnName(columnName);

        const newColumns: string[] = [...columns];
        newColumns.splice(index, 0, newColumnName);
        await updateBoards(newColumns);
    };

    const updateBoardColumns = async (index: number, columnName: string) => {
        const oldColumnName = columns[index];
        const newColumnName = computeNewColumnName(columnName, oldColumnName);

        const newColumns: string[] = columns.map((name, no) => index === no ? newColumnName : name);
        const newTasks: Task[] = tasks.map(it => it.status === oldColumnName ? {...it, status: newColumnName} : it);

        setTasks(newTasks);
        await updateBoards(newColumns);
    };

    const deleteColumn = async (index: number) => {
        const otherColumn = columns[index - 1];
        const currentColumn = columns[index];

        const newColumns: string[] = columns.filter((_, no) => index !== no);
        const newTasks: Task[] = tasks.map(it => it.status === currentColumn ? {...it, status: otherColumn} : it);

        setTasks(newTasks);
        await updateBoards(newColumns);
    };

    const swapColumns: SwapColumnHandler = async (column1: string, column2: string): Promise<void> => {
        const column1Index: number = columns.findIndex(it => it === column1);
        const column2Index: number = columns.findIndex(it => it === column2);

        if (column1Index !== -1 && column2Index !== -1) {
            const newColumns = [...columns];
            newColumns[column2Index] = column1;
            newColumns[column1Index] = column2;
            await updateBoards(newColumns);
        }
    };

    const addTask: AddTaskHandler = (taskName, dueDate, goalId, assignedUserId, link) => {
        const newTasks: Task[] = tasks.concat({
            id: v4(),
            status: columns[0],
            noOfComments: 0,
            noOfFileUploads: 0,
            taskName: taskName,
            dueDate: dueDate,
            goalId: goalId,
            assigneeUserId: assignedUserId,
            link: link
        });

        setTasks(newTasks);
    };

    const editTask: EditTaskHandler = (taskId, taskName, dueDate, goalId, assigneeUserId, link) => {
        const newTasks: Task[] = tasks.map((it: Task) => {
            if (it.id === taskId) {
                return {
                    ...it,
                    taskName: taskName,
                    dueDate: dueDate,
                    goalId: goalId,
                    assigneeUserId: assigneeUserId,
                    link: link
                }
            }
            return it;
        });

        setDragging(false);
        setTasks(newTasks);
    };

    const removeTask: RemoveTaskHandler = (taskId) => {
        const newTasks: Task[] = tasks.filter((task: Task) => task.id !== taskId);
        setDragging(false);
        setTasks(newTasks);
    };

    const swapTaskOrders: SwapTaskOrderHandler = (firstTaskId, secondTaskId) => {
        const targetTaskIndex = tasks.findIndex(it => it.id === firstTaskId) || 0;

        const targetTask = tasks[targetTaskIndex];
        const draggingTask = findTask(secondTaskId, tasks);

        if (!!draggingTask) {
            if (targetTask.status === lastState && draggingTask.status !== lastState) {
                setTaskForTimeTrackingId(draggingTask.id);
                return;
            }

            const newTasks = [...tasks.filter(it => it.id !== draggingTask.id)];
            newTasks.splice(targetTaskIndex, 0, {...draggingTask, status: targetTask.status});
            setTasks(newTasks);
        }

    };

    const changeTaskStatus: ChangeTaskStatusHandler = (taskId, taskStatus) => {
        const task = findTask(taskId, tasks);
        if (!!task && task.status === taskStatus) {
            return;
        }

        if (taskStatus === lastState) {
            setTaskForTimeTrackingId(taskId);
            return;
        }

        const newTasks: Task[] = tasks.map(it => {
            return it.id === taskId ? {...it, status: taskStatus} : it;
        });

        setTasks(newTasks);
    };

    const moveToTop = (taskId: string) => {
        const targetTask = findTask(taskId, tasks);
        if (!!targetTask) {
            const newTasks = [...tasks.filter(it => it.id !== targetTask.id)];
            newTasks.splice(0, 0, targetTask);
            setTasks(newTasks);
        }
    };

    const moveToBottom = (taskId: string) => {
        const targetTask = findTask(taskId, tasks);
        if (!!targetTask) {
            const newTasks = [...tasks.filter(it => it.id !== targetTask.id)];
            newTasks.splice(newTasks.length, 0, targetTask);
            setTasks(newTasks);
        }
    };

    const setTaskComplete: SetTimeSpendHandler = (taskId: string, timeSpent: DurationInMillis | undefined) => {
        const newTasks: Task[] = tasks.map((it: Task) => {
            if (it.id === taskId) {
                return {
                    ...it,
                    status: lastState,
                    timeSpent: timeSpent
                }
            }
            return it;
        });
        setTasks(newTasks);
        onCloseTimeSpentPanel();
    };

    const showAutomationPanel = () => setAutomationPanelShown(true);
    const onCloseTimeSpentPanel = () => setTaskForTimeTrackingId(undefined);

    const location: H.Location = useLocation();
    const history: H.History = useHistory();
    const showTask: string | null = new URLSearchParams(location.search).get("showTask");

    const scrollToTask = useCallback((taskId: string) => {
        setDefaultExpanded(true);
        setTimeout(() => {
            const element = document.getElementById(taskId);
            if (element) {
                element.scrollIntoView({behavior: "smooth", block: "end", inline: "end"});
                (element as any).classList.add('highlight');
                history.push(Routes.PLAN_PATH)
            }
        }, 400);

    }, [history]);

    useEffect(() => {
        if (showTask) {
            scrollToTask(showTask);
        }
    }, [scrollToTask, showTask]);

    const [defaultExpanded, setDefaultExpanded] = useState(false);
    const [isColumnDragging, setIsColumnDragging] = useState<boolean>(false);

    const taskColumnProps = {
        isSignedIn,
        tasks, goals,
        addTask, editTask, removeTask, swapTaskOrders, changeTaskStatus, moveToTop, moveToBottom, swapColumns,
        isDragging, setDragging,
        taskFilter, selectedBoard, boards, moveTasks, lastState, defaultExpanded, isColumnDragging, setIsColumnDragging
    };

    const signedIn = isSignedIn && selectedBoard?.creatorUserId === successfulLogin?.userId;
    return (
        <>
            <TimeTrackingPanel task={findTask(taskForTimeTrackingId, tasks)} onOK={setTaskComplete}
                               onClose={onCloseTimeSpentPanel}/>

            <div className="tasks-holder slate-background-transparent">
                <div>
                    <span className="panel-title">
                        <FontAwesome name="tasks"/> Tasks
                    </span>

                    {isSignedIn &&
                    <Button className="automation-button" disabled={!canWrite} variant="link"
                            onClick={showAutomationPanel}>
                        <FontAwesome name="cogs"/>
                    </Button>}

                    <TaskFilterDisplay taskFilter={taskFilter}/>
                </div>
                <div className="tasks">
                    <Row>
                        {columns.map((taskStatus, index) => <Col key={index} className="tasks-col"
                                                                 md={columns.length > 4 ? undefined : true}
                                                                 lg={true}>
                            <TaskColumn taskStatus={taskStatus}
                                        columnNo={index}
                                        setColumnName={signedIn
                                            ? (columnName) => updateBoardColumns(index, columnName)
                                            : undefined}
                                        addColumn={signedIn && columns.length < 6
                                            ? () => addNewColumn(index + 1, "New Column")
                                            : undefined}
                                        deleteColumn={signedIn && (index !== 0) ? () => deleteColumn(index) : undefined}
                                        {...taskColumnProps}/>
                        </Col>)}
                    </Row>
                </div>
            </div>
        </>
    )
};

const TaskFilterDisplay: React.FC<{ taskFilter: TaskFilter }> = ({taskFilter}) => {
    if (!taskFilter.showTaskMatchingGoal) {
        return <></>
    }

    return (
        <>
            <br/>
            <span className="task-filter-display">
                ( Showing <b>'{taskFilter.showTaskMatchingGoal.goalName}'</b> tasks only )
            </span>
        </>
    );
};

interface TimeTrackingPanelProps {
    task: Task | undefined;
    onOK: (taskId: string, timeSpent: DurationInMillis | undefined) => void;
    onClose: () => void;
}

const TimeTrackingPanel: React.FC<TimeTrackingPanelProps> = ({task, onOK, onClose}) => {
    const [selectTimeSpent, setSelectTimeSpent] = useState<DurationInMillis | undefined>(undefined);

    const handleSetTimeSpent = () => {
        if (!task) {
            return;
        }
        onOK(task.id, selectTimeSpent);
    };

    const availableTimeZones: any[] = [
        {display: "", value: undefined},
        {display: "About 1 minute", value: 60000},
        {display: "About 5 minutes", value: 300000},
        {display: "About 15 minutes", value: 900000},
        {display: "About 30 minutes", value: 1.8e+6},
        {display: "About 1 hour", value: 3.6e+6},
        {display: "About 2 hours", value: 7.2e+6},
        {display: "About 4 hours", value: 1.44e+7},
        {display: "About 1 day", value: 8.64e+7},
        {display: "About 2 days", value: 1.728e+8},
        {display: "About 4 days", value: 3.456e+8},
        {display: "About 1 week", value: 6.048e+8},
        {display: "About 2 weeks", value: 1.21e+9},
        {display: "About 3 weeks", value: 1.814e+9},
        {display: "About 1 month", value: 2.628e+9},
    ];

    return (
        <Modal show={!!task} animation={false}>
            <Modal.Header>
                <Modal.Title>Set Time Spent</Modal.Title>
            </Modal.Header>
            <Modal.Body className="shared-goal-panel">
                <span>How much time did you spend on this task?</span>
                <br/>
                <div>
                    <select id="time-tracking-select"
                            className="form-control"
                            value={selectTimeSpent}
                            onChange={((event: ChangeEvent<HTMLSelectElement>) => setSelectTimeSpent(parseInt(event.target.value)))}>
                        {
                            availableTimeZones.map((it: any, index: number) =>
                                <option key={index} value={it.value}>
                                    {it.display}
                                </option>)
                        }
                    </select>
                </div>
                <div className="button-group">
                    <FakeButton size="sm" onClick={handleSetTimeSpent}>
                        Set
                    </FakeButton>
                    <FakeButton size="sm" onClick={onClose}>
                        Cancel
                    </FakeButton>
                </div>
            </Modal.Body>
        </Modal>
    )
};

interface TaskColumnProps {
    isSignedIn: boolean,
    tasks: Task[],
    goals: Goal[],
    taskStatus: TaskStatus,
    addTask: AddTaskHandler,
    editTask: EditTaskHandler,
    removeTask: RemoveTaskHandler,
    swapTaskOrders: SwapTaskOrderHandler,
    changeTaskStatus: ChangeTaskStatusHandler,
    swapColumns: SwapColumnHandler,
    moveToTop: (taskId: string) => void,
    moveToBottom: (taskId: string) => void,
    isDragging: boolean,
    setDragging: (isDragging: boolean) => void,
    taskFilter: TaskFilter,
    selectedBoard: Board | undefined
    boards: Board[],
    columnNo: number,
    setColumnName?: (newColumn: string) => Promise<void>,
    addColumn?: () => Promise<void>
    deleteColumn?: () => Promise<void>
    moveTasks: MoveTaskHandler,
    lastState: string,
    defaultExpanded: boolean,
    isColumnDragging: boolean,
    setIsColumnDragging: (value: boolean) => void
}

const TaskColumn: React.FC<TaskColumnProps> = ({
                                                   isSignedIn, tasks, goals, taskStatus,
                                                   addTask, editTask, removeTask, swapTaskOrders,
                                                   changeTaskStatus, swapColumns, moveToTop, moveToBottom,
                                                   setDragging, taskFilter,
                                                   selectedBoard, boards,
                                                   columnNo, setColumnName, moveTasks, addColumn, deleteColumn, lastState,
                                                   defaultExpanded = false,
                                                   isColumnDragging, setIsColumnDragging
                                               }) => {

    const [isAddTaskOpen, setAddTaskOpen] = useState(false);
    const [isExpanded, setExpanded] = useState(defaultExpanded);
    const [addingTask, setAddingTask] = useState<AddingTask | undefined>(undefined);

    const dragAreaClassName = `drag-area`;

    const onDragDrop = (e: TaskDragEvent) => {
        changeTaskStatus(e.dragData.taskId, taskStatus);
    };

    const handleAddTask = () => {
        setAddTaskOpen(true);
        setExpanded(true);
        setTimeout(() => {
            const element = document.getElementById("add-task-form");
            if (element) {
                element.scrollIntoView({behavior: "smooth", block: "end", inline: "end"});
            }
        }, 250);
    };

    useEffect(() => setExpanded(defaultExpanded), [defaultExpanded]);

    const location: H.Location = useLocation();
    const history: H.History = useHistory();
    const addTaskValue: string | null = new URLSearchParams(location.search).get("addTask");

    useEffect(() => {
        if (isSignedIn && addTaskValue) {
            try {
                const json: any = JSON.parse(Base64.decode(JSON.stringify(addTaskValue)));
                setAddingTask({
                    ...json,
                    dueDate: json.dueDate ? dayjs(json.dueDate, "D MMMM YYYY hh:mm A") : undefined
                });
                handleAddTask();
            } catch (e) {
                console.error('couldn\'t handle "addTask" parameter');
            } finally {
                history.push(Routes.PLAN_PATH);
            }
        }
    }, [history, addTaskValue, isSignedIn]);


    const taskMatchStatus = tasks.filter(it => it.status === taskStatus);
    const displayTasks = !isExpanded ? taskMatchStatus.slice(0, SHOW_LIMIT_UNEXPECTED) : taskMatchStatus;
    const showExpandButton = taskMatchStatus.length > SHOW_LIMIT_UNEXPECTED;
    const showAddTaskButton = !isAddTaskOpen && columnNo === 0;
    const canWrite = new Selector(useContext).checkBoardWriteAccess();

    const otherBoards = boards.filter(it => it.id !== selectedBoard?.id);
    return (
        <div className="tasks-column">
            <DropTarget targetKey="task" onHit={onDragDrop}>
                <TaskColumnHeader taskStatus={taskStatus}
                                  setColumnName={setColumnName}
                                  addColumn={addColumn}
                                  deleteColumn={deleteColumn}
                                  swapColumns={swapColumns}
                                  isColumnDragging={isColumnDragging}
                                  setIsColumnDragging={setIsColumnDragging}/>
                <div className={dragAreaClassName}>
                    <div className="tasks-column-body">
                        {
                            displayTasks
                                .filter((task) => !!taskFilter.showTaskMatchingGoal ? taskFilter.showTaskMatchingGoal?.id === task.goalId : true)
                                .map((task, index) => <TaskDisplay isSignedIn={isSignedIn}
                                                                   task={task}
                                                                   taskIndex={index}
                                                                   goals={goals}
                                                                   key={index}
                                                                   editTask={editTask}
                                                                   onRemove={() => removeTask(task.id)}
                                                                   swapTaskOrders={swapTaskOrders}
                                                                   changeTaskStatus={changeTaskStatus}
                                                                   setDragging={setDragging}
                                                                   moveToTop={index === 0 ? undefined : moveToTop}
                                                                   moveToBottom={index === taskMatchStatus.length - 1 ? undefined : moveToBottom}
                                                                   selectableBoards={otherBoards}
                                                                   lastState={lastState}
                                                                   moveTasks={moveTasks}/>)
                        }
                    </div>
                    {
                        showAddTaskButton &&
                        <div className="full-width">
                            {canWrite && <FakeButton size="sm" onClick={handleAddTask}>
                                Add Task
                            </FakeButton>}
                        </div>
                    }
                    {
                        showExpandButton &&
                        <div className="full-width">
                            <ColumnExpander isExpanded={isExpanded}
                                            setExpanded={setExpanded}
                                            showMoreMessage={`Show ${taskMatchStatus.length - SHOW_LIMIT_UNEXPECTED} more`}/>
                        </div>
                    }
                    {
                        isAddTaskOpen && columnNo === 0 &&
                        <div className="full-width">
                            <AddEditTaskForm addTask={addTask}
                                             task={addingTask}
                                             goals={goals}
                                             onClose={() => setAddTaskOpen(!isAddTaskOpen)}/>
                        </div>
                    }
                </div>
            </DropTarget>
        </div>
    )
};

export const getColumnHeaderName = (name: TaskStatus) => {
    switch (name) {
        case "TODO":
            return "To Do";
        case "IN_PROGRESS":
            return "In Progress";
        case "DONE":
            return "Done";
        default:
            return name;
    }
};

interface TaskColumnHeaderProps {
    taskStatus: TaskStatus,
    swapColumns: SwapColumnHandler,
    setColumnName?: (newColumn: string) => Promise<void>,
    addColumn?: () => Promise<void>,
    deleteColumn?: () => Promise<void>,
    isColumnDragging: boolean,
    setIsColumnDragging: (value: boolean) => void
}

const TaskColumnHeader: React.FC<TaskColumnHeaderProps> = ({
                                                               taskStatus, swapColumns, setColumnName, addColumn, deleteColumn,
                                                               isColumnDragging, setIsColumnDragging
                                                           }) => {


    const titleHeader: string = getColumnHeaderName(taskStatus);
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [newColumnName, setNewColumnName] = useState<string>(titleHeader);

    useEffect(() => {
        setNewColumnName(getColumnHeaderName(taskStatus));
    }, [taskStatus]);

    const handleOnKeyPress = (event: React.KeyboardEvent<HTMLElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleSetColumnName();
        }
    };

    const handleSetColumnName = async () => {
        if (!setColumnName) {
            return;
        }

        setIsSaving(true);
        await setColumnName(getColumnHeaderName(newColumnName))
            .finally(() => {
                setIsEditing(false);
                setIsSaving(false);
            });
    };

    const handleCancel = () => {
        setIsEditing(false);
        setNewColumnName(titleHeader);
    };

    const onDragDrop = (e: ColumDragEvent) => {
        if (e.dragData.taskStatus === taskStatus) {
            return;
        }

        setIsSaving(true);
        swapColumns(e.dragData.taskStatus, taskStatus)
            .finally(() => setIsSaving(false));
    };

    if (isEditing) {
        return <Form inline className="task-column-edit-holder">
            <Form.Control type="text"
                          className="task-column-edit"
                          value={newColumnName}
                          onKeyPress={handleOnKeyPress}
                          onChange={(e: any) => setNewColumnName((e as any).target.value)}/>
            <ButtonGroup>
                <Button variant="link" className="column-edit" onClick={() => handleSetColumnName()}><FontAwesome
                    name="check"/></Button>
                <Button variant="link" className="column-edit" onClick={() => handleCancel()}><FontAwesome
                    name="times"/></Button>
            </ButtonGroup>
            <LoadingComponent isBusy={isSaving}/>
        </Form>
    }


    return (
        <DropTarget targetKey="column" onHit={onDragDrop}>
            <DragDropContainer targetKey='column'
                               onDragStart={() => setIsColumnDragging(true)}
                               onDragEnd={() => setIsColumnDragging(false)}
                               dragHandleClassName='task-column-header-holder'
                               dragData={{taskStatus: taskStatus}}>

                <div className={`task-column-header-holder ${isColumnDragging ? 'dragging': ''}`}>
                    <span className={`task-column-header`}>{titleHeader}</span>

                    {setColumnName &&
                    <Button variant="link" className="column-edit" onClick={() => setIsEditing(true)}><FontAwesome
                        name="edit"/></Button>}

                    {deleteColumn &&
                    <ConfirmAction confirmButtonText="Delete"
                                   confirmMessage={"Are you sure you want to remove this column?"}
                                   onClick={() => deleteColumn()}>
                        <Button variant="link" className="column-edit"><FontAwesome name="trash-alt"/></Button>
                    </ConfirmAction>
                    }

                    {addColumn &&
                    <Button variant="link" className="column-edit" onClick={() => addColumn()}><FontAwesome
                        name="plus-square"/></Button>}
                    <LoadingComponent isBusy={isSaving}/>
                </div>
            </DragDropContainer>
        </DropTarget>
    )
};

interface TaskEditDisplayActionProps {
    startEditing: () => void,
    startWritingComments: () => void,
    startRecurringTaskSetup: () => void,
    startFileUpload: () => void
}

interface TaskDisplayProps {
    isSignedIn: boolean,
    task: Task,
    taskIndex: number
    goals: Goal[],
    editTask: EditTaskHandler,
    onRemove: () => void,
    swapTaskOrders: SwapTaskOrderHandler,
    changeTaskStatus: ChangeTaskStatusHandler,
    moveToTop: ((taskId: string) => void) | undefined,
    moveToBottom: ((taskId: string) => void) | undefined,
    setDragging: (isDragging: boolean) => void,
    selectableBoards: Board[],
    moveTasks: MoveTaskHandler,
    lastState: string
}

type EditMode = "WRITING_COMMENTS" | "EDIT_TASK" | "RECURRING_TASK" | "UPLOAD_FILE"

const TaskDisplay: React.FC<TaskDisplayProps> = ({
                                                     isSignedIn, task, taskIndex, goals, editTask, onRemove, swapTaskOrders,
                                                     changeTaskStatus, moveToTop, moveToBottom, setDragging,
                                                     selectableBoards, moveTasks, lastState
                                                 }) => {


    const [editMode, setEditMode] = useState<EditMode | undefined>(undefined);

    const editingActions: TaskEditDisplayActionProps = {
        startEditing: () => {
            setDragging(false);
            setEditMode("EDIT_TASK");
        },

        startWritingComments: () => {
            setDragging(false);
            setEditMode("WRITING_COMMENTS");
        },

        startRecurringTaskSetup: () => {
            setDragging(false);
            setEditMode("RECURRING_TASK");
        },

        startFileUpload: () => {
            setDragging(false);
            setEditMode("UPLOAD_FILE");
        }
    };

    const handleRemove = (e: React.MouseEvent) => {
        e.stopPropagation();
        onRemove();
    };

    const handleEditTask: AddTaskHandler = (taskName: string, dueDate?: dayjs.Dayjs, goalId?: string, assignedUserId?: string, link?: string) => {
        editTask(task.id, taskName, dueDate, goalId, assignedUserId, link);
    };

    const onDropAbove = (e: TaskDragEvent) => {
        swapTaskOrders(task.id, e.dragData.taskId);
    };

    const startDragging = () => {
        const itemWidth = document.querySelectorAll('.tasks-holder .ddcontainersource')[taskIndex]?.clientWidth;
        document.querySelectorAll('.tasks-holder .ddcontainerghost').forEach((value => {
            (value as any).style.width = `${itemWidth}px`;
        }))
    };

    const targetKey = `task`;
    const assignedGoal: Goal | undefined = findGoal(task.goalId, goals);
    const canWrite = new Selector(useContext).checkBoardWriteAccess();

    const showForm = (editMode: EditMode | undefined) => {
        switch (editMode) {
            case "EDIT_TASK":
                return <AddEditTaskForm addTask={handleEditTask}
                                        task={task}
                                        goals={goals}
                                        onClose={() => setEditMode(undefined)}/>;
            case "WRITING_COMMENTS":
                return <TaskComment task={task}
                                    assignedGoal={assignedGoal}
                                    onClose={() => setEditMode(undefined)}/>;
            case "RECURRING_TASK":
                return <RecurringTaskFormContainer task={task}
                                                   onClose={() => setEditMode(undefined)}/>;
            case "UPLOAD_FILE":
                return <TaskUploadFileContainer task={task}
                                                onClose={() => setEditMode(undefined)}/>;
            default:
                return <></>
        }
    };

    return (
        <div id={task.id} className="task-holder">
            <div className="drop-target-holder">
                <DropTarget targetKey={targetKey} onHit={onDropAbove}/>
            </div>

            <div className="drop-target-holder">
                {
                    !editMode &&
                    <DragDropContainer targetKey={targetKey}
                                       className="task-drag-container"
                                       dragHandleClassName="drag-handle"
                                       dragData={{taskId: task.id}}
                                       onDragStart={startDragging}
                                       onDragEnd={() => setDragging(false)}
                                       noDragging={!canWrite}>
                        <DropTarget targetKey={targetKey} onHit={onDropAbove}>
                            <ShowTask isSignedIn={isSignedIn}
                                      task={task} assignedGoal={assignedGoal}
                                      editingActions={editingActions}
                                      handleRemove={handleRemove}
                                      moveToTop={moveToTop}
                                      moveToBottom={moveToBottom}
                                      changeTaskStatus={changeTaskStatus}
                                      selectableBoards={selectableBoards}
                                      lastState={lastState}
                                      moveTasks={moveTasks}/>
                        </DropTarget>
                    </DragDropContainer>
                }
                {showForm(editMode)}
            </div>

            <div className="drop-target-holder">
                <DropTarget targetKey={targetKey} onHit={onDropAbove}/>
            </div>
        </div>
    )
};

interface ShowTaskProps {
    isSignedIn: boolean,
    task: Task,
    assignedGoal: Goal | undefined,
    editingActions: TaskEditDisplayActionProps,
    handleRemove: (e: React.MouseEvent) => void,
    moveToTop: ((taskId: string) => void) | undefined,
    moveToBottom: ((taskId: string) => void) | undefined,
    changeTaskStatus: ChangeTaskStatusHandler,
    selectableBoards: Board[],
    moveTasks: MoveTaskHandler,
    lastState: string
}


const ShowTask: React.FC<ShowTaskProps> = ({
                                               isSignedIn, task, assignedGoal, editingActions, handleRemove, moveToTop, moveToBottom,
                                               changeTaskStatus, selectableBoards, moveTasks, lastState
                                           }) => {

    const canWrite = new Selector(useContext).checkBoardWriteAccess();

    if (task.status === lastState) {
        return (
            <div className={`slate-background-transparent hover-support task`}>
                <div className="drag-handle">
                    <span style={{textDecoration: 'line-through'}}>{task.taskName}</span>

                    <ConfirmAction confirmButtonText="Delete Task"
                                   confirmMessage={
                                       <span>Are you sure you want to delete the task <br/><b>"{task.taskName}"?</b></span>}
                                   onClick={handleRemove}
                                   disabled={!canWrite}>
                        <Button variant="link" disabled={!canWrite}><FontAwesome name="trash-alt"/></Button>
                    </ConfirmAction>

                    {isSignedIn && <Button variant="link" className={`${task.noOfComments > 0 ? 'comments-exist' : ''}`}
                                           onClick={editingActions.startWritingComments}><FontAwesome
                        name="comment-dots"/> ({task.noOfComments})</Button>}

                    <TaskAction task={task} selectableBoards={selectableBoards}
                                changeTaskStatus={changeTaskStatus} moveToTop={moveToTop} moveToBottom={moveToBottom}
                                moveTasks={moveTasks}
                                disabled={!canWrite}/>
                </div>
            </div>
        )
    }

    const displayedTaskLink = (task.link?.length || 0) > 35 ? `${task.link?.substring(0, 35)}...` : task.link;
    const isOverdue = () => !!task.dueDate && task.dueDate.unix() < dayjs().unix();

    return (
        <div className={`slate-background-transparent hover-support task ${isOverdue() ? 'overdue' : ''}`}>
            <div className="drag-handle">
                <span>{task.taskName}</span>
                {task.dueDate &&
                <>
                    <br/>
                    <span className={`due-date ${isOverdue() ? 'overdue' : ''}`}>
                        {`Due: ${formatDateToDateTimeString(task.dueDate)}`}
                    </span>
                </>}
                {assignedGoal &&
                <>
                    <br/>
                    <span className="task-goal-display">
                        <GoalDisplay goal={assignedGoal}/>
                    </span>
                </>}
                {task.link &&
                <>
                    <br/>
                    <a href={task.link} target="_blank" rel="noopener noreferrer"><FontAwesome
                        name="link"/> {displayedTaskLink}</a>
                </>}

                <ShowFeature supportedFeatureLevels={["PREMIUM", "WORKFORCE"]}>
                    <SelectedAssignee selectedAssignee={task.assigneeUserId}/>
                </ShowFeature>
            </div>
            <Button variant="link" onClick={editingActions.startEditing} disabled={!canWrite}><FontAwesome name="edit"/></Button>
            <ConfirmAction confirmButtonText="Delete Task"
                           confirmMessage={<span>Are you sure you want to delete the task <br/><b>"{task.taskName}"?</b></span>}
                           onClick={handleRemove}
                           disabled={!canWrite}>
                <Button variant="link" disabled={!canWrite}><FontAwesome name="trash-alt"/></Button>
            </ConfirmAction>

            {isSignedIn && <Button variant="link" disabled={!canWrite}>
                <FontAwesome name="sync-alt"
                             style={task.occurrence ? {color: 'white'} : undefined}
                             onClick={editingActions.startRecurringTaskSetup}/>
            </Button>}

            {isSignedIn &&
            <Button variant="link" className={`button-with-numbers ${task.noOfFileUploads > 0 ? 'comments-exist' : ''}`}
                    onClick={editingActions.startFileUpload}
                    disabled={!canWrite && task.noOfFileUploads === 0}>
                <FontAwesome name="file-upload"/> ({task.noOfFileUploads})
            </Button>}

            {isSignedIn &&
            <Button variant="link" className={`button-with-numbers ${task.noOfComments > 0 ? 'comments-exist' : ''}`}
                    onClick={editingActions.startWritingComments}
                    disabled={!canWrite && task.noOfComments === 0}>
                <FontAwesome name="comment-dots"/> ({task.noOfComments})</Button>}

            <TaskAction task={task}
                        selectableBoards={selectableBoards}
                        changeTaskStatus={changeTaskStatus}
                        moveToTop={moveToTop}
                        moveToBottom={moveToBottom}
                        moveTasks={moveTasks}
                        disabled={!canWrite}/>
        </div>
    )
};

export default TasksBoard;