import React, {useCallback, useContext, useEffect, useState} from "react";
import {store} from "../../AppState";
import {Button, ButtonGroup, Dropdown, Form, Modal} from "react-bootstrap";
import LoadingComponent from "../../component/loadingComponent/LoadingComponent";
import {SocialService} from "../../service/SocialService";
import {Organisation} from "../../model/Organisation";
import FeatureNotAvailable from "../../component/featureNotAvailable/FeatureNotAvailable";
import MultipleShareWithFriendChooser
    from "../planPage/components/sharedWithFriendChooser/MultipleShareWithFriendChooser";
import teams from '../../assets/img/v3-teams.gif'
import {DispatcherCreator} from "../../state/dispatcherCreator";
import './TeamOrganisationPanel.css'

const TeamOrganisationPanel: React.FC = () => {
    const {state, dispatch} = useContext(store);
    const dispatcherCreator = useCallback(() => new DispatcherCreator(dispatch), [dispatch]);

    const showOrganisationPanel = state.showOrganisationPanel;
    const successfulLoginId: string | undefined = state.lastSuccessfulLogin?.userId;
    const organisationsSupported = state.features?.features.organisations || false;

    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [selectedOrganisation, setSelectedOrganisation] = useState<Organisation | undefined>(undefined);
    const [organisations, setOrganisations] = useState<Organisation[]>(state.organisations || []);

    const isLoading = !state.features || (!state.organisations && organisationsSupported);

    const handleClosePanel = () => {
        setOrganisations(state.organisations || []);
        setSelectedOrganisation(undefined);
        dispatcherCreator().setShowOrganisationPanelShown(false);
    };

    const handleAddNewOrganisation = () => {
        const newOrganisations = [...organisations];
        const organisation: Organisation = {
            name: `Team #${organisations.length + 1}`,
            index: organisations.length,
            adminUserIds: successfulLoginId ? [successfulLoginId] : [],
            userIds: [],
            canEdit: true
        };

        newOrganisations.push(organisation);
        setOrganisations(newOrganisations);
        setSelectedOrganisation(organisation);
    };

    const handleSelectOrganisation = (organisation: Organisation) => {
        setSelectedOrganisation(organisation);
    };

    const handleSetOrganisation = (organisation: Organisation) => {
        const newOrganisations = organisations.map((it: Organisation) => it.index === organisation.index ? organisation : it);
        setSelectedOrganisation(organisation);
        setOrganisations(newOrganisations);
    };

    const handleRemoveOrganisation = (organisation: Organisation) => {
        const newOrganisations = organisations.filter((it: Organisation) => it.index !== organisation.index);
        setSelectedOrganisation(undefined);
        setOrganisations(newOrganisations);
    };

    const saveOrganisations = () => {
        if (successfulLoginId) {
            setIsSaving(true);
            new SocialService().saveOrganisation(successfulLoginId, organisations).then(dispatcherCreator().setOrganisations)
                .finally(() => {
                    setIsSaving(false);
                    dispatcherCreator().setShowOrganisationPanelShown(false);
                });
        }
    };

    const isDirty = !!state.organisations && state.organisations !== organisations;

    useEffect(() => {
        if (state.organisations) {
            setOrganisations(state.organisations);
            setSelectedOrganisation(undefined);
        }
    }, [state.organisations]);

    return (
        <Modal className="organisation-modal" show={showOrganisationPanel} animation={false} onHide={handleClosePanel}>
            <Modal.Header>
                <Modal.Title>Teams</Modal.Title>
                <Button variant="link" onClick={handleClosePanel}>X</Button>
            </Modal.Header>
            <Modal.Body>
                {
                    (isLoading) &&
                    <div className="automation-is-loading">
                        <LoadingComponent isBusy={true} size={"2x"}/>
                    </div>
                }
                {
                    (!!state.features && !organisationsSupported) &&
                    <div>
                        <FeatureNotAvailable supportedFeatureLevels={["WORKFORCE"]}
                                             displayText={
                                                 <>
                                                     <span>Creating <b>'Teams'</b> is a Workforce feature.</span><br/>
                                                     <span>This allows you to organise your friends and fellow members into teams when sharing boards.</span><br/>
                                                     <span>Click here to review all features and subscription settings.</span>
                                                     <br/>
                                                     <br/>
                                                     <div className="teams-images-holder" style={{textAlign: 'center'}}>
                                                         <img src={teams} alt="Teams"/>
                                                     </div>
                                                 </>}/>
                    </div>
                }
                {
                    (!isLoading && organisationsSupported) &&
                    <div>
                        <OrganisationSelection organisations={organisations}
                                               selectedOrganisation={selectedOrganisation}
                                               handleSelectOrganisation={handleSelectOrganisation}
                                               handleAddNewOrganisation={handleAddNewOrganisation}/>
                    </div>
                }
                {
                    (!isLoading && organisationsSupported && selectedOrganisation) &&
                    <>
                        <OrganisationForm organisation={selectedOrganisation}
                                          setOrganisation={handleSetOrganisation}
                                          removeOrganisation={handleRemoveOrganisation}/>

                        <hr style={{marginTop: '0.2rem', marginBottom: '0.2rem'}}/>
                    </>
                }

                <div style={{textAlign: 'center', paddingTop: '1rem'}}>
                    <ButtonGroup>
                        <Button variant="dark" onClick={saveOrganisations}
                                className={isDirty ? "dirty-button" : ""}
                                disabled={isSaving || !organisationsSupported || !isDirty}>
                            Save <LoadingComponent isBusy={isSaving}/>
                        </Button>
                        <Button variant="secondary" onClick={handleClosePanel} disabled={isSaving}>
                            Cancel
                        </Button>
                    </ButtonGroup>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export interface OrganisationSelectionProps {
    organisations: Organisation[],
    selectedOrganisation: Organisation | undefined,
    handleSelectOrganisation: (organisation: Organisation) => void,
    handleAddNewOrganisation: () => void
}

const OrganisationSelection: React.FC<OrganisationSelectionProps> = ({organisations, selectedOrganisation, handleSelectOrganisation, handleAddNewOrganisation}) => {
    return (
        <Dropdown>
            <Dropdown.Toggle className="form-control"
                             style={{backgroundColor: 'grey'}}
                             id="dropdown-boards">
                {selectedOrganisation?.name}
            </Dropdown.Toggle>
            <Dropdown.Menu>
                {
                    organisations.map((it, index) =>
                        <Dropdown.Item key={index} onClick={() => handleSelectOrganisation(it)}>
                            {it.name}
                        </Dropdown.Item>)
                }

                <hr style={{marginTop: '0.2rem', marginBottom: '0.2rem'}}/>

                <Dropdown.Item onClick={handleAddNewOrganisation}>
                    Create New Team
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    )
};

export interface OrganisationFormProps {
    organisation: Organisation,
    setOrganisation: (organisation: Organisation) => void
    removeOrganisation: (organisation: Organisation) => void
}

const OrganisationForm: React.FC<OrganisationFormProps> = ({organisation, setOrganisation, removeOrganisation}) => {
    const setOrganisationName = (name: string) => setOrganisation({...organisation, name: name});
    const setAdmins = (adminUserIds: string[]) => setOrganisation({...organisation, adminUserIds: adminUserIds});
    const setUsers = (userIds: string[]) => setOrganisation({...organisation, userIds: userIds});

    return (
        <>
            <hr style={{marginTop: '1rem', marginBottom: '1rem'}}/>

            <Form.Group className="form">
                <Form.Label>Team Name</Form.Label>
                <Form.Control type="text"
                              disabled={!organisation.canEdit}
                              value={organisation.name}
                              onChange={(e: any) => setOrganisationName((e as any).target.value)}/>
            </Form.Group>

            <Form.Group className="form">
                <Form.Label>Administrators</Form.Label>
                <MultipleShareWithFriendChooser selectedUsers={organisation.adminUserIds}
                                                setSelectedUsers={setAdmins}
                                                disabled={!organisation.canEdit}
                                                allowSelectingMyself={true}/>
            </Form.Group>

            <Form.Group className="form">
                <Form.Label>Members</Form.Label>
                <MultipleShareWithFriendChooser selectedUsers={organisation.userIds}
                                                setSelectedUsers={setUsers}
                                                disabled={!organisation.canEdit}
                                                allowSelectingMyself={true}/>
            </Form.Group>

            <div className="remove-button-holder">
                <Button variant="danger" size="sm"
                        disabled={!organisation.canEdit}
                        onClick={() => removeOrganisation(organisation)}>
                    Remove Team
                </Button>
            </div>
        </>
    )
};

export default TeamOrganisationPanel;