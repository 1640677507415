import React from 'react';
import {BrowserRouter, Route} from "react-router-dom";
import {Routes} from "./util/Properties";
import LandingPage from "./features/landingPage/LandingPage";
import PlanPage from "./features/planPage/PlanPageContainer";
import {StateProvider} from "./AppState";
import rotateImg from './assets/img/rotate_phone.png';
import AppSecurityProvider from "./AppSecurityProvider";
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'fast-text-encoding/text';
import './App.css';
import AccountPage from "./features/accountPage/AccountPage";

const StateFulApp: React.FC = () => {
    return (
        <StateProvider>
            <AppSecurityProvider>
                <App/>
            </AppSecurityProvider>
        </StateProvider>
    )
};

const App: React.FC = () => (
    <>
        <div className="landscape-mode-warning">
            <img src={rotateImg} id="rotateImg" alt="rotate-screen"/>
        </div>


        <BrowserRouter>
            <Route exact path={Routes.BASE_PATH} render={() => (
                <LandingPage/>
            )}/>
            <Route exact path={Routes.PLAN_PATH} render={() => (
                <PlanPage/>
            )}/>
            <Route exact path={Routes.PRICING_PATH} render={() => (
                <AccountPage/>
            )}/>
        </BrowserRouter>
    </>
);

export default StateFulApp;
