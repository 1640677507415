import React, {MouseEventHandler, PropsWithChildren} from "react";
import {NOOP} from "../../../../../util/Functions";

interface SideBarSelectionProps {
    onClick?: MouseEventHandler<any>
    className?: string,
    disabled?: boolean
}

export const SideBarSelection: React.FC<SideBarSelectionProps> = ({onClick = NOOP, className = "", children, disabled = false}: PropsWithChildren<SideBarSelectionProps>) => {
    return (
        <div className={`side-bar-selection ${className} ${disabled ? 'disabled' : ''}`}
             onClick={disabled ? NOOP : onClick}>
            <div className="inner">
                {children}
            </div>
        </div>
    )
};