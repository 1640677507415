import {FriendsWithPendingInvites} from "../../../../../model/Friend";
import {Board, BoardSettings, DEFAULT_BOARD_SETTINGS, PermissionLevel} from "../../../../../model/Board";
import React, {ChangeEvent, MouseEvent, useCallback, useContext, useEffect, useState} from "react";
import {store} from "../../../../../AppState";
import {Button, ButtonGroup, Col, Dropdown, Form, FormControl, InputGroup, ProgressBar} from "react-bootstrap";
import {ColourChooser} from "../../../../../component/colourChooser/ColourChooser";
import FontAwesome from "react-fontawesome";
import LoadingComponent from "../../../../../component/loadingComponent/LoadingComponent";
import FeatureNotAvailable from "../../../../../component/featureNotAvailable/FeatureNotAvailable";
import MultipleShareWithFriendChooser from "../../sharedWithFriendChooser/MultipleShareWithFriendChooser";
import ConfirmAction from "../../../../../component/confirmAction/ConfirmAction";
import {Organisation} from "../../../../../model/Organisation";
import {CopyToClipboard} from 'react-copy-to-clipboard';
// @ts-ignore
import byteSize from "byte-size";
import {NOOP} from "../../../../../util/Functions";
import {Selector} from "../../../../../state/selector";

interface BoardsPanelProps {
    boards: Board[];
    sharedBoards: Board[];
    selectedBoard?: Board;
    saveBoard: (boardsToSave: Board[], selectedBoard?: Board) => Promise<void>,
    friendResponse: FriendsWithPendingInvites,
    onClose: () => void,
    setShowOrganisationPanel: (value: boolean) => void,
    setAutomationPanelShown: (value: boolean) => void
}

export const BoardsPanel: React.FC<BoardsPanelProps> = ({
                                                            boards, sharedBoards, selectedBoard, saveBoard, onClose,
                                                            setShowOrganisationPanel, setAutomationPanelShown
                                                        }) => {
    const {state} = useContext(store);
    const canWrite = new Selector(useContext).checkBoardWriteAccess();

    const [currentBoards, setCurrentBoards] = useState(boards);
    const [currentSelectedBoard, setCurrentSelectedBoard] = useState(selectedBoard);

    const boardName: string = selectedBoard?.boardName || "";
    const boardColour: string | undefined = selectedBoard?.colour;

    const [selectedBoardName, setSelectedBoardName] = useState<string>(boardName);
    const [selectedBoardColour, setSelectedBoardColour] = useState<string | undefined>(boardColour);

    const [isEditingBoardName, setIsEditingBoardName] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [isSwitchingBoard, setIsSwitchingBoard] = useState(false);

    const [selectedSharedWithUsers, setSelectedSharedWithUsers] = useState<string[]>(currentSelectedBoard?.sharedWithUsers || []);
    const [shareWithTeamId, setSharedWithTeamId] = useState<string | undefined>(currentSelectedBoard?.sharedWithTeam);
    const [selectedBoardSettings, setSelectedBoardSettings] = useState<BoardSettings>(currentSelectedBoard?.settings || DEFAULT_BOARD_SETTINGS);

    const createdByMe: boolean = currentSelectedBoard?.creatorUserId === state.lastSuccessfulLogin?.userId;
    const noOfSharedBoardUsers = state.features?.features?.noOfSharedBoardUsers || 0;
    const sharedBoardToEveryone = state.features?.features.canShareBoardToEveryone || false;

    const exceedSharedUsers = selectedSharedWithUsers.length > noOfSharedBoardUsers;
    const unableToShareToEveryone = !sharedBoardToEveryone && (selectedBoardSettings.writePermissionAccess === "EVERYONE" || selectedBoardSettings.readPermissionAccess === "EVERYONE");

    const handleReload = useCallback(() => {
        setCurrentBoards(boards);
        setSelectedBoardName(boardName);
        setSelectedBoardColour(boardColour);

        if (!!selectedBoard) {
            setCurrentSelectedBoard(selectedBoard);
            setSelectedSharedWithUsers(selectedBoard?.sharedWithUsers || []);
            setSelectedBoardSettings(selectedBoard?.settings || DEFAULT_BOARD_SETTINGS);
        }
    }, [boards, selectedBoard, boardName, boardColour]);

    useEffect(() => {
        handleReload();
    }, [handleReload, boards, selectedBoard]);

    const startEditingBoardName = (e: MouseEvent<any>) => {
        setIsEditingBoardName(true);
        document.getElementById("editBoardName")?.focus();
        e.stopPropagation();
    };

    const handleAddNewBoard = () => {
        const nextIndex = currentBoards.length + 1;
        const newBoard: Board = {
            id: "N/A",
            boardName: `My Personal Board #${nextIndex}`,
            colour: '#000000',
            defaultBoard: false,
            creatorUserId: state.lastSuccessfulLogin?.userId,
            fileUploadedBytes: 0
        };

        setSelectedBoardName(newBoard.boardName);
        setSelectedBoardColour(undefined);
        setCurrentSelectedBoard(newBoard);
        setCurrentBoards([...currentBoards, newBoard]);
        setSelectedBoardSettings(DEFAULT_BOARD_SETTINGS);
        setIsEditingBoardName(true);
    };

    const handleSelectedBoard = (board: Board) => {
        setIsSwitchingBoard(true);
        saveBoard(currentBoards, board)
            .then(onClose)
            .finally(() => setIsSwitchingBoard(false));
    };

    const handleOnKeyPress = (event: React.KeyboardEvent<HTMLElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleSave();
        }
    };

    const handleSave = async () => {
        const newBoards: Board[] = currentBoards.map((it: Board) => {
            return it.id === currentSelectedBoard?.id
                ? ({
                    ...it,
                    boardName: selectedBoardName,
                    colour: selectedBoardColour || it.colour,
                    sharedWithUsers: selectedSharedWithUsers,
                    sharedWithTeam: shareWithTeamId,
                    settings: selectedBoardSettings
                })
                : it;
        });

        setIsSaving(true);
        await saveBoard(newBoards, selectedBoard).finally(() => setIsSaving(false));
        setIsEditingBoardName(false);
    };

    const handleCancel = () => {
        setIsEditingBoardName(false);
        handleReload();
    };

    const handleDelete = async () => {
        const newBoards: Board[] = currentBoards.filter(it => it.id !== currentSelectedBoard?.id);
        setIsSaving(true);
        await saveBoard(newBoards, currentBoards[0]).finally(() => setIsSaving(false));
    };

    const handleSetUpAutomation = () => {
        setAutomationPanelShown(true);
        onClose();
    };

    const disableDeleteBoard = isSaving || currentSelectedBoard?.defaultBoard;

    const currentBoardsNotShared = currentBoards.filter(it => !it.sharedWithUsers?.length);
    const currentBoardsSharedWithOthers = currentBoards.filter(it => it.sharedWithUsers?.length);

    const isSharingUsers = selectedBoardSettings?.readPermissionAccess === "SHARED_USERS"
        || selectedBoardSettings?.writePermissionAccess === "SHARED_USERS";

    return (
        <>
            <div className="side-bar-boards slate-background">
                <div className="side-bar-title">
                    <b>Boards</b>
                </div>
                <div className="boards-chooser">
                    {isEditingBoardName &&
                    <Form>
                        <Form.Row>
                            <Col xs={7} sm={8}>
                                <FormControl type="text"
                                             id="editBoardName"
                                             value={selectedBoardName}
                                             onChange={(e: any) => setSelectedBoardName((e as any).target.value)}
                                             onKeyPress={handleOnKeyPress}/>
                            </Col>
                            <Col xs={2} sm={2}>
                                <ColourChooser selectedColour={selectedBoardColour}
                                               setColour={(it) => setSelectedBoardColour(it)}/>
                            </Col>
                            <Col xs={3} sm={2}>
                                <ButtonGroup>
                                    <Button variant="dark" onClick={handleSave}
                                            disabled={isSaving || exceedSharedUsers || unableToShareToEveryone}>
                                        {!isSaving && <FontAwesome name="check"/>} <LoadingComponent isBusy={isSaving}/>
                                    </Button>
                                    <Button variant="dark" onClick={handleCancel} disabled={isSaving}>
                                        <FontAwesome name="times"/>
                                    </Button>
                                </ButtonGroup>
                            </Col>
                        </Form.Row>
                        {
                            exceedSharedUsers && <Form.Row>
                                <Col xs={true}>
                                    <FeatureNotAvailable supportedFeatureLevels={["WORKFORCE"]}
                                                         displayText={<span>You've exceeded the max shared users.
                                                             <br/>You can upgrade this limit by upgrading your account.</span>}/>
                                </Col>
                            </Form.Row>
                        }
                        {
                            createdByMe && <Form.Row>
                                <Col>
                                    <div style={{width: '100%', paddingTop: '0.2rem', paddingBottom: '0.2rem'}}>
                                        <Form.Label>Share Board with Others</Form.Label>

                                        <MultipleShareWithFriendChooser selectedUsers={selectedSharedWithUsers}
                                                                        setSelectedUsers={setSelectedSharedWithUsers}
                                                                        allowSelectingMyself={false}
                                                                        disabled={!isSharingUsers}/>

                                        <TeamSelect sharedWithTeamId={shareWithTeamId}
                                                    setSharedWithTeamId={setSharedWithTeamId}
                                                    setShowOrganisationPanel={setShowOrganisationPanel}
                                                    disabled={!isSharingUsers}/>
                                    </div>
                                </Col>
                            </Form.Row>
                        }
                        {
                            createdByMe && <Form.Row>
                                <Col>
                                    <div style={{width: '100%', paddingTop: '0.2rem', paddingBottom: '0.2rem'}}>
                                        <Form.Label>Settings</Form.Label>

                                        <BoardSettingSelection settings={selectedBoardSettings}
                                                               setSettings={setSelectedBoardSettings}/>
                                    </div>
                                </Col>
                            </Form.Row>
                        }
                        {
                            (createdByMe && unableToShareToEveryone) && <Form.Row>
                                <Col xs={true}>
                                    <FeatureNotAvailable supportedFeatureLevels={["PREMIUM", "WORKFORCE"]}
                                                         displayText={<span>Unable to share this board to everyone.
                                                             <br/>You can access this feature by upgrading your account.</span>}/>
                                </Col>
                            </Form.Row>
                        }
                        <Form.Row>
                            <Col xs={true}>
                                <div style={{width: '100%', paddingTop: '0.2rem', paddingBottom: '0.2rem'}}>
                                    <Button variant="dark" disabled={!canWrite} block onClick={handleSetUpAutomation}>
                                        Set Up Automation Rules
                                    </Button>
                                </div>
                            </Col>
                        </Form.Row>
                    </Form>}

                    {!isEditingBoardName && <Form>
                        <Form.Row>
                            <Col xs={9} sm={10}>
                                <Dropdown>
                                    <Dropdown.Toggle className="form-control"
                                                     style={{backgroundColor: 'grey'}}
                                                     id="dropdown-boards">
                                        <FontAwesome name="square-full"
                                                     style={{color: selectedBoardColour}}/> {`${selectedBoardName} `}
                                        <LoadingComponent isBusy={isSwitchingBoard}/>
                                    </Dropdown.Toggle>
                                    <UploadBoardLimit/>
                                    <Dropdown.Menu>

                                        {/*CURRENT BOARDS*/}
                                        {!!currentBoardsNotShared.length &&
                                        <Dropdown.Header>My Boards</Dropdown.Header>}
                                        {
                                            currentBoardsNotShared.map((it: Board, index: number) =>
                                                <Dropdown.Item key={index} onClick={() => handleSelectedBoard(it)}>
                                                    <FontAwesome name="square-full"
                                                                 style={{color: it.colour}}/> {it.boardName}
                                                </Dropdown.Item>)
                                        }
                                        <hr style={{marginTop: '0.2rem', marginBottom: '0.2rem'}}/>

                                        {/*SHARED WITH OTHERS*/}
                                        {!!currentBoardsSharedWithOthers.length &&
                                        <Dropdown.Header>Shared with others</Dropdown.Header>}
                                        {
                                            currentBoardsSharedWithOthers.map((it: Board, index: number) =>
                                                <Dropdown.Item key={index} onClick={() => handleSelectedBoard(it)}>
                                                    <FontAwesome name="square-full"
                                                                 style={{color: it.colour}}/> {it.boardName}
                                                </Dropdown.Item>)
                                        }

                                        {!!currentBoardsSharedWithOthers.length &&
                                        <hr style={{marginTop: '0.2rem', marginBottom: '0.2rem'}}/>}

                                        {/*SHARED BOARDS*/}
                                        {!!sharedBoards.length && <Dropdown.Header>Shared Boards</Dropdown.Header>}
                                        {
                                            sharedBoards.map((it: Board, index: number) =>
                                                <Dropdown.Item key={index} onClick={() => handleSelectedBoard(it)}>
                                                    <FontAwesome name="square-full"
                                                                 style={{color: it.colour}}/> {it.boardName}
                                                </Dropdown.Item>)
                                        }

                                        {!!sharedBoards.length &&
                                        <hr style={{marginTop: '0.2rem', marginBottom: '0.2rem'}}/>}


                                        <Dropdown.Item onClick={handleAddNewBoard}>
                                            Create New Board
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Col>
                            <Col xs={3} sm={2}>
                                <ButtonGroup>
                                    <Button variant="dark" onClick={startEditingBoardName}
                                            disabled={isSaving}>
                                        <FontAwesome name="edit"/>
                                    </Button>

                                    <ConfirmAction confirmButtonText={"Delete"}
                                                   confirmMessage={
                                                       <span>Are you sure you want to delete this board? <br/>
                                                       All tasks related to this board will move back to the default board.</span>
                                                   }
                                                   onClick={handleDelete}
                                                   disabled={disableDeleteBoard}>
                                        <Button variant="dark" disabled={disableDeleteBoard}>
                                            {!isSaving && <FontAwesome name="trash-alt"/>}
                                            <LoadingComponent isBusy={isSaving}/>
                                        </Button>
                                    </ConfirmAction>
                                </ButtonGroup>
                            </Col>
                        </Form.Row>
                    </Form>}
                </div>
            </div>
        </>
    )
};

const UploadBoardLimit: React.FC = () => {
    const {state} = useContext(store);
    const selectedBoard = state.selectedBoard;
    const boardByteLimit = selectedBoard?.boardByteLimit;

    if (!boardByteLimit || !selectedBoard) {
        return <></>
    }

    const fileUploadedHumanReadable = byteSize(selectedBoard.fileUploadedBytes, {units: 'iec', precision: 2});
    const boardLimitHumanReadable = byteSize(boardByteLimit, {units: 'iec', precision: 2});
    const label = `Uploaded ${fileUploadedHumanReadable} / ${boardLimitHumanReadable}`;

    return (<div>
        <span className="progress-bar-label">{label}</span>
        <ProgressBar now={(selectedBoard.fileUploadedBytes / boardByteLimit) * 100}/>
    </div>);
};

interface TeamSelectProps {
    sharedWithTeamId: string | undefined;
    setSharedWithTeamId: (id: string | undefined) => void;
    setShowOrganisationPanel: (value: boolean) => void;
    disabled: boolean
}

const TeamSelect: React.FC<TeamSelectProps> = ({sharedWithTeamId, setSharedWithTeamId, setShowOrganisationPanel, disabled}) => {
    const {state} = useContext(store);
    const organisations: Organisation[] | undefined = state.organisations;
    if (!organisations) {
        return <></>
    }

    const selectedOrganisation: Organisation | undefined = organisations.find(it => it.id === sharedWithTeamId);
    const organisationsFeatures = state.features?.features.organisations;


    const isSelected = (id: string | undefined): boolean => sharedWithTeamId === id;
    const selectTeam = (id: string | undefined) => {
        if (!isSelected(id)) {
            setSharedWithTeamId(id);
        } else {
            setSharedWithTeamId(undefined);
        }
    };

    return (
        <Dropdown>
            <Dropdown.Toggle className="form-control"
                             style={{backgroundColor: 'white', color: 'black'}}
                             id="dropdown-boards"
                             disabled={disabled}>
                {
                    selectedOrganisation?.name
                }
            </Dropdown.Toggle>
            <Dropdown.Menu>
                <Dropdown.Item disabled={!organisationsFeatures}
                               onClick={() => setSharedWithTeamId(undefined)}>
                    Clear
                </Dropdown.Item>
                {
                    organisations.map((it: Organisation, index) =>
                        <Dropdown.Item key={index}
                                       disabled={!organisationsFeatures}
                                       onClick={() => selectTeam(it.id)}
                                       style={isSelected(it.id) ? {backgroundColor: 'lightgray'} : {}}>
                            {it.name}
                        </Dropdown.Item>)
                }
                <hr style={{marginTop: '0.2rem', marginBottom: '0.2rem'}}/>
                <Dropdown.Item onClick={() => setShowOrganisationPanel(true)}>
                    Manage Teams
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
};

interface BoardSettingsSelectionProps {
    settings: BoardSettings;
    setSettings: (settings: BoardSettings) => void
}

interface Option {
    level: number;
    display: string;
    value: PermissionLevel
}

const BoardSettingSelection: React.FC<BoardSettingsSelectionProps> = ({settings, setSettings}) => {
    const {state} = useContext(store);
    const sharedBoardToEveryone = state.features?.features.canShareBoardToEveryone || false;

    const calcNewWritePermission = (permissionLevel: PermissionLevel) => {
        const currentWriteLevel = options.find(it => it.value === settings.writePermissionAccess)?.level || 1;
        const newReadLevel = options.find(it => it.value === permissionLevel)?.level || 1;

        return (currentWriteLevel > newReadLevel)
            ? options.find((it: Option) => it.level === newReadLevel)?.value
            : undefined;
    };

    const calcNewReadPermission = (permissionLevel: PermissionLevel) => {
        const currentReadLevel = options.find(it => it.value === settings.readPermissionAccess)?.level || 1;
        const newWriteLevel = options.find(it => it.value === permissionLevel)?.level || 1;

        return (currentReadLevel < newWriteLevel)
            ? options.find((it: Option) => it.level === newWriteLevel)?.value
            : undefined;
    };

    const setReadPermission = (permissionLevel: PermissionLevel) => {
        const newWritePermission = calcNewWritePermission(permissionLevel);

        setSettings({
            ...settings,
            readPermissionAccess: permissionLevel,
            writePermissionAccess: newWritePermission || settings.writePermissionAccess
        })
    };

    const setWritePermission = (permissionLevel: PermissionLevel) => {
        const newReadPermission = calcNewReadPermission(permissionLevel);

        setSettings({
            ...settings,
            writePermissionAccess: permissionLevel,
            readPermissionAccess: newReadPermission || settings.readPermissionAccess
        })
    };

    const options: Option[] = [
        {value: "ONLY_ME", display: "Only Me", level: 1},
        {value: "SHARED_USERS", display: "Shared Users", level: 2},
        {value: "EVERYONE", display: "Everyone", level: 3}
    ];

    const isSharedWithEveryone = sharedBoardToEveryone && (settings.readPermissionAccess === "EVERYONE" || settings.writePermissionAccess === "EVERYONE");

    return (
        <div className="dropdown">
            <InputGroup size="sm">
                <InputGroup.Prepend>
                    <InputGroup.Text>Read Access</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl as="select"
                             value={settings.readPermissionAccess}feature-text
                             onChange={((event: ChangeEvent<HTMLSelectElement>) => setReadPermission(event.target.value as PermissionLevel))}>
                    {options.map((it, index: number) => <option key={index} value={it.value}>{it.display}</option>)}
                </FormControl>
            </InputGroup>
            <InputGroup size="sm">
                <InputGroup.Prepend>
                    <InputGroup.Text>Write Access</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl as="select"
                             value={settings.writePermissionAccess}
                             onChange={((event: ChangeEvent<HTMLSelectElement>) => setWritePermission(event.target.value as PermissionLevel))}>
                    {options.map((it, index: number) => <option key={index} value={it.value}>{it.display}</option>)}
                </FormControl>
            </InputGroup>
            {isSharedWithEveryone && <>
                <span className="feature-text">
                    <FontAwesome name="info-circle"/>{" You can share this board simply by sharing this page link. "}
                </span>
                <CopyToClipboard text={window.location.href}>
                    <a className="copy-link" onClick={NOOP}><FontAwesome name="link"/> Copy Link</a>
                </CopyToClipboard>
            </>}
        </div>
    );
};