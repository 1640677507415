import React, {useCallback, useContext} from "react";
import UnsubscribePanel from "./UnsubscribePanel";
import {store} from "../../AppState";
import * as H from "history";
import {useLocation} from "react-router-dom";
import {DispatcherCreator} from "../../state/dispatcherCreator";

const UnsubscribePanelContainer: React.FC = () => {
    const {state, dispatch} = useContext(store);
    const dispatcherCreator = useCallback(() => new DispatcherCreator(dispatch), [dispatch]);

    const userId = state.lastSuccessfulLogin?.userId;
    const userPreferences = state.userPreferences;

    const location: H.Location = useLocation();
    const emailSettings: string | null = new URLSearchParams(location.search).get("emailSettings");

    if (!userId || !emailSettings || !userPreferences) {
        return null;
    }

    return <UnsubscribePanel userId={userId}
                             userPreferences={userPreferences}
                             updateUserPreference={dispatcherCreator().setUserPreferences}/>
};

export default UnsubscribePanelContainer;