import React from "react";
import {Goal} from "../../../../../model/Goal";
import FontAwesome from "react-fontawesome";

const GoalDisplay: React.FC<{ goal: Goal | undefined, maxCharacters?: number }> = ({goal, maxCharacters = 25}) => {
    return (
        <>
            <FontAwesome name="square-full" style={{color: goal?.colour}}/> <TruncateGoalName goal={goal}
                                                                                              maxCharacters={maxCharacters}/>
        </>
    )
};

const TruncateGoalName: React.FC<{ goal: Goal | undefined, maxCharacters: number }> = ({goal, maxCharacters}) => {
    return (goal?.goalName || "").length > maxCharacters
        ? <>{goal?.goalName.substring(0, maxCharacters)}...</>
        : <>{goal?.goalName}</>
};

export default GoalDisplay;