import {ItemType} from "../model/Comment";

export class Routes {
    public static readonly BASE_PATH = "/";
    public static readonly PLAN_PATH  = "/plan";
    public static readonly PLAN_PATH_FOR_PATH = (userId: string, boardId: string) => `/plan?k=${userId}&b=${boardId}`;
    public static readonly PRICING_PATH = "/pricing";
}

export class HttpUrls {
    public static readonly USER = "/user";
    public static readonly USER_ACCOUNT_SIGN_UP = "/user/signUp";
    public static readonly USER_ACCOUNT_LOGIN = "/user/login";
    public static readonly USER_ACCOUNT_PASSWORD_RESET = "/user/passwordReset";
    public static readonly USER_ACCOUNT_REFRESH_TOKEN = "/user/refreshToken";
    public static readonly USER_RESET_NEW_PASSWORD = "/user/resetPassword";
    public static readonly USER_CHANGE_PASSWORD = "/user/changePassword";
    public static readonly PAYMENT_SESSION = "/payment/session";

    public static readonly USER_TASKS = "/user/tasks";
    public static readonly USER_GOALS = "/user/goals";
    public static readonly MOVE_TASK = "/user/tasks/move";
    public static readonly MOVE_GOALS = "/user/goals/move";
    public static readonly USER_BOARDS = "/user/boards";
    public static readonly USER_AUTOMATION = "/user/automation";
    public static readonly USER_FRIENDS = "/user/friends";
    public static readonly USER_REQUEST = "/user/request";
    public static readonly USER_PREFERENCES = "/user/preferences";
    public static readonly USER_FEATURES = "/user/features";
    public static readonly USER_ANALYTICS_FEEDBACK = "/feedback";
    public static readonly USER_ANALYTICS_EVENT = "/event";
    public static readonly USER_ORGANISATION = "user/organisation";

    public static readonly USER_COMMENTS = (itemType: ItemType, itemId: string) => `/user/comments/${itemType}/${itemId}`;
    public static readonly USER_COMMENT = (commentId: string) => `user/comment/${commentId}`;

    public static readonly USER_NOTIFICATIONS = (userId: string) => `user/notifications/${userId}`;
    public static readonly USER_NOTIFICATION = `user/notifications`;

    public static readonly FILE_UPLOAD = '/upload';
    public static readonly FILE_UPLOAD_FOR_ITEM = (itemType: ItemType, itemId: string) => `/upload/${itemType}/${itemId}`;
    public static readonly FILE_DOWNLOAD_FOR_ID = (id: string) => `/download/${id}`;

    public static readonly RESOURCES_IOT_GATEWAY = "/resources/iotgateway";
}