import {Login} from "../../../../../model/Account";
import React, {useState} from "react";
import {useHistory} from "react-router-dom";
import {Routes} from "../../../../../util/Properties";
import FontAwesome from "react-fontawesome";
import ChangePasswordPanelContainer from "../../../../changePasswordPanel/ChangePasswordPanelContainer";
import {SideBarSelection} from "../sidebarSelection/SideBarSelection";

export type SettingPanelMode = "CHANGE_PASSWORD"

interface SettingPanelProps {
    successfulLogin: Login | undefined;
    onClose: () => void
}

export const SettingPanel: React.FC<SettingPanelProps> = ({successfulLogin, onClose}) => {
    const history = useHistory();
    const [settingsMode, setSettingsMode] = useState<string | undefined>(undefined);

    const changeSettingsMode = (newSettingsMode: SettingPanelMode) => {
        if (settingsMode === newSettingsMode) {
            setSettingsMode(undefined);
        } else {
            setSettingsMode(newSettingsMode);
        }
    };

    const handleEmailSettingClick = () => {
        history.push(`${Routes.PLAN_PATH}?emailSettings=true`);
        onClose();
    };

    const handleRedirectPayment = async () => {
        history.push(Routes.PRICING_PATH);
        onClose();

        if (!successfulLogin?.userEmail) {
            return;
        }
    };

    const isSignedIn = !!successfulLogin;

    return (
        <>
            <div className="side-bar-settings side-bar slate-background">
                <SideBarSelection onClick={handleRedirectPayment}>
                    <FontAwesome size="2x" name="user"/><br/>
                    Subscription<br/>Settings
                </SideBarSelection>

                <SideBarSelection onClick={handleEmailSettingClick}
                                  disabled={!isSignedIn}>
                    <FontAwesome size="2x" name="envelope"/><br/>
                    Email<br/>Settings
                </SideBarSelection>

                <SideBarSelection disabled={!isSignedIn || successfulLogin?.accountType !== "NORMAL"}
                                  onClick={() => changeSettingsMode("CHANGE_PASSWORD")}>
                    <FontAwesome size="2x" name="key"/><br/>
                    Change<br/>Password
                </SideBarSelection>
            </div>
            {settingsMode === "CHANGE_PASSWORD" &&
            <ChangePasswordPanelContainer closePanel={() => setSettingsMode(undefined)}/>}
        </>
    )
};