import React from "react";
import assignees from "../../../assets/img/v1-assignees.gif";

export const V1: React.FC = () => {
    return <>
        <h5>Assignees</h5>
        <span className="new">NEW!</span> <span>Premium Feature</span>
        <div className="login-text-holder">
            <span>Assign users to be responsible for each task.</span>
        </div>
        <div className="images-holder">
            <img src={assignees} alt="Assignee"/>
        </div>
    </>
};