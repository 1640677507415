import HttpUtilService from "./HttpUtilService";
import {HttpUrls} from "../util/Properties";
import {toUserPreferences, UserPreferences} from "../model/UserPreferences";

export class UserPreferencesService {

    private readonly USER_PREFERENCES_SERVICE_URL: string;
    private readonly httpUtilService: HttpUtilService;

    constructor(USER_PREFERENCES_SERVICE_URL: string | undefined = process.env.REACT_APP_USER_ACCOUNT_BACK_END_API,
                httpUtilService: HttpUtilService = new HttpUtilService()) {

        this.USER_PREFERENCES_SERVICE_URL = USER_PREFERENCES_SERVICE_URL || "";
        this.httpUtilService = httpUtilService;
    }


    public getUserPreferences(userId: string): Promise<UserPreferences> {
        return this.httpUtilService.get(HttpUrls.USER_PREFERENCES, this.USER_PREFERENCES_SERVICE_URL, {userId: userId})
            .then(response => toUserPreferences(response.data));
    }

    public saveUserPreferences(userId: string, userPreferences: UserPreferences): Promise<UserPreferences> {
        return this.httpUtilService.post(HttpUrls.USER_PREFERENCES, this.USER_PREFERENCES_SERVICE_URL,  userPreferences,{userId: userId})
            .then(response => toUserPreferences(response.data));
    }

}