import {Task, TaskStatus} from "../../../../../model/Task";
import {Board, DEFAULT_COLUMNS} from "../../../../../model/Board";
import {Button, OverlayTrigger, Popover} from "react-bootstrap";
import LoadingComponent from "../../../../../component/loadingComponent/LoadingComponent";
import FontAwesome from "react-fontawesome";
import React, {useContext, useState} from "react";
import {store} from "../../../../../AppState";
import {getColumnHeaderName} from "../TasksBoard";

type MoveTaskHandler = (tasks: Task[], targetBoard: Board) => Promise<void>;
type ChangeTaskStatusHandler = (taskId: string, taskStatus: TaskStatus) => void;

interface TaskActionsProps {
    task: Task,
    moveToTop: ((taskId: string) => void) | undefined,
    moveToBottom: ((taskId: string) => void) | undefined,
    changeTaskStatus: ChangeTaskStatusHandler,
    selectableBoards: Board[],
    moveTasks: MoveTaskHandler,
    disabled?: boolean
}

export const TaskAction: React.FC<TaskActionsProps> = ({task, selectableBoards, changeTaskStatus, moveToTop, moveToBottom, moveTasks, disabled}) => {
    const {state} = useContext(store);
    const columns = state.selectedBoard?.columns || DEFAULT_COLUMNS;

    const [show, setShow] = useState<boolean>(true);
    const [isMovingTask, setIsMovingTask] = useState<boolean>(false);

    const handleMoveToTop = () => {
        setShow(false);
        !!moveToTop && moveToTop(task.id);
    };

    const handleChangeStatus = (status: TaskStatus) => {
        setShow(false);
        changeTaskStatus(task.id, status);
    };


    const handleMoveToBottom = () => {
        setShow(false);
        !!moveToBottom && moveToBottom(task.id);
    };

    const handleMoveTask = (tasks: Task[], board: Board) => {
        setIsMovingTask(true);
        moveTasks(tasks, board).finally(() => {
            setIsMovingTask(false);
            setShow(false);
        });
    };

    return (
        <OverlayTrigger trigger="click" placement="bottom-start" rootClose={true} overlay={
            show ? <Popover id="popover-basic">
                <Popover.Content>
                    <Button variant="link" onClick={handleMoveToTop} disabled={!moveToTop}>
                        Move to Top
                    </Button>
                    <Button variant="link" onClick={handleMoveToBottom} disabled={!moveToBottom}>
                        Move to Bottom
                    </Button>
                    <hr/>
                    {
                        columns.map((column, index) => <Button variant="link"
                                                               key={index}
                                                               onClick={() => handleChangeStatus(column)}
                                                               disabled={task.status === column}>
                            Move to <b>{getColumnHeaderName(column)}</b>
                        </Button>)
                    }
                    {selectableBoards.length && <hr/>}
                    {selectableBoards.map((it, index) => <Button key={index} variant="link"
                                                                 onClick={() => handleMoveTask([task], it)}>Move
                        to <b>{it.boardName}</b> <LoadingComponent isBusy={isMovingTask}/></Button>)}
                </Popover.Content>
            </Popover> : <div/>
        }>
            <Button variant="link" disabled={disabled} onClick={() => setShow(true)}><FontAwesome name="caret-square-down"/></Button>
        </OverlayTrigger>
    );
};