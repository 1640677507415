import dayjs from "dayjs";
import {Goal} from "./Goal";

export type TaskStatus = string;
export type SharedStatus = "PENDING_ACCEPTANCE" | "ACCEPTED";

export type DurationInMillis = number;

export interface Task {
    id: string,
    taskName: string,
    link?: string,
    status: TaskStatus,
    dueDate?: dayjs.Dayjs,
    goalId?: string,
    creatorUserId?: string,
    noOfComments: number,
    noOfFileUploads: number,
    timeSpent?: DurationInMillis,
    occurrence?: Occurrence,
    assigneeUserId?: string;
}

export const toTask = (json: any): Task => {
    const {id, taskName, link, status, dueDate, goalId, creatorUserId, noOfComments, noOfFileUploads, timeSpent, occurrence, assigneeUserId} = json;
    return {
        id,
        taskName,
        link,
        status,
        dueDate: !!dueDate ? dayjs(dueDate) : undefined,
        goalId,
        creatorUserId,
        noOfComments,
        noOfFileUploads,
        timeSpent,
        occurrence,
        assigneeUserId
    }
};

export interface TaskFilter {
    showTaskMatchingGoal?: Goal
}

export const toTaskFilter = (json: any): TaskFilter => {
    const {showTaskMatchingGoal} = json;
    return {
        showTaskMatchingGoal
    }
};


export type Occurrence = WeeklyOccurrence | MonthlyOccurrence | YearlyOccurrence;

export const SUNDAY = 0;
export const MONDAY = 1;
export const TUESDAY = 2;
export const WEDNESDAY = 3;
export const THURSDAY = 4;
export const FRIDAY = 5;
export const SATURDAY = 6;
export type DayOfWeek = 0 | 1 | 2 | 3 | 4 | 5 | 6;

export interface WeeklyOccurrence {
    type: "WEEKLY"
    days: DayOfWeek[]
}

export interface MonthlyOccurrence {
    type: "MONTHLY",
    dayOfMonth: number
}

export interface YearlyOccurrence {
    type: "YEARLY",
    dayOfMonth: number,
    month: number
}
