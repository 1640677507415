export type AccountType = 'NORMAL' | 'FACEBOOK' | 'GOOGLE'

export interface LoginRequest {
    emailAddress: string,
    password: string,
    accountType: AccountType
}

export interface SignUpRequest {
    emailAddress: string,
    firstName: string,
    lastName: string,
    password: string,
    accountType: AccountType
}

export interface RefreshTokenRequest {
    emailAddress: string;
    refreshToken: string;
}

export interface ResetPasswordRequest {
    userId: string;
    resetPasswordToken: string;
    newPassword: string;
}

export interface ChangePasswordRequest {
    emailAddress: string;
    oldPassword: string;
    newPassword: string;
}

export type LoginStatus = 'SUCCESS' | 'FAILURE'

export interface User {
    userId: string;
    displayName: string;
    boardUser?: boolean
}

export const toUser = (json: any): User => {
    const {userId, displayName, boardUser} = json;
    return {
        userId,
        displayName,
        boardUser
    }
};

export interface Login {
    loginStatus: LoginStatus;
    loginMessage: string;
    userId?: string;
    firstName?: string;
    lastName?: string;
    userEmail?: string;
    userDisplayPicture?: string;
    accessToken?: string;
    refreshToken?: string;
    accountType?: AccountType;
    paymentSignedUp?: boolean;
}

export interface BillingSessionReturn {
    url: string;
    paymentSignedUp: boolean;
}

export type FeatureLevel = 'BASIC' | 'PREMIUM' | 'WORKFORCE'

export interface Features {
    features: {
        assignees: boolean,
        offline: boolean,
        noOfSharedBoardUsers: number,
        organisations: boolean
        boardByteLimit: number,
        fileUploadLimit: number,
        canShareBoardToEveryone: boolean,
        noOfAutomatedRules?: number
    };
    level: FeatureLevel
}

export const toLogin = (json: any): Login => {
    const {loginStatus, loginMessage, userId, firstName, lastName, userEmail, userDisplayPicture, accessToken, refreshToken, accountType, paymentSignedUp} = json;
    return {
        loginStatus,
        loginMessage,
        userId,
        firstName,
        lastName,
        userEmail,
        userDisplayPicture,
        accessToken,
        refreshToken,
        accountType,
        paymentSignedUp
    }
};