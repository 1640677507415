import React, {ReactNode, useCallback, useContext, useEffect, useState} from "react";
import {store} from "./AppState";
import {Login} from "./model/Account";
import {getExpirationTime, isTokenExpired} from "./util/TokenUtils";
import UserService from "./service/UserService";
import HttpUtilService from "./service/HttpUtilService";
import dayjs from "dayjs";
import LoadingComponent from "./component/loadingComponent/LoadingComponent";
import {DefaultWebSocket} from "./service/WebSocketClientFactory";
import {DispatcherCreator} from "./state/dispatcherCreator";
import SessionStore from "./util/SessionStore";

const AppSecurityProvider: React.FC<{ children: ReactNode }> = ({children}) => {
    const {state, dispatch} = useContext(store);
    const dispatcherCreator = useCallback(() => new DispatcherCreator(dispatch), [dispatch]);

    const CURRENT_LOCAL_STORAGE_VERSION = '0.9.63';
    const VERSION_STORE = 'version';

    const isLocalStorageVersionIncompatible = (): boolean => {
        const localStoreVersion = SessionStore.getItemOrDefault(VERSION_STORE, '0.0.1');
        return CURRENT_LOCAL_STORAGE_VERSION !== localStoreVersion;
    };

    const clearLocalStorage = useCallback(() => {
        SessionStore.clearAll();
        SessionStore.setItem(VERSION_STORE, CURRENT_LOCAL_STORAGE_VERSION);
        dispatcherCreator().signOut();
    }, [dispatcherCreator]);

    useEffect(() => {
        if (isLocalStorageVersionIncompatible()) {
            console.log('incompatible local storage version');
            clearLocalStorage();
        }
    }, [clearLocalStorage]);

    const [isTokenSet, setIsTokenSet] = useState(false);
    const emailAddress = state.lastSuccessfulLogin?.userEmail;
    const refreshToken = state.lastSuccessfulLogin?.refreshToken;
    const userId = state.lastSuccessfulLogin?.userId;

    const timeoutUntilExpiry = (expiryTime: dayjs.Dayjs | undefined) => {
        if (!!expiryTime) {
            const timeoutInMillis = expiryTime.unix() - dayjs().unix();
            console.log('Token expiry in minutes ', (timeoutInMillis / 60).toFixed(0));
            setTimeout(() => loadToken(), timeoutInMillis * 1000)
        }
    };

    const loadToken = useCallback(() => {
        updateToken(refreshToken, emailAddress, dispatcherCreator().onSignUp)
            .then((expiryTime: dayjs.Dayjs | undefined) => {
                setIsTokenSet(true);
                timeoutUntilExpiry(expiryTime);
            })
            .catch(() => clearLocalStorage());

    }, [emailAddress, refreshToken, dispatcherCreator, clearLocalStorage]);

    useEffect(() => {
        if (!isTokenSet) {
            loadToken();
        }
    }, [loadToken, isTokenSet]);


    useEffect(() => {
        new UserService().getFeatures(userId).then(dispatcherCreator().setFeatures)
    }, [userId, dispatcherCreator]);

    if (!isTokenSet) {
        return <div className="loading-token-overlay main-background">
            <LoadingComponent isBusy={true} size='4x'/>
        </div>
    }

    return <>{children}</>
};

const updateToken = async (refreshToken: string | undefined,
                           emailAddress: string | undefined,
                           refreshLogin: (login: Login) => void): Promise<dayjs.Dayjs | undefined> => {

    if (!!refreshToken && !!emailAddress) {
        const request = {refreshToken: refreshToken, emailAddress: emailAddress};
        return new UserService().refreshToken(request).then(async login => {
            if (login.loginStatus === "SUCCESS") {
                refreshLogin(login);
                HttpUtilService.setTokens(login.accessToken, login.refreshToken);
                await DefaultWebSocket.refreshConnection();
                return getExpirationTime(login.accessToken)
            } else {
                return Promise.reject({error: login.loginStatus})
            }
        });
    }

    return Promise.resolve(getExpirationTime(undefined));
};


export default AppSecurityProvider;