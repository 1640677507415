import React from "react";
import {Button, ButtonGroup} from "react-bootstrap";
import FontAwesome from "react-fontawesome";

import pomodoroWorkingImg from '../../../../assets/img/pomodoro_working.png'
import pomodoroTakeABreakImg from '../../../../assets/img/pomodoro_break.png'

export const PomodoroHelpPanel: React.FC<{ toggleShowHelpMode: () => void }> = ({toggleShowHelpMode}) => {
    return (
        <div className="pomodoro-bar">
            <div className="pomodoro-timer pomodoro-timer-help">
                <ul>
                    <li>Focus on your tasks for the day using the <a
                        href="https://francescocirillo.com/pages/pomodoro-technique" target="_blank"
                        rel="noopener noreferrer">Pomodoro
                        Technique.</a>
                    </li>

                    <li>
                        When you start the timer, immerse and focus yourself, working on a task or goal
                        <br/>
                        <img src={pomodoroWorkingImg} alt="working"/>
                    </li>

                    <li>When the timer finishes treat yourself to a short break by relaxing, getting some coffee or
                        whatever you desire
                        <br/>
                        <img src={pomodoroTakeABreakImg} alt="take a break"/>
                    </li>

                    <li>Re-focus yourself on your tasks again</li>

                    <li>After 4 pomodoros, treat yourself to a longer break!</li>

                    <li>Keeping going until you've completed your task or goal and do it all over again!</li>
                </ul>

                <div className="timer-settings">
                    <ButtonGroup>
                        <Button variant="link" onClick={toggleShowHelpMode}>
                            <FontAwesome name="times"/>
                        </Button>
                    </ButtonGroup>
                </div>
            </div>
        </div>
    )
};