import React, {useState} from "react";
import {Button, ButtonGroup, Form, FormControl} from "react-bootstrap";
import {PomodoroSettings} from "../../../../model/UserPreferences";
import LoadingComponent from "../../../../component/loadingComponent/LoadingComponent";

interface PomodoroSettingsProps {
    pomodoroSettings: PomodoroSettings,
    setPomodoroSettings: (settings: PomodoroSettings) => Promise<void>,
    toggleEditingSettings: () => void
}

export const PomodoroSettingsPanel: React.FC<PomodoroSettingsProps> = ({pomodoroSettings, setPomodoroSettings, toggleEditingSettings}) => {
    const [stateSettings, setStateSettings] = useState(pomodoroSettings);
    const [isSaving, setIsSaving] = useState(false);

    const setWorkTime = (workTime: number) => {
        setStateSettings({...stateSettings, workTime: workTime});
    };

    const setBreakTime = (breakTime: number) => {
        setStateSettings({...stateSettings, breakTime: breakTime});
    };

    const setLongerBreakTime = (longerBreakTime: number) => {
        setStateSettings({...stateSettings, longerBreakTime: longerBreakTime});
    };

    const setLongerBreakAfterIterations = (longerBreakAfterIterations: number) => {
        setStateSettings({...stateSettings, longerBreakAfterIterations: longerBreakAfterIterations});
    };

    const setPlaySound = (playSound: boolean) => {
        setStateSettings({...stateSettings, playSound: playSound});
    };

    const setAutoStart = (autoStart: boolean) => {
        setStateSettings({...stateSettings, autoStart: autoStart});
    };

    const handleSignUpKeyPress = async (e: React.KeyboardEvent<HTMLElement>) => {
        if (e.key === 'Enter' && !e.shiftKey && isValid) {
            e.preventDefault();
            await onSave();
        }
    };

    const onSave = async () => {
        setIsSaving(true);
        await setPomodoroSettings(stateSettings).finally(() => setIsSaving(false));
        toggleEditingSettings();
    };

    const onCancel = () => {
        toggleEditingSettings();
    };

    const isValid = !!stateSettings.workTime && !!stateSettings.breakTime && !!stateSettings.longerBreakTime && !!stateSettings.longerBreakAfterIterations;

    return (
        <div className="pomodoro-bar">
            <div className="pomodoro-timer">
                <Form>
                    <Form.Group>
                        <Form.Label>Working Time (in Minutes)</Form.Label>
                        <FormControl type="number"
                                     size="sm"
                                     value={stateSettings.workTime}
                                     onChange={(e: any) => setWorkTime((e as any).target.value)}
                                     isInvalid={!stateSettings.workTime}
                                     onKeyPress={handleSignUpKeyPress}/>
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>Break Time (in Minutes)</Form.Label>
                        <FormControl type="number"
                                     size="sm"
                                     value={stateSettings.breakTime}
                                     onChange={(e: any) => setBreakTime((e as any).target.value)}
                                     isInvalid={!stateSettings.breakTime}
                                     onKeyPress={handleSignUpKeyPress}/>
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>Long Break Time (in Minutes)</Form.Label>
                        <FormControl type="number"
                                     size="sm"
                                     value={stateSettings.longerBreakTime}
                                     onChange={(e: any) => setLongerBreakTime((e as any).target.value)}
                                     isInvalid={!stateSettings.longerBreakTime}
                                     onKeyPress={handleSignUpKeyPress}/>
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>Long Break After Number of Intervals</Form.Label>
                        <FormControl type="number"
                                     size="sm"
                                     value={stateSettings.longerBreakAfterIterations}
                                     onChange={(e: any) => setLongerBreakAfterIterations((e as any).target.value)}
                                     isInvalid={!stateSettings.longerBreakAfterIterations}
                                     onKeyPress={handleSignUpKeyPress}/>
                    </Form.Group>

                    <hr/>

                    <Form.Group>
                        <Form.Label>Play Sound when Timer Finishes</Form.Label>
                        <Form.Check type="checkbox"
                                    checked={stateSettings.playSound}
                                    onChange={(e: any) => setPlaySound((e as any).target.checked)}/>
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>Auto Start Next Timer</Form.Label>
                        <Form.Check type="checkbox"
                                    checked={stateSettings.autoStart}
                                    onChange={(e: any) => setAutoStart((e as any).target.checked)}/>
                    </Form.Group>

                    <hr/>

                    <ButtonGroup>
                        <Button variant="dark"
                                onClick={onSave}
                                disabled={isSaving || !isValid}>
                            Save <LoadingComponent isBusy={isSaving}/>
                        </Button>
                        <Button variant="secondary" onClick={onCancel} disabled={isSaving}>
                            Cancel
                        </Button>
                    </ButtonGroup>
                </Form>
            </div>
        </div>
    )
};