import {Goal} from "../../../../../model/Goal";
import React, {useState} from "react";
import {dateToDayJs, dayJsToDate, formatDateToDateTimeString} from "../../../../../util/DateHelpers";
import {Button, ButtonGroup, Dropdown, Form, InputGroup, FormControl} from "react-bootstrap";
import DatePicker from "react-datepicker";
import GoalDisplay from "../goalDisplay/GoalDisplay";
import dayjs from "dayjs";
import {AssigneeChooser} from "../../assigneeChooser/AssigneeChooser";
import FeatureNotAvailable from "../../../../../component/featureNotAvailable/FeatureNotAvailable";

type AddTaskHandler = (taskName: string, dueDate?: dayjs.Dayjs, goalId?: string, assignedUserId?: string, link?: string) => void;

const findGoal = (goalId: string | undefined, goals: Goal[]): Goal | undefined => {
    return goalId ? goals.find(it => it.id === goalId) : undefined;
};

interface AddEditTaskFormProps {
    addTask: AddTaskHandler,
    goals: Goal[],
    onClose: () => void,
    task?: AddingTask
}

export interface AddingTask {
    id: string,
    taskName: string,
    dueDate?: dayjs.Dayjs,
    link?: string,
    goalId?: string,
    assigneeUserId?: string;
}

const AddEditTaskForm: React.FC<AddEditTaskFormProps> = ({addTask, onClose, task, goals}) => {
    const [taskName, setTaskName] = useState<string>(task?.taskName || '');
    const [dueDate, setDueDate] = useState<Date | null>(dayJsToDate(task?.dueDate) || null);
    const [goal, setGoal] = useState<Goal | undefined>(findGoal(task?.goalId, goals));
    const [assignee, setAssignee] = useState<string | undefined>(task?.assigneeUserId);
    const [link, setLink] = useState<string>(task?.link || '');

    const isValid = () => {
        return !!taskName.length;
    };

    const handleOnKeyPress = (event: React.KeyboardEvent<HTMLElement>) => {
        if (event.key === 'Enter') {
            handleSubmit();
        }
    };

    const handleSubmit = () => {
        addTask(taskName, dateToDayJs(dueDate), goal?.id, assignee, link);
        onClose();
    };

    const isOverdue = () => {
        const dueDateDayJs = dateToDayJs(dueDate);
        return !!dueDateDayJs && dueDateDayJs.unix() < dayjs().unix()
    };

    return (
        <div id="add-task-form" className="slate-background-transparent form-holder">
            <Form className="form">
                <Form.Group className="form">
                    <Form.Label>Task Name</Form.Label>
                    <Form.Control type="text"
                                  placeholder="E.g take out the trash"
                                  value={taskName}
                                  onKeyPress={handleOnKeyPress}
                                  onChange={(e: any) => setTaskName((e as any).target.value)}>
                    </Form.Control>
                </Form.Group>
                <Form.Group className="form">
                    <Form.Label>Due Date (Optional)</Form.Label>
                    <DatePicker selected={dueDate}
                                onChange={setDueDate}
                                showTimeSelect
                                dateFormat={`d MMM yyyy h:mm aa`}
                                withPortal
                                customInput={
                                    <FormControl type="text"
                                                 className={isOverdue() ? "is-invalid" : ""}
                                                 value={formatDateToDateTimeString(dateToDayJs(dueDate))}/>
                                }/>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Link (Optional)</Form.Label>
                    <InputGroup className="mb-2">
                        <Form.Control type="text"
                                      placeholder="E.g https://horizon-goals.com"
                                      value={link}
                                      onKeyPress={handleOnKeyPress}
                                      onChange={(e: any) => setLink((e as any).target.value)}>
                        </Form.Control>
                        <InputGroup.Append>
                            <Button variant="secondary"
                                    onClick={() => setLink("")}>Clear</Button>
                        </InputGroup.Append>
                    </InputGroup>
                </Form.Group>
                <Form.Group className="form">
                    <Form.Label>Assign Task To Goal (Optional)</Form.Label>
                    <GoalChooser goals={goals}
                                 selectedGoal={goal}
                                 setSelectedGoal={setGoal}/>
                </Form.Group>
                <Form.Group className="form">
                    <Form.Label>Assignee (Optional)</Form.Label>
                    <AssigneeChooser selectedAssignee={assignee}
                                     setSelectedAssignee={setAssignee}
                                     allowOnlyBoardUsers={true}/>
                    <FeatureNotAvailable supportedFeatureLevels={["PREMIUM", "WORKFORCE"]}
                                         displayText={<>Viewing and Setting an <b>'Assignee'</b> is available with
                                             Premium</>}/>
                </Form.Group>
                <ButtonGroup>
                    <Button variant="light"
                            disabled={!isValid()}
                            onClick={handleSubmit}>
                        OK
                    </Button>
                    <Button variant="dark"
                            onClick={onClose}>
                        Cancel
                    </Button>
                </ButtonGroup>
            </Form>
        </div>
    )
};


interface GoalChooserProps {
    goals: Goal[],
    selectedGoal: Goal | undefined,
    setSelectedGoal: (goal: Goal | undefined) => void
}

const GoalChooser: React.FC<GoalChooserProps> = ({goals, selectedGoal, setSelectedGoal}) => {
    const selectedGoalColour = selectedGoal?.colour;
    const availableGoalSelection: (Goal | undefined)[] = [undefined, ...goals];

    return <Dropdown>
        <Dropdown.Toggle className="form-control"
                         style={{backgroundColor: 'grey', borderColor: selectedGoalColour}}
                         id="dropdown-basic">
            <GoalDisplay goal={selectedGoal}/>
        </Dropdown.Toggle>
        <Dropdown.Menu>
            {
                !!goals.length && availableGoalSelection.map((it: Goal | undefined, index: number) =>
                    <Dropdown.Item key={index} onClick={() => setSelectedGoal(it)}>
                        <GoalDisplay goal={it} maxCharacters={40}/>
                    </Dropdown.Item>)
            }
            {
                !goals.length && <Dropdown.Item>
                    No Available Goals
                </Dropdown.Item>
            }
        </Dropdown.Menu>
    </Dropdown>
};

export default AddEditTaskForm;
