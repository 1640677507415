import React, {useContext, useEffect, useRef, useState} from "react";
import dayjs from "dayjs";
import Countdown, {CountdownRendererFn} from "react-countdown";
import {NotificationService} from "../../../../service/NotificationService";
import {Button, ButtonGroup} from "react-bootstrap";
import FontAwesome from "react-fontawesome";
import ConfirmAction from "../../../../component/confirmAction/ConfirmAction";
import {PomodoroSettings} from "../../../../model/UserPreferences";
import {store} from "../../../../AppState";
import {DispatcherCreator} from "../../../../state/dispatcherCreator";

const timerSound = require('../../../../assets/sound/pomodoro_timer.mp3');
export type CountdownState = "Working" | "Taking a break" | "Taking a long break";

interface PomodoroState {
    completedPomodoros: number,
    nextTimerRun?: Date,
    state?: CountdownState
}

interface PomodoroPanelProps {
    pomodoroSettings: PomodoroSettings,
    setPomodoroSettings: (settings: PomodoroSettings) => Promise<void>,
    toggleFocusMode: () => Promise<void>,
    toggleEditingSettings: () => void,
    toggleShowHelpMode: () => void
}


export const PomodoroPanel: React.FC<PomodoroPanelProps> = ({
                                                                pomodoroSettings,
                                                                setPomodoroSettings,
                                                                toggleFocusMode,
                                                                toggleEditingSettings,
                                                                toggleShowHelpMode
                                                            }) => {

    const {state, dispatch} = useContext(store);
    const dispatcherCreator = new DispatcherCreator(dispatch);
    const savedPomodoroState = state.pomodoroState;
    const [isTimerRunning, setIsTimerRunning] = useState<boolean>(false);

    const isValidStoredCountdown: () => boolean = () => {
        return savedPomodoroState?.onUnloadPomodoroNextTimer
            ? dayjs().isBefore(dayjs(savedPomodoroState.onUnloadPomodoroNextTimer))
            : false;
    };

    const onCalculateNextTimer: () => (Date | undefined) = () => {
        if (savedPomodoroState?.wasRunning) {
            return savedPomodoroState.onUnloadPomodoroNextTimer;
        } else if (savedPomodoroState?.onUnloadTime && savedPomodoroState?.onUnloadPomodoroNextTimer) {
            const currentTime = dayjs();
            const unloadTime = dayjs(savedPomodoroState.onUnloadTime);

            const difference = currentTime.diff(unloadTime, 'millisecond');

            return dayjs(savedPomodoroState.onUnloadPomodoroNextTimer).add(difference, 'millisecond').toDate();
        }
    };

    const [pomodoroState, setPomodoroState] = useState<PomodoroState>({
        completedPomodoros: pomodoroSettings.completedPomodoros,
        nextTimerRun: isValidStoredCountdown() ? onCalculateNextTimer() : undefined,
        state: isValidStoredCountdown() ? savedPomodoroState?.onUnloadState : undefined
    });

    useEffect(() => {
        if (isValidStoredCountdown() && savedPomodoroState?.wasRunning) {
            onStart();
        }
    }, []);

    const nextWorkingTimer = () => dayjs().add(pomodoroSettings.workTime, 'minute').toDate();
    const nextBreakTimer = () => dayjs().add(pomodoroSettings.breakTime, 'minute').toDate();
    const nextLongBreakTimer = () => dayjs().add(pomodoroSettings.longerBreakTime, 'minute').toDate();
    const audio: HTMLAudioElement = new Audio(timerSound);

    const countdownRef: React.MutableRefObject<Countdown | null> = useRef(null);

    const onToggleFocusMode = () => {
        onStop();
        toggleFocusMode();
    };

    const onToggleEditingSettings = () => {
        onStop();
        toggleEditingSettings();
    };

    const countdownRenderer: CountdownRendererFn = ({minutes, seconds}) => {
        const paddedMinutes = `${minutes}`.padStart(2, '0');
        const paddingSeconds = `${seconds}`.padStart(2, '0');
        return <span>{paddedMinutes}:{paddingSeconds}</span>;
    };

    const playTimerSound = () => {
        audio.play()
    };

    const onStart = () => {
        if (!pomodoroState.nextTimerRun) {
            const nextTimerRun = nextWorkingTimer();
            setPomodoroState({
                completedPomodoros: pomodoroState.completedPomodoros,
                nextTimerRun: nextTimerRun,
                state: "Working"
            });
        }
        setIsTimerRunning(true);
        countdownRef.current?.getApi()?.start();
    };

    const onStop = () => {
        savePomodoroState(false);
        countdownRef.current?.getApi()?.pause();
        setIsTimerRunning(false);
    };

    const onReset = () => {
        setIsTimerRunning(false);
        resetPomodoroState();
        countdownRef.current?.getApi()?.stop();
    };

    const savePomodoroState = (timerRunning: boolean) => {
        dispatcherCreator.setPomodoroState({
            onUnloadState: pomodoroState.state,
            onUnloadPomodoroNextTimer: pomodoroState.nextTimerRun,
            onUnloadTime: new Date(),
            wasRunning: timerRunning
        })
    }

    const resetPomodoroState = () => {
        setPomodoroState({
            completedPomodoros: 0,
        });
        dispatcherCreator.resetPomodoroState();
        setPomodoroSettings({...pomodoroSettings, completedPomodoros: 0});
    }

    const onComplete = () => {
        if (pomodoroSettings.playSound) {
            playTimerSound();
        }

        switch (pomodoroState.state) {
            case "Working":
                const completedPomodoros = pomodoroState.completedPomodoros + 1;
                if (completedPomodoros % pomodoroSettings.longerBreakAfterIterations === 0) {
                    setPomodoroState({
                        state: "Taking a long break",
                        nextTimerRun: nextLongBreakTimer(),
                        completedPomodoros: completedPomodoros
                    });
                    new NotificationService().createNotification("Time for a longer break!");
                } else {
                    setPomodoroState({
                        state: "Taking a break",
                        nextTimerRun: nextBreakTimer(),
                        completedPomodoros: completedPomodoros
                    });
                    new NotificationService().createNotification("Time for a short break!");
                }
                break;

            case "Taking a break":
            case "Taking a long break":
                setPomodoroState({
                    completedPomodoros: pomodoroState.completedPomodoros,
                    state: "Working",
                    nextTimerRun: nextWorkingTimer()
                });
                new NotificationService().createNotification("Time to work!");
                break;
        }

        if (pomodoroSettings.autoStart) {
            countdownRef.current?.getApi()?.start();
        } else {
            setIsTimerRunning(false);
        }
        savePomodoroState(pomodoroSettings.autoStart);

        setPomodoroSettings({...pomodoroSettings, completedPomodoros: pomodoroState.completedPomodoros})
    };

    return (
        <div className="pomodoro-bar medium-and-above-screens">
            <div className="pomodoro-timer">
                <span className="timer-text">
                    {pomodoroState.state && <b>{pomodoroState.state}</b>}
                    {(pomodoroState.state && pomodoroState.completedPomodoros > 0) && <br/>}
                    {pomodoroState.completedPomodoros > 0 &&
                    <span>{`Completed ${pomodoroState.completedPomodoros} Pomodoros`}</span>}
                </span>
                <div className="current-timer">
                    <Countdown date={pomodoroState.nextTimerRun || nextWorkingTimer()}
                               ref={countdownRef}
                               autoStart={false}
                               renderer={countdownRenderer}
                               onComplete={onComplete}
                               onTick={() => savePomodoroState(true)}
                    />
                </div>
                <ButtonGroup>
                    <Button variant="link" className={isTimerRunning ? 'active' : ''} onClick={onStart}>
                        <FontAwesome name="play"/>
                    </Button>
                    {isTimerRunning &&
                    <Button variant="link" className={!isTimerRunning ? 'active' : ''} onClick={onStop}>
                        <FontAwesome name="pause"/>
                    </Button>}
                    {!isTimerRunning &&
                    <Button variant="link" className={!isTimerRunning ? 'active' : ''} >
                        <ConfirmAction confirmButtonText="Reset Timer"
                                       confirmMessage={"Are you sure you want to stop and reset the timer?"}
                                       onClick={onReset}>
                            <FontAwesome name="stop"/>
                        </ConfirmAction>
                    </Button>
                    }
                </ButtonGroup>

                <hr/>


                <div className="timer-settings">
                    <ButtonGroup>
                        <Button variant="link">
                            <FontAwesome name="cogs" onClick={onToggleEditingSettings}/>
                        </Button>

                        <Button variant="link" onClick={toggleShowHelpMode}>
                            <FontAwesome name="question-circle"/>
                        </Button>

                        <Button variant="link" onClick={onToggleFocusMode}>
                            <FontAwesome name="times"/>
                        </Button>
                    </ButtonGroup>
                </div>
            </div>
        </div>
    )
};