import * as React from "react";
import {useState} from "react";
import {
    DayOfWeek,
    FRIDAY,
    MONDAY,
    MonthlyOccurrence,
    Occurrence,
    SATURDAY,
    SUNDAY,
    Task,
    THURSDAY,
    TUESDAY,
    WEDNESDAY,
    WeeklyOccurrence,
    YearlyOccurrence
} from "../../../../../model/Task";
import {Button, ButtonGroup, Dropdown, Form} from "react-bootstrap";
import dayjs from "dayjs";
import LoadingComponent from "../../../../../component/loadingComponent/LoadingComponent";

interface RecurringTaskPanelProps {
    task: Task;
    updateTask: (task: Task) => Promise<void>;
    onClose: () => void
}

interface RecurringTaskOption {
    label: string,
    value: Occurrence | undefined
}

const RecurringTaskForm: React.FC<RecurringTaskPanelProps> = ({task, updateTask, onClose}) => {
    const [isSaving, setIsSaving] = useState(false);
    const [occurrence, setOccurrence] = useState<Occurrence | undefined>(task.occurrence);

    const currentTime: dayjs.Dayjs = dayjs();
    const occurrenceType = task.occurrence?.type;

    const handleSaveTask = async () => {
        setIsSaving(true);
        await updateTask({...task, occurrence: occurrence})
            .finally(() => setIsSaving(false));
        onClose();
    };

    const options: RecurringTaskOption[] = [
        {label: "None", value: undefined},
        {
            label: "Daily/Weekly",
            value: occurrenceType === "WEEKLY" ? task.occurrence : {
                type: "WEEKLY",
                days: [SUNDAY, MONDAY, TUESDAY, WEDNESDAY, THURSDAY, FRIDAY, SATURDAY]
            }
        },
        {
            label: "Monthly",
            value: occurrenceType === "MONTHLY" ? task.occurrence : {type: "MONTHLY", dayOfMonth: currentTime.date()}
        },
        {
            label: "Yearly",
            value: occurrenceType === "YEARLY" ? task.occurrence : {
                type: "YEARLY",
                dayOfMonth: currentTime.date(),
                month: currentTime.month()
            }
        },
    ];

    return (
        <div className="slate-background-transparent form-holder">
            <Form className="form">
                <Form.Group className="form">
                    <Form.Label>Recurring Task Settings</Form.Label>
                </Form.Group>

                <Form.Group className="form">
                    <Dropdown>
                        <Dropdown.Toggle className="form-control"
                                         style={{backgroundColor: 'grey'}}
                                         id="dropdown-basic">
                            {!!occurrence?.type ? options.find(it => it.value?.type === occurrence.type)?.label : 'None'}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {
                                options.map((it: RecurringTaskOption, index: number) =>
                                    <Dropdown.Item key={index} onClick={() => setOccurrence(it.value)}>
                                        {it.label}
                                    </Dropdown.Item>)
                            }
                        </Dropdown.Menu>
                    </Dropdown>
                </Form.Group>

                <Form.Group className="form">
                    {occurrence?.type === "WEEKLY" && <WeeklyOccurrenceSelection occurrence={occurrence}
                                                                                 setOccurrence={(it => setOccurrence(it))}/>}

                    {occurrence?.type === "MONTHLY" && <MonthlyOccurrenceSelection occurrence={occurrence}
                                                                                   setOccurrence={(it => setOccurrence(it))}/>}

                    {occurrence?.type === "YEARLY" && <YearlyOccurrenceSelection occurrence={occurrence}
                                                                                 setOccurrence={(it => setOccurrence(it))}/>}
                </Form.Group>

                <ButtonGroup style={{paddingTop: '0.5rem'}}>
                    <Button variant="light" disabled={isSaving} onClick={handleSaveTask}>
                        <LoadingComponent isBusy={isSaving}/> OK
                    </Button>
                    <Button variant="dark"
                            onClick={onClose}>
                        Cancel
                    </Button>
                </ButtonGroup>
            </Form>
        </div>
    )
};

interface OccurrenceSelectionProps<T extends WeeklyOccurrence | MonthlyOccurrence | YearlyOccurrence> {
    occurrence: T,
    setOccurrence: (occurrence: T) => void
}

const WeeklyOccurrenceSelection: React.FC<OccurrenceSelectionProps<WeeklyOccurrence>> = ({occurrence, setOccurrence}) => {
    const toggleDaySelection = (dayOfWeek: DayOfWeek) => {
        let newDays = occurrence.days;

        if (newDays.find(it => it === dayOfWeek) !== undefined) {
            newDays = newDays.filter(it => it !== dayOfWeek);
        } else {
            newDays = newDays.concat([dayOfWeek]).sort();
        }

        setOccurrence({
            ...occurrence,
            days: newDays
        })
    };

    const options: { label: string, value: DayOfWeek }[] = [
        {label: "Sunday", value: SUNDAY},
        {label: "Monday", value: MONDAY},
        {label: "Tuesday", value: TUESDAY},
        {label: "Wednesday", value: WEDNESDAY},
        {label: "Thursday", value: THURSDAY},
        {label: "Friday", value: FRIDAY},
        {label: "Saturday", value: SATURDAY},
    ];

    return (
        <div className="weekly-occurrence">
            <Form.Label>
                Repeat on
            </Form.Label>

            {options.map((day, index) => <Form.Check key={index}
                                                     type="checkbox"
                                                     label={day.label}
                                                     checked={occurrence.days.find(it => it === day.value) !== undefined}
                                                     onChange={() => toggleDaySelection(day.value)}/>)}


        </div>
    )
};


const MonthlyOccurrenceSelection: React.FC<OccurrenceSelectionProps<MonthlyOccurrence>> = ({occurrence, setOccurrence}) => {
    const daysInMonth = dayjs().daysInMonth();

    const selectDate = (e: any) => {
        setOccurrence({...occurrence, dayOfMonth: e.target.value})
    };

    return (
        <div>
            <Form.Group>
                <div className="recurring-monthly-holder">
                    <Form.Label>
                        Repeat on
                    </Form.Label>

                    <br className="extra-small-screens-only"/>

                    <select value={occurrence.dayOfMonth} className="form-control recurring-monthly-date-select"
                            onChange={selectDate}>
                        {[...Array(daysInMonth)].map((_it, index) => <option key={index}
                                                                             value={index + 1}>{index + 1}</option>)}
                    </select>
                    <Form.Label>
                        each month
                    </Form.Label>
                </div>
            </Form.Group>
        </div>
    )
};

const YearlyOccurrenceSelection: React.FC<OccurrenceSelectionProps<YearlyOccurrence>> = ({occurrence, setOccurrence}) => {
    const months: string[] = ['January', 'February', 'March', 'April', ' May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const daysInMonth = dayjs().set('date', 0).set('month', occurrence.month).daysInMonth();

    const selectDate = (e: any) => {
        setOccurrence({...occurrence, dayOfMonth: e.target.value})
    };

    const isSelected = (index: number) => occurrence.month === index;

    return (
        <div>
            <Form.Group>
                <div className="recurring-monthly-holder">
                    <Form.Label>
                        Repeat on
                    </Form.Label>

                    <br className="extra-small-screens-only"/>

                    <select value={occurrence.dayOfMonth} className="form-control recurring-yearly-date-select"
                            onChange={selectDate}>
                        {[...Array(daysInMonth)].map((_it, index) => <option value={index + 1}>{index + 1}</option>)}
                    </select>

                    <Dropdown className="recurring-yearly-month-select">
                        <Dropdown.Toggle className="form-control"
                                         style={{backgroundColor: 'grey'}}
                                         id="dropdown-basic">
                            {months[occurrence.month]}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {
                                months.map((it: string, index: number) =>
                                    <Dropdown.Item key={index}
                                                   style={isSelected(index) ? {backgroundColor: 'lightgray'} : {}}
                                                   onClick={() => setOccurrence({...occurrence, month: index})}>
                                        {it}
                                    </Dropdown.Item>)
                            }
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </Form.Group>
        </div>
    )
};


export default RecurringTaskForm;